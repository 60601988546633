import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../RmaDashboard/RmaDashboard.scss';
import { Post, uriName } from '../../../utilities/apiUtility';
import RmaSummaryTable from './RmaSummaryTable';
import { goToRmaDashboard, createSurRma } from '../createTransferFlow/action';
import { ReactComponent as LeftArrowLogo } from '../../../assets/arrow1-left.svg';
import { ReactComponent as DeleteLogo } from '../../../assets/delete.svg';
import { ReactComponent as ChevronUp } from '../../../assets/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../../assets/chevron-down.svg';


import {
  addSuccessMessage,
  addErrorMessage,
  clearMessages,
} from '../../action';

import {
  getInitialInfo,
  searchServiceProviders,
  getspLocInfo,
  searchRma,
  goToCreateTransferFlow,
  goToImportFlow,
  goToRmaSummary,
  goToRmaSummarySuccess,
  setRmaSummary,
} from '../RmaDashboard/action';

class RmaSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rmaCreateButton: true,
      indexClicked: []
    };
    this.createRmaAndGoToSummary = this.createRmaAndGoToSummary.bind(this);
  }
  componentDidUpdate() { }

  componentDidMount() {
    if (!this.props.userType) {
      this.props.getInitialInfo();
    }
  }
  removeItem(arr, item) {
    return arr.filter(f => f !== item)
  }

  render() {
    return (
      <div>
        <a
          className="ScanNavLabel"
          href="#"
          onClick={this.props.goToRmaDashboard}
        >
          <LeftArrowLogo className="scanLeftArraow" />
          Return to RMA Dashboard
        </a>
        <div>
          <div className="leftdiv">
            <br></br>
            <p className="rmaTitle">Create new RMA</p>
            <p />
            <p>Uploaded file</p>
            <br />
            <>
              {this.props.fileName}{' '}
              <DeleteLogo onClick={this.props.goToRmaDashboard} />
            </>
          </div>
        </div>
        <div className="tablediv">
          {this.props.uploadSummary.map((x, i) => {
            return (
              <div className="mainTablediv" key={i}>
                <div className="summaryHeaders">
                  <div className="summaryleftdiv">
                     {x[0].spName} 
                  </div>
                  <div className="summaryrightdiv">
                    {x[0].spLocationName}
                    {!this.state.indexClicked.includes(i) && <ChevronDown onClick={() => {
                      let array = this.state.indexClicked
                      array.push(i)
                      this.setState({
                        indexClicked: array
                      });
                    }} />}
                    {this.state.indexClicked.includes(i) && <ChevronUp onClick={() => {
                      let arr = this.state.indexClicked;
                      let newarr = this.removeItem(arr, i);
                      this.setState({
                        indexClicked: newarr
                      });
                    }} />}
                  </div>
                </div>
                <div>
                  {this.state.indexClicked.includes(i) && <RmaSummaryTable tableData={x} />}
                </div>
              </div>
            );
          })}
          <div className="manualSummarydiv" >
            {this.state.rmaCreateButton && (
              <button
                className="createRMASummaryButton"
                onClick={this.createRmaAndGoToSummary}
              >
                Create RMA
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  async createRmaAndGoToSummary() {
    this.setState({ rmaCreateButton: false });
    let rmaSummaryObject = [];
    let rmaCreationCount = 0;

    // grouping based on location ID
    let mainObject = this.props.summaryData;
    const groupedByLocationId = mainObject.reduce(function (r, a) {
      r[a.spLocId] = r[a.spLocId] || [];
      r[a.spLocId].push(a);
      return r;
    }, Object.create(null));

    const groupedByLocationIdArray = Object.values(
      groupedByLocationId
    ).reverse();

    // grouping based  on serialized and non-serialized  and calling ceeating RMA {"somedata":"sp-related", parts :[{},{},{}]}

    for (let i = 0; i < groupedByLocationIdArray.length; i++) {
      let groupedBysearializedParts = groupedByLocationIdArray[i].filter(
        (x) => x.searializedPart === '1'
      );
      let groupedBynonSerializedParts = groupedByLocationIdArray[i].filter(
        (x) => x.searializedPart === '0' || !x.searializedPart
      );
      if (groupedBysearializedParts.length > 0) {
        // since sp and  location details  are same  for  each object
        let obj = groupedBysearializedParts[0];
        let rmaSpId = obj.spId;
        let rmalocId = obj.spLocId;
        let spName = obj.spName;
        let spLocationName = obj.spLocationName;
        let location = obj.Location;
        let customerAccount = obj.ServiceBenchId;
        let programType = obj.ProgramType;
        //  forming parts data
        let parts = [];
        let partsSet = [];
        groupedBysearializedParts.map((x, i) => {
          let part = {};
          if (partsSet.indexOf(x.PartNumber).toString() === "-1") {
            partsSet.push(x.PartNumber);
          }
          part.partNumber = x.PartNumber;
          part.DefectCode = x.DefectCode;
          part.partType = x.PartType;
          part.ReturnReason = x.ReturnReason;
          part.spLocId = x.spLocId;
          part.quantity = x.ReturnQuantity;
          part.spInventoryId = x.spInvId;
          part.serialNumber = x.SerialNumber;
          part.asurionBarCode = x.AsurionBarcode;
          part.returnStatus = "Requested"
          part.lineNumber = (partsSet.indexOf(x.PartNumber) + 1).toString();
          part.isSerialized = x.searializedPart;
          parts[i] = part;
          return x;
        });
        const serializedPartsResult = await this.processRMA(
          obj,
          rmaSpId,
          rmalocId,
          parts,
          spName,
          spLocationName,
          location,
          customerAccount,
          programType,
        );
        rmaSummaryObject.push(serializedPartsResult);
        rmaCreationCount++;
      }

      if (groupedBynonSerializedParts.length > 0) {
        // since sp and  location details  are same  for  each object
        let obj = groupedBynonSerializedParts[0];
        let rmaSpId = obj.spId;
        let rmalocId = obj.spLocId;
        let spName = obj.spName;
        let spLocationName = obj.spLocationName;
        let location = obj.Location;
        let customerAccount = obj.ServiceBenchId;
        let programType = obj.ProgramType;
        //  forming parts data
        let parts = [];
        let partsSet = [];
        groupedBynonSerializedParts.map((x, i) => {
          let part = {};
          if (partsSet.indexOf(x.PartNumber).toString() === "-1") {
            partsSet.push(x.PartNumber);
          }
          part.partNumber = x.PartNumber;
          part.DefectCode = x.DefectCode;
          part.partType = x.PartType;
          part.returnStatus = "Requested"
          part.ReturnReason = x.ReturnReason;
          part.spLocId = x.spLocId;
          part.quantity = x.ReturnQuantity;
          part.spInventoryId = x.spInvId;
          part.serialNumber = x.SerialNumber;
          part.asurionBarCode = x.AsurionBarcode;
          part.lineNumber = (partsSet.indexOf(x.PartNumber) + 1).toString();
          part.isSerialized = x.searializedPart;
          parts[i] = part;
          return x;
        });
        const nonserializedPartsResult = await this.processRMA(
          obj,
          rmaSpId,
          rmalocId,
          parts,
          spName,
          spLocationName,
          location,
          customerAccount,
          programType,
        );
        rmaSummaryObject.push(nonserializedPartsResult);
        rmaCreationCount++;
      }
    }

    //need to enhance the funtionality to track rmaCreationCount
    if (rmaCreationCount > 0) {
      this.props.addSuccessMessage(
        'You successfully created ' + rmaCreationCount + ' RMA'
      );
      this.props.setRmaSummary(rmaSummaryObject);
    }
    this.props.goToRmaSummarySuccess();
  }
  async processRMA(
    obj,
    rmaSpId,
    rmalocId,
    parts,
    spName,
    spLocationName,
    location,
    customerAccount,
    programType,
  ) {
    let result = await this.props.createSurRma(
      rmaSpId,
      rmalocId,
      this.props.daxAddress,
      programType,
      parts
    );
    let returnObj = {};

    if (result && result.status) {
      if (result.status === 'success') {
        this.setState({ modalVisible: false });
        returnObj.parts = parts;
        returnObj.rmaNumber = result.rmaNumber;
        returnObj.rmalocId = rmalocId;
        returnObj.spName = spName;
        returnObj.spLocationName = spLocationName;
        obj.rmaNumber = result.rmaNumber;

        /* send out email */

        let rmaSpLocEmailInfo = await Post(
          uriName,
          `/v1/transfergateway/rma/rmaLocationEmailInfo`,
          {
            locationNumber: rmalocId,
          }
        );
        if (rmaSpLocEmailInfo.emailId === 'NOT_FOUND') {
          this.props.addErrorMessage(
            'Email Not Found for location: ' + location + '.'
          );
        }
        /*get location address */

        let rmaSpLocAddress = await Post(
          uriName,
          `/v1/transfergateway/rma/rmaLocationAddress`,
          {
            spLocationId: rmalocId,
          }
        );
        if (rmaSpLocAddress.address === 'NOT_FOUND') {
          this.props.addErrorMessage('address not found: ' + location + '.');
        } else {
          try {
            for (let i = 0; i < rmaSpLocEmailInfo.length; i++) {
              let rmaSendEmail = await Post(
                uriName,
                `/v1/transfergateway/rma/sendRmaEmail`,
                {
                  rmaNumber: result.rmaNumber,
                  emailId: rmaSpLocEmailInfo[i].EMAIL_ADDRESS,
                  addressName: rmaSpLocAddress.name,
                  addressContactName: rmaSpLocAddress.contactName,
                  addressLine1: rmaSpLocAddress.addressLine1,
                  addressLine2: rmaSpLocAddress.addressLine2,
                  addressCity: rmaSpLocAddress.addressCity,
                  addressState: rmaSpLocAddress.addressState,
                  addressZip: rmaSpLocAddress.addressZip,
                  parts: parts,
                }
              );
            }
          } catch (err) {
            this.props.addErrorMessage(
              'error sending emails ' + location + '.'
            );
          }
          /* send out email end */

          try {
            // sending request to DAX either 365 or 2009
            let rmaDaxResponse = await Post(
              uriName,
              `/v1/transfergateway/rma/daxnotifications`,
              {
                rmaNumber: result.rmaNumber,
                isDax365file: this.props.isDax365file,
                serviceProviderId: rmaSpId,
                customerAccount: customerAccount,
                parts: parts,
              }
            );

            if (rmaDaxResponse.ErrorCode === 'Dax call not successful') {
              this.props.addErrorMessage('dax update failed ' + location + '.');
            }
          } catch (err) {
            this.props.addErrorMessage('dax update failed ' + location + '.');
          }
        }
        return returnObj;
        // this.props.go;ToRmaSummarySuccess()
      } else if (result.status === 'failure') {
        this.props.addErrorMessage('Something went wrong when creating RMA. Please try again.');
      }
    } else {
      this.props.addErrorMessage('Something went wrong creating RMA. Please try again.');
    }
  }
  async this() { }
}

const mapStateToProps = ({ rmaImportReducer, rmadashboardReducer }) => {
  const {
    tableData,
    userType,
    selectedSP,
    spLocations,
    spList,
    fromDate,
    toDate,
    selectedSpId,
    shipModalVisisble,
    packListModalVisible,
  } = rmadashboardReducer;
  const { summaryData, fileName, daxAddress, isDax365file, uploadSummary } = rmaImportReducer;

  return {
    tableData,
    userType,
    selectedSP,
    spLocations,
    spList,
    fromDate,
    toDate,
    selectedSpId,
    shipModalVisisble,
    packListModalVisible,
    summaryData,
    fileName,
    daxAddress,
    isDax365file,
    uploadSummary
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInitialInfo,
      searchServiceProviders,
      getspLocInfo,
      searchRma,
      goToCreateTransferFlow,
      goToImportFlow,
      goToRmaSummary,
      createSurRma,
      addSuccessMessage,
      addErrorMessage,
      clearMessages,
      goToRmaSummarySuccess,
      goToRmaDashboard,
      setRmaSummary,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RmaSummary);
