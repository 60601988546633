module.exports = {
  rmaStatusOutgoingMap: [
    {
      label: 'Initiated',
      value: 'INITIATED'
    },
    {
      label: 'Created',
      value: 'CREATED'
    },
    {
      label: 'Completed',
      value: 'COMPLETED'
    },
    {
      label: 'Closed',
      value: 'CLOSED'
    },
    {
      label: 'Received',
      value: 'RECEIVED'
    },
    {
      label: 'Receiving',
      value: 'IN_PROGRESS'
    },
    {
      label: 'In Progress',
      value: 'PKNG_LST_CREATED'
    },
    {
      label: 'Label Generated',
      value: 'LABEL_GENERATED'
    },
    {
      label: 'Awaiting Label',
      value: 'LABEL_REQUESTED'
    },
    {
      label: 'Label Printed',
      value: 'LABEL_PRINTED'
    },
    {
      label: 'Pending Receiving',
      value: 'PND_RECEIVING'
    }
  ],
  rmaStatusIncomingMap: [
    {
      label: 'Received',
      value: 'RECEIVED'
    },
    {
      label: 'Pending Receiving',
      value: 'PND_RECEIVING'
    }
  ]
};
