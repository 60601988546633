import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CONSTANTS } from '../../../common/constants/generalConst';
import '../table.scss';
import { openShipLabelModal, openPackListlModal } from '../action';

const getStatus = value => {
  return <a className={statusMap[value]['class']}>{statusMap[value]['displayValue']}</a>;
};

const statusMap = {
  INITIATED: {
    displayValue: 'In progress',
    action: [],
    class: 'rmaInprogressStyle'
  },
  CREATED: {
    displayValue: 'RMA ready',
    action: [],
    class: 'rmaCreatedStyle'
  },
  PKNG_LST_CREATED: {
    displayValue: ' In progress ',
    action: ['Print packing list'],
    class: 'rmaInprogressStyle'
  },
  LABEL_GENERATED: {
    displayValue: ' Shipping label ready ',
    action: ['Print packing list', '|', 'Print shipping label'],
    class: 'rmaShipStatusStyle'
  },
  LABEL_REQUESTED: {
    displayValue: ' Awaiting shipping label ',
    action: ['Print packing list', '|', 'Print shipping label'],
    class: 'rmaShipStatusStyle'
  },
  LABEL_PRINTED: {
    displayValue: ' Shipping label printed ',
    action: ['Print packing list', '|', 'Print shipping label'],
    class: 'rmaShipStatusStyle'
  },
  COMPLETED: {
    displayValue: ' Completed ',
    action: ['Print packing list', '|', 'Print shipping label'],
    class: 'rmaFinishStyle'
  },
  CLOSED: {
    displayValue: ' Closed ',
    action: ['Print packing list', '|', 'Print shipping label'],
    class: 'rmaFinishStyle'
  },
  PND_RECEIVING: {
    displayValue: 'Pending receiving',
    action: ['View'],
    class: 'rmaCreatedStyle'
  },
  IN_PROGRESS: {
    displayValue: 'Receiving',
    action: ['View'],
    class: 'rmaShipStatusStyle'
  },
  RECEIVED: {
    displayValue: 'Received',
    action: ['View'],
    class: 'rmaFinishStyle'
  }
};

const columns = [
  {
    Header: 'Transfer number',
    accessor: 'rmaNumber',
    disableSortBy: true
  },
  {
    Header: 'Date created',
    accessor: 'createdDate',
    sortType: 'basic',
    Cell: e => {
      let dayOfJob = new Date(e.value);
      let today = new Date();
      let Difference_In_Time = today.getTime() - dayOfJob.getTime();
      let daysInbetween = Difference_In_Time / (1000 * 3600 * 24);
      if (daysInbetween < 1) {
        return <>Today</>;
      } else if (daysInbetween < 2) {
        return <>Yesterday</>;
      } else if (daysInbetween < 3) {
        return <>2 days ago</>;
      } else if (daysInbetween < 4) {
        return <>3 days ago</>;
      } else if (daysInbetween < 5) {
        return <>4 days ago</>;
      } else if (daysInbetween < 6) {
        return <>5 days ago </>;
      } else if (daysInbetween < 7) {
        return <>6 days ago</>;
      } else {
        return (
          <>
            {dayOfJob.getMonth() + 1}/{dayOfJob.getDate() + 1}/{dayOfJob.getFullYear().toString().substr(-2)}
          </>
        );
      }
    }
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
    Cell: e => getStatus(e.value)
  },
  {
    Header: 'Tracking Number',
    accessor: 'trackingNumber',
    disableSortBy: true
  },
  {
    Header: 'Actions',
    accessor: 'status',
    id: 'actionItem',
    disableSortBy: true
  }
];

const SaTable = props => {
  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data: props.tableData,
      initialState: { pageIndex: props.currentPage }
    },
    useSortBy,
    usePagination
  );
  return (
    <>
      <table className='rmadashboardtable' {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th className='rmaDashboardTableHeader' {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? '▼' : '▲') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            const transferType = row.original.transferType;
            return (
              <tr className='dashrmatr' {...row.getRowProps()}>
                {row.cells.map(cell => {
                  let rmaNumber = cell.row.cells[0].value;
                  return (
                    <td style={cell.column.Header === 'Actions' ? { width: '600px' } : {}} className='rmaDashBoardTableData'>
                      {cell.column.Header === 'Actions'
                        ? statusMap[cell.value].action.map((item, cell) => {
                            if (item === '|') {
                              return <a className='actionItem'>{item}</a>;
                            }
                            if (item === 'Print packing list') {
                              return (
                                <a
                                  className={transferType === CONSTANTS.CAPABILITIES.TRUCK ? 'disabled-link' : 'actionItem'}
                                  onClick={() => props.openPackListlModal(rmaNumber)}
                                >
                                  {item}
                                </a>
                              );
                            }
                            if (item === 'Print shipping label') {
                              return (
                                <a
                                  className={transferType === CONSTANTS.CAPABILITIES.TRUCK ? 'disabled-link' : 'actionItem'}
                                  onClick={() => props.openShipLabelModal(rmaNumber)}
                                >
                                  {item}
                                </a>
                              );
                            }
                            return (
                              <a className='actionItem' href='#'>
                                {item}
                              </a>
                            );
                          })
                        : cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {page && page.length > 0 ? (
        ''
      ) : (
        <div style={{ width: '100%' }}>
          <p style={{}}>No Data Available, expand your search</p>
        </div>
      )}
      <div className='paginationdiv'>
        <button
          className={canPreviousPage ? 'paginationbuttonEnabled' : 'paginationbuttonDisabled'}
          onClick={() => {
            previousPage();
            props.setCurrentPage(pageIndex - 1);
          }}
          disabled={!canPreviousPage}
        >
          {'< Prev'}
        </button>{' '}
        {pageOptions.length > 0 && pageIndex - 2 >= 0 ? (
          <a
            onClick={() => {
              gotoPage(pageIndex - 2);
              props.setCurrentPage(pageIndex - 2);
            }}
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              color: '#377A80'
            }}
          >
            {pageIndex - 1}
          </a>
        ) : (
          ''
        )}
        {pageOptions.length > 0 && pageIndex - 1 >= 0 ? (
          <a
            onClick={() => {
              gotoPage(pageIndex - 1);
              props.setCurrentPage(pageIndex - 1);
            }}
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              color: '#377A80'
            }}
          >
            {pageIndex}
          </a>
        ) : (
          ''
        )}
        {pageOptions.length > 0 ? (
          <a
            onClick={() => {
              gotoPage(pageIndex);
              props.setCurrentPage(pageIndex);
            }}
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              color: '#000000'
            }}
          >
            {pageIndex + 1}
          </a>
        ) : (
          ''
        )}
        {pageOptions.length > 0 && pageIndex + 2 <= pageOptions.length ? (
          <a
            onClick={() => {
              gotoPage(pageIndex + 1);
              props.setCurrentPage(pageIndex + 1);
            }}
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              color: '#377A80'
            }}
          >
            {pageIndex + 2}
          </a>
        ) : (
          ''
        )}
        {pageOptions.length > 0 && pageIndex + 3 <= pageOptions.length ? (
          <a
            onClick={() => {
              gotoPage(pageIndex + 2);
              props.setCurrentPage(pageIndex + 2);
            }}
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              color: '#377A80'
            }}
          >
            {pageIndex + 3}
          </a>
        ) : (
          ''
        )}{' '}
        <button
          className={canNextPage ? 'paginationbuttonEnabled' : 'paginationbuttonDisabled'}
          onClick={() => {
            nextPage();
            props.setCurrentPage(pageIndex + 1);
          }}
          disabled={!canNextPage}
        >
          {'Next >'}
        </button>{' '}
        {pageOptions.length > 0 ? (
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ rmadashboardReducer }) => {
  const { tableData, userType } = rmadashboardReducer;
  return {
    tableData,
    userType
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openShipLabelModal,
      openPackListlModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SaTable);
