import {
  SET_APPLICATION_SUCCESS_MESSAGES,
  ADD_APPLICATION_SUCCESS_MESSAGE,
  DELETE_APPLICATION_SUCCESS_MESSAGE,
  SET_APPLICATION_ERROR_MESSAGES,
  ADD_APPLICATION_ERROR_MESSAGE,
  DELETE_APPLICATION_ERROR_MESSAGE,
  ENABLE_APPLICATION_SPINNER,
  DISABLE_APPLICATION_SPINNER
} from '../actionTypes';
import { hasError, showErrorMessage } from '../actions/alertActions';
import { getDomain } from '@sbx/sbx-deimos-reactui-common/genericUtility';
import { setSessionExpired } from '@sbx/sbx-deimos-reactui-common/authentication/userSessionActions';
import { updateCurrentSession } from '@sbx/sbx-deimos-reactui-common/authentication/userSessionSaga';
import { UPDATE_CURRENT_SESSION } from '@sbx/sbx-deimos-reactui-common/authentication/userSessionConst';

import { Get, userInfo } from '../utilities/apiUtility';

export const deleteSuccessMessage = index => async dispatch => {
  dispatch({ type: DELETE_APPLICATION_SUCCESS_MESSAGE, data: index });
};

export const addSuccessMessage = message => async dispatch => {
  dispatch({ type: ADD_APPLICATION_SUCCESS_MESSAGE, data: message });
};

export const deleteErrorMessage = index => async dispatch => {
  dispatch({ type: DELETE_APPLICATION_ERROR_MESSAGE, data: index });
};

export const addErrorMessage = message => async dispatch => {
  dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: message });
};

export const clearMessages = () => async dispatch => {
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_APPLICATION_SUCCESS_MESSAGES, data: [] });
};
export const enableSpinner = () => async dispatch => {
  dispatch({ type: ENABLE_APPLICATION_SPINNER, data: true });
};
export const disableSpinner = () => async dispatch => {
  dispatch({ type: DISABLE_APPLICATION_SPINNER, data: false });
};

export const callUpdateCurrentSession = setCookies => async dispatch => {
  dispatch(
    updateCurrentSession({
      fetchAccessToken,
      handleExpiredSession,
      setCookies: (cookieName, cookieValue) => setCookies(cookieName, cookieValue, { domain: getDomain(), path: '/' }),
      userInfo
    })
  );
  dispatch({ type: UPDATE_CURRENT_SESSION });
};

const fetchAccessToken = async () => {
  return await Get(sbxAuthentication.subUri, {
    'refresh-token': userInfo.refreshToken,
    'x-api-key': config.API.BaseUrl.apikey,
    Accept: 'application/json',
    Authorization: `Bearer ${userInfo.token}`
  });
};

const handleExpiredSession = () => async dispatch => {
  dispatch(setSessionExpired(true));
  dispatch(showErrorMessage('Error: Session Expired'));
};
