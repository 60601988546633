import React from 'react';
import { connect } from 'react-redux';
import './Layout.scss';
import Loading from '../common/Loading';
import Returns from '../returns/Returns';
import logo from '../../assets/sb-logo-white.png';
import Alerts from '../Alerts/Alerts';

class Layout extends React.Component {
  render() {
    return (
      <>
        {this.props.spinnerEnable ? <Loading /> : ''}
        <div className='mainContainer'>
          <div>
            <Alerts />
          </div>
          <div className='content'>{this.props.currentModule === 'returns' ? <Returns /> : ''}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ appReducer }) => {
  const { currentModule, spinnerEnable } = appReducer;
  return {
    currentModule,
    spinnerEnable
  };
};

export default connect(mapStateToProps)(Layout);
