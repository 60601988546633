import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteSuccessMessage, deleteErrorMessage } from '../action';
import './Alerts.scss';
import { ReactComponent as BlackCloseLogo } from '../../assets/blackClose.svg';
import { ReactComponent as WhiteCloseLogo } from '../../assets/blackClose.svg';

class Alerts extends React.Component {
  
  render() {
    return (
      <div className="AlertDiv">
        {this.props.successMessages.map((message, index) => {
          return (
            <div key={`success_message_app_${index}`}>
              <div className="alertImagediv">
                <BlackCloseLogo
                  onClick={() => this.props.deleteSuccessMessage(index)}
                />
              </div>
              <div className="successAlert">{message}</div>
            </div>
          );
        })}
        {this.props.errorMessages.map((message, index) => {
          return (
            <div key={`error_message_app_${index}`}>
              <div className="alertImagediv">
                <WhiteCloseLogo
                  onClick={() => this.props.deleteErrorMessage(index)}
                />
              </div>
              <div className="errorAlert">{message}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = ({ appReducer }) => {
  const { successMessages, errorMessages } = appReducer;
  return { successMessages, errorMessages };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteSuccessMessage,
      deleteErrorMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
