import React from 'react';
const dayjs = require('dayjs');
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
const dateFormat = 'MM/DD/YYYY';
import * as Styled from './SBDateRangePicker.styles';

const SBDateRangePicker = props => {
  const currentDate = new Date();
  const oneWeekAhead = new Date();
  oneWeekAhead.setDate(oneWeekAhead.getDate() + 7);

  const fromDate = props?.fromDate ? props.fromDate : `${currentDate.getMonth()}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  const toDate = props?.toDate ? props.toDate : `${oneWeekAhead.getMonth()}/${oneWeekAhead.getDate()}/${oneWeekAhead.getFullYear()}`;

  return (
    <Styled.DateRangeContainer>
      <label>{props.label}</label>
      <RangePicker
        value={[dayjs(fromDate), dayjs(toDate)]}
        format={dateFormat}
        onChange={(date, dateString) => {
          props?.onChange(dateString);
        }}
      />
    </Styled.DateRangeContainer>
  );
};

export default SBDateRangePicker;
