import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closePackListModal } from './action';
import './RmaDashModal.scss';
import _ from 'lodash'

function printContent(props) {
  var frame1 = document.createElement('iframe');
  frame1.name = 'frame1';
  frame1.style.position = 'absolute';
  frame1.style.top = '-1000000px';
  document.body.appendChild(frame1);
  var frameDoc = frame1.contentWindow
    ? frame1.contentWindow
    : frame1.contentDocument.document
    ? frame1.contentDocument.document
    : frame1.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write(
    `<html><head></head><body><p style="
        font-family: Apercu Pro;
        font-size:18px;
        color: #000000;
        padding-bottom: 15px,
      "}}">PackingList for ${props.rmaInfoInShipPackModal.rmaInfo.rmaNumber}</p>`
  );
  const programType = _.get(props.rmaInfoInShipPackModal.rmaInfo, "programType", "") ;
  let content = `<table style="border-collapse: collapse;">
  <tbody style=" display: block;width: 100%;">
    <tr >
      <th style="  border: 1px solid #dddddd;
      text-align: left;
      margin: 0;
      width: 300px;
      padding: 20px;">Part number</th>
      <th style="  border: 1px solid #dddddd;
      text-align: left;
      margin: 0;
      width: 300px;
      padding: 20px;">${programType !== "MA" ? 'Serial Number' : 'ABC Number' }</th>
      <th style="  border: 1px solid #dddddd;
      text-align: left;
      margin: 0;
      width: 300px;
      padding: 20px;">Return reason</th>
      <th style="  border: 1px solid #dddddd;
      text-align: left;
      margin: 0;
      width: 300px;
      padding: 20px;">Quantity</th>`;

    if (programType === "MA") {
      content += `<th style="  border: 1px solid #dddddd;
        text-align: left;
        margin: 0;
        width: 300px;
        padding: 20px;">Status</th>`
    }
    content += `</tr>`;
  
    props.rmaInfoInShipPackModal.parts.map(
      (part) =>
        {
          content += `<tr className="rmaDashModalTabletr">`;
          content += `<td style="  border: 1px solid #dddddd;
            text-align: left;
            margin: 0;
            width: 300px;
            padding: 20px;">${part.partNumber}</td>
            <td style="  border: 1px solid #dddddd;
            text-align: left;
            margin: 0;
            width: 300px;
            padding: 20px;">
              ${_.get(props.rmaInfoInShipPackModal.rmaInfo, "programType", "") !== "MA" ? part.partSerialNumber : part.asurionBarcode }
            </td>
            <td style="  border: 1px solid #dddddd;
            text-align: left;
            margin: 0;
            width: 300px;
            padding: 20px;">${part.itemNotes !== 'null' ? part.itemNotes : ''}</td>
            <td style="  border: 1px solid #dddddd;
            text-align: left;
            margin: 0;
            width: 300px;
            padding: 20px;">${
              props.rmaInfoInShipPackModal.rmaInfo.returnType === 'SP_INITIATED_FSL'
                ? part.requiredQuantity
                : part.scanQuantity
            }</td>`;
          if (programType === "MA") {
            content += `<td style="  border: 1px solid #dddddd;
              text-align: left;
              margin: 0;
              width: 300px;
              padding: 20px;">${part.status}</td>`;
          }
          content += `</tr>`;          
        }
    );
  content = content + `</tbody></table>`;
  frameDoc.document.write(content);
  frameDoc.document.write('</body></html>');
  frameDoc.document.close();
  setTimeout(function () {
    window.frames['frame1'].focus();
    window.frames['frame1'].print();
    document.body.removeChild(frame1);
  }, 500);
  return false;
}

const PackingListModal = (props) => {
  return (
    <div id="packListlModel" className="rmaDashModal">
      <div className="modal-content">
        <div id="packListContent" style={{ width: '100%' }}>
          <p
            style={{
              fontFamily: 'Apercu Pro',
              fontSize: '18px',
              color: '#000000',
              paddingBottom: '15px'
            }}
          >
            Packing List for {props.rmaInfoInShipPackModal.rmaInfo.rmaNumber}
          </p>
          {props.rmaInfoInShipPackModal.parts.length > 0 ? (
            <table className="rmaDashModalTable">
              <tbody className="rmaDashModalTableBody">
                <tr className="rmaDashModalTabletr">
                  <th className="rmaDashModalTableth">Part number</th>
                  {_.get(props.rmaInfoInShipPackModal.rmaInfo, "programType", "") !== "MA" ? 
                  (<th className="rmaDashModalTableth">Serial number</th>) :
                  (<th className="rmaDashModalTableth">ABC Number</th>)}
                  <th className="rmaDashModalTableth">Return reason</th>
                  <th className="rmaDashModalTableth">Quantity</th>
                  {_.get(props.rmaInfoInShipPackModal.rmaInfo, "programType", "") === "MA" &&
                    <th className="rmaDashModalTableth">Status</th>
                  }
                </tr>
                {props.rmaInfoInShipPackModal.parts.map((part, id) => (
                  <tr
                    id={`rma-info-in-ship-row-${id}`} 
                    key={`rma-info-in-ship-row-${id}`} 
                    className="rmaDashModalTabletr"
                    >
                    <td className="rmaDashModalTabletd">{part.partNumber}</td>
                    <td className="rmaDashModalTabletd">
                      {_.get(props.rmaInfoInShipPackModal.rmaInfo, "programType", "") !== "MA" ? part.partSerialNumber : part.asurionBarcode }
                    </td>
                    <td className="rmaDashModalTabletd">
                      {part.itemNotes !== 'null' ? part.itemNotes : ''}
                    </td>
                    <td className="rmaDashModalTabletd">
                      {props.rmaInfoInShipPackModal.rmaInfo.returnType ===
                      'SP_INITIATED_FSL'
                        ? part.requiredQuantity
                        : part.scanQuantity}
                    </td>
                    {_.get(props.rmaInfoInShipPackModal.rmaInfo, "programType", "") === "MA" &&
                      <td className="rmaDashModalTabletd">
                        {getStatus(_.get(props, 'codeDescriptionList'), part.status)}
                      </td>}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>no parts on the RMA</p>
          )}
        </div>
        <div id="model footer" style={{ width: '100%' }}>
          <button className="modalButton" onClick={props.closePackListModal}>
            Cancel
          </button>
          <button className="modalButton" onClick={() => printContent(props)}>
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

const getStatus = (codeDescriptionList, status) => {
  const res = _.findIndex(codeDescriptionList, { "codeValue": status });
  if (res < 0) return status;
  return codeDescriptionList[res]['description'];
};

const mapStateToProps = ({ rmadashboardReducer }) => {
  const { userType, rmaInfoInShipPackModal, codeDescriptionList } = rmadashboardReducer;
  return {
    userType,
    rmaInfoInShipPackModal,
    codeDescriptionList
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closePackListModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PackingListModal);
