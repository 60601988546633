import { SET_INFO_FOR_DYNAMIC_SCAN_PAGE, SET_RMA_INFO_FOR_DYNAMIC_SCAN_PAGE, SET_RMA_SCAN_SUCCESSFUL } from '../../../actionTypes';

const initialState = {
  pageData: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO_FOR_DYNAMIC_SCAN_PAGE:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          programType: action.data.programType,
          sourceLocationId: action.data.sourceLocationId,
          sourceDescription: action.data.sourceDescription,
          serviceProviderLocationId: action.data.serviceProviderLocationId,
          externalReferenceNumber: action.data.externalReferenceNumber ? action.data.externalReferenceNumber : null,
          createWithDuplicateRefNumber: action.data.createWithDuplicateRefNumber ? action.data.createWithDuplicateRefNumber : false,
          rmaArray: action.data.rmaArray,
          rmaScanSuccessful: false
        }
      };
    case SET_RMA_INFO_FOR_DYNAMIC_SCAN_PAGE:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          rmaInfo: action.data
        }
      };
    case SET_RMA_SCAN_SUCCESSFUL:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          rmaScanSuccessful: action.data
        }
      };
    default:
      return state;
  }
};
