import styled from 'styled-components';

export const ScanParent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid #a5aaaf;
  box-sizing: border-box;
`;

export const ScanContent = styled.div`
  flex: 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  padding: 16px;
  gap: 32px;
`;

export const ScanButtonContent = styled.div`
  flex: 3;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
`;

export const ShippingOrigin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`;
