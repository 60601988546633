import { isEmpty } from '@sbx/sbx-deimos-reactui-common/genericUtility';

export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const SHOW_WARNING = 'SHOW_WARNING';
export const HIDE_ALERT = 'HIDE_ALERT';

const error = {
  code: '',
  message: ''
};
const success = {
  code: '',
  message: ''
};

export const showErrorMessage = message => dispatch => {
  dispatch({ type: SHOW_ERROR, payload: isEmpty(message) ? error.message : message });
};

export const hideAlert = () => dispatch => {
  dispatch({ type: HIDE_ALERT });
};
export const showSuccessMessage = message => dispatch => {
  dispatch({ type: SHOW_SUCCESS, payload: isEmpty(success.message) ? message : success.message });
  setTimeout(() => dispatch({ type: HIDE_ALERT }), 20000);
};

export const showWarningMessage = message => dispatch => {
  dispatch({ type: SHOW_WARNING, payload: message });
  setTimeout(() => dispatch({ type: HIDE_ALERT }), 20000);
};

export const hasError = res => {
  error.code = '';
  error.message = '';
  if (res && res.Error) {
    error.code = res.Error.Code;
    error.message = res.Error.Message;
    return { code: error.code, message: error.message };
  } else if (res && (res.error || res.errors)) {
    const err = res.errors ? res.errors[0] : res.error;
    error.code = err.code || err.statusCode;
    error.message = err.description || err.message || err.responseContent || err;
    return { code: error.code, message: error.message };
  } else if (res instanceof Error) return true;
  else return false;
};

export const hasSuccess = res => {
  success.code = '';
  success.message = '';
  if (res && res.Success) {
    success.code = res.Success.Code;
    success.message = res.Success.Message;
    return true;
  } else if (res && (res.success || res.successs)) {
    const succ = res.successs ? res.successs[0] : res.success;
    success.code = succ.code;
    success.message = succ.description ? succ.description : succ.message;
    success.message = success.message ? success.message : succ;
    return true;
  } else if (res instanceof Success) return true;
  else return false;
};
