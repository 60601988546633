import {
  SET_APPLICATION_NAVIGATION,
  SET_APPLICATION_SUCCESS_MESSAGES,
  ADD_APPLICATION_SUCCESS_MESSAGE,
  DELETE_APPLICATION_SUCCESS_MESSAGE,
  SET_APPLICATION_ERROR_MESSAGES,
  ADD_APPLICATION_ERROR_MESSAGE,
  DELETE_APPLICATION_ERROR_MESSAGE,
  ENABLE_APPLICATION_SPINNER,
  DISABLE_APPLICATION_SPINNER,
  SET_APPLICATION_STATUS_INTIALIZE,
  SET_APPLICATION_STATUS_READY
} from '../actionTypes';
const initialState = {
  successMessages: [],
  errorMessages: [],
  currentModule: 'returns',
  spinnerEnable: false,
  appStatus: 'INITIALIZED'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APPLICATION_NAVIGATION:
      return {
        ...state,
        currentModule: action.data
      };
    case SET_APPLICATION_SUCCESS_MESSAGES:
      return {
        ...state,
        successMessages: action.data
      };
    case SET_APPLICATION_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: action.data
      };
    case ADD_APPLICATION_SUCCESS_MESSAGE:
      let asm = state.successMessages;
      asm.push(action.data);
      return {
        ...state,
        successMessages: [...asm]
      };
    case DELETE_APPLICATION_SUCCESS_MESSAGE:
      let dsm = state.successMessages;
      dsm.splice(action.data, 1);
      return {
        ...state,
        successMessages: [...dsm]
      };
    case ADD_APPLICATION_ERROR_MESSAGE:
      let aem = state.errorMessages;
      aem.push(action.data);
      return {
        ...state,
        errorMessages: [...aem]
      };
    case DELETE_APPLICATION_ERROR_MESSAGE:
      let dem = state.errorMessages;
      dem.splice(action.data, 1);
      return {
        ...state,
        errorMessages: [...dem]
      };
    case ENABLE_APPLICATION_SPINNER:
      return {
        ...state,
        spinnerEnable: true
      };
    case DISABLE_APPLICATION_SPINNER:
      return {
        ...state,
        spinnerEnable: false
      };
    case SET_APPLICATION_STATUS_INTIALIZE:
      return {
        ...state,
        appStatus: 'INITIALIZED'
      };
    case SET_APPLICATION_STATUS_READY:
      return {
        ...state,
        appStatus: 'READY'
      };
    default:
      return state;
  }
};
