import './RmaDashboard.scss';
import { Spin, Row, Col } from 'antd';
import { Text, Button } from '@sbx/sbx-ui-components';
import Incoming from './incoming/IncomingTransfers';
import Outgoing from './outgoing/OutgoingTransfers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialInfo, goToCreateTransferFlow, goToImportFlow, setCurrentSection } from './action';
import * as Styled from './TransferDashboard.styles';
import { userInfo } from '../../../utilities/apiUtility';

const TransferDashboard = () => {
  const dispatch = useDispatch();
  const {
    appReducer: { appStatus },
    rmadashboardReducer: { capabilities, currentTransfersSelection, userType },
    userSession: { userDetails }
  } = useSelector(state => state);

  useEffect(() => {
    if (!userType && userDetails && userInfo.companyId) {
      dispatch(getInitialInfo());
    }
  }, [userDetails, userInfo]);

  const redirectToImportFlow = () => {
    dispatch(goToImportFlow());
  };

  const redirectToCreateTransferFlow = () => {
    dispatch(goToCreateTransferFlow());
  };

  const setSection = section => {
    dispatch(setCurrentSection(section));
  };

  return (
    <>
      {appStatus === 'INITIALIZED' && (
        <Row type='flex' justify='center' align='middle'>
          <Col span={6} offset={6}>
            <Spin size='large' style={{ lineHeight: '200px' }} />
          </Col>
        </Row>
      )}
      {appStatus !== 'INITIALIZED' && (
        <div>
          <Styled.Header>
            <Text size={6}>Transfer Dashboard</Text>
            {userType === 'SA' ? (
              <Button variant='default' size='medium' onClick={redirectToImportFlow}>
                Create new RMA
              </Button>
            ) : capabilities.length > 0 ? (
              <Button variant='default' size='medium' onClick={redirectToCreateTransferFlow}>
                Start new Transfer
              </Button>
            ) : (
              ''
            )}
          </Styled.Header>
          <div className='selectiondiv'>
            <div onClick={() => setSection('incoming')} className={currentTransfersSelection === 'incoming' ? 'selectionHighlighter incoming' : 'incoming'}>
              <p>Incoming</p>
            </div>
            <div onClick={() => setSection('outgoing')} className={currentTransfersSelection === 'outgoing' ? 'selectionHighlighter outgoing' : 'outgoing'}>
              <p>Outgoing</p>
            </div>
          </div>
          <div>{currentTransfersSelection === 'incoming' ? <Incoming /> : <Outgoing />}</div>
        </div>
      )}
    </>
  );
};

export default TransferDashboard;
