import {
  SET_ORDER_INFO_FOR_SCAN_PAGE,
  SET_ORDER_CHANGES_SCAN_PAGE,
} from '../../../actionTypes';
const initialState = {
  OrderData: {},
  OrderChanges: {
    partList: [],
    serializedPartList: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_INFO_FOR_SCAN_PAGE:
      return {
        ...state,
        OrderData: action.data,
      };
    case SET_ORDER_CHANGES_SCAN_PAGE:
      return {
        ...state,
        OrderChanges: action.data,
      };
    default:
      return state;
  }
};
