import {
  SET_RETURNS_NAVIGATION,
  ADD_APPLICATION_SUCCESS_MESSAGE,
  ADD_APPLICATION_ERROR_MESSAGE,
  SET_APPLICATION_ERROR_MESSAGES,
  SET_APPLICATION_SUCCESS_MESSAGES,
  SET_TRUCK_PAGE_PARTS,
  SET_TRUCK_PAGE_STRUCTURED_PARTS_DATA,
  SET_TRUCK_PAGE_INITIAL_INFO
} from '../../../actionTypes';
import { Post, uriName } from '../../../utilities/apiUtility';

export const goToRmaDashboard = () => async dispatch => {
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_APPLICATION_SUCCESS_MESSAGES, data: [] });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaDashBoard' });
};

export const clearTruckPage = rmaNumber => async dispatch => {
  const cancelResponse = await Post(uriName, `/v1/transfergateway/rma/update`, {
    action: 'cancel',
    rmaNumber
  });
  if (cancelResponse && cancelResponse.status && cancelResponse.status.toUpperCase() === 'SUCCESS') {
    dispatch({ type: ADD_APPLICATION_SUCCESS_MESSAGE, data: `Transfer ${rmaNumber} has been cancelled.` });
  } else {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Something went wrong cancelling transfer ${rmaNumber}. Please try again.` });
  }
  dispatch({
    type: SET_TRUCK_PAGE_PARTS,
    data: {
      locationIdOrNumber: '',
      sourceLocationIdOrNumber: '',
      serializedPartList: [],
      partList: [],
      partListForUSDAndDOA: []
    }
  });
  dispatch({ type: SET_TRUCK_PAGE_INITIAL_INFO, data: {} });
  dispatch({ type: SET_TRUCK_PAGE_STRUCTURED_PARTS_DATA, data: {} });
};
export const togglechild = (currentpartNumber, structuredData, visible) => async dispatch => {
  if (structuredData) {
    let parts = {};
    Object.keys(structuredData).map(partNumber => {
      let part = {};
      part = structuredData[`${partNumber}`];
      if (currentpartNumber === partNumber) {
        part.childVisible = visible;
      }
      parts[`${partNumber}`] = part;
    });
    dispatch({
      type: SET_TRUCK_PAGE_STRUCTURED_PARTS_DATA,
      data: { ...parts }
    });
  }
};

export const saveTransfer = (parts, truckData) => async dispatch => {
  let body = {
    serviceProviderLocationId: truckData.serviceProviderLocationId,
    sourceParLocationId: truckData.sourcePartLocationId,
    destinationPartLocationId: truckData.destinationPartLocationId,
    direction: truckData.direction,
    serializedPartList: parts.serializedPartList,
    partList: parts.partList,
    transferNumber: truckData.transferNumber
  };

  try {
    let saveOrder = await Post(uriName, `/v1/transfergateway/truck/transfer`, body);
    if (saveOrder.status === 'success') {
      return saveOrder;
    } else {
      dispatch({
        type: SET_APPLICATION_ERROR_MESSAGES,
        data: ['saving transfer failed']
      });
      return saveOrder;
    }
  } catch (err) {
    dispatch({
      type: SET_APPLICATION_ERROR_MESSAGES,
      data: ['saving transfer failed']
    });
  }
  return false;
};

export const clearTruckChanges = parts => async dispatch => {
  try {
    parts.serializedPartList = [];
    parts.partList = [];
    dispatch({
      type: SET_TRUCK_PAGE_STRUCTURED_PARTS_DATA,
      data: {}
    });
    dispatch({
      type: SET_TRUCK_PAGE_PARTS,
      data: { ...parts }
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateTruckPage = parts => async dispatch => {
  let structuredData = {};
  for (let sku of parts.partList) {
    structuredData[sku.partNumber] = {
      isSerialized: false,
      childVisible: true,
      countsAtLocation: sku.countsAtLocation
    };
  }
  for (let sku of parts.serializedPartList) {
    let found = false;
    let skus = Object.keys(structuredData);
    if (skus.includes(sku.partNumber)) {
      found = true;
      structuredData[sku.partNumber].parts.push({
        partNumber: sku.partNumber,
        partSerialNumber: sku.serialNumber
      });
      structuredData[sku.partNumber].quantity = structuredData[sku.partNumber].quantity + 1;
      structuredData[sku.partNumber].childVisible = true;
    }
    if (!found) {
      structuredData[sku.partNumber] = {
        quantity: 1,
        parts: [
          {
            partNumber: sku.partNumber,
            partSerialNumber: sku.serialNumber
          }
        ],
        isSerialized: true,
        childVisible: true
      };
    }
  }
  dispatch({
    type: SET_TRUCK_PAGE_STRUCTURED_PARTS_DATA,
    data: structuredData
  });
};

export const checkSerial = (serial, truckData, parts) => async dispatch => {
  try {
    const response = await Post(uriName, `/v1/transfergateway/truck/part`, {
      serviceProviderLocationId: truckData.serviceProviderLocationId,
      sourceParLocationId: truckData.sourcePartLocationId,
      destinationPartLocationId: truckData.destinationPartLocationId,
      direction: truckData.direction,
      partOrSerialNumber: serial,
      transferNumber: truckData.transferNumber
    });
    if (response.status === 'success') {
      if (response.serial) {
        parts.serializedPartList.push({
          serialNumber: response.serial,
          partNumber: response.partNumber
        });
      } else {
        response.countsAtLocation = response.countsAtLocation.map(item => {
          return { ...item, quantityIncrement: 0 };
        });
        let part = {
          partNumber: response.partNumber,
          countsAtLocation: response.countsAtLocation
        };
        parts.partList.push(part);
      }
    } else {
    }
    dispatch({
      type: SET_TRUCK_PAGE_PARTS,
      data: parts
    });
    return response;
  } catch (err) {
    dispatch({
      type: SET_APPLICATION_ERROR_MESSAGES,
      data: ['unable to verify the sku or serial']
    });
  }
};

export const updateQuantity = (partNumber, countsAtLocation, parts, currentParts, partListForUSDAndDOA) => async dispatch => {
  currentParts[partNumber].countsAtLocation = countsAtLocation;
  parts.partList = parts.partList.map(item => {
    if (item.partNumber === partNumber) {
      for (let x of countsAtLocation) {
        if (x.status === 'IN_STOCK') {
          item.quantityIncrement = x.quantityIncrement;
        }
      }
    }
    return item;
  });
  let newUSDDOAPARTS = {};
  dispatch({
    type: SET_TRUCK_PAGE_PARTS,
    data: { ...parts }
  });
  dispatch({
    type: SET_TRUCK_PAGE_STRUCTURED_PARTS_DATA,
    data: { ...currentParts }
  });
};
