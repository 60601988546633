import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MessageModal from '../../common/MessageModal';
import ErrorBoundary from '../../common/ErrorBoundary';
import { ReactComponent as LeftArrowLogo } from '../../../assets/arrow1-left.svg';
import { ReactComponent as Expand } from '../../../assets/chevron-down.svg';
import { ReactComponent as Collapse } from '../../../assets/chevron-up.svg';
import { ReactComponent as EditPencil } from '../../../assets/pencil-1.svg';
import BarcodeReader from 'react-barcode-reader';
import { goToRmaDashboard } from '../scanningFlow/action';
import { enableSpinner, disableSpinner } from '../../action';
import { ButtonGroup, Button, Text } from '@soluto-private/mx-asurion-ui-react';
import { togglechild, checkSerial, updateTruckPage, saveTransfer, updateQuantity, clearTruckChanges, clearTruckPage } from './action';
import '../scanningFlow/ScanSerial.scss';
import './TruckFlow.scss';

const statusMap = {
  REMOVED: 'Removed',
  NOT_REQUIRED: 'Not Required',
  DEFECTIVE_REPLACED: 'Defective Replaced',
  UNINSTALLED: 'Uninstalled',
  DEFECTIVE: 'Defective',
  IN_STOCK: 'In Stock',
  DEAD_ON_ARRIVAL: 'Dead on Arrival'
};

class TruckFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      message: '',
      borderColor: '#377a80',
      afterSaveModalVisible: false,
      quantityModalVisible: false,
      currentPartNumber: '',
      quantity: 0,
      serial: '',
      dirtyData: false,
      errors: []
    };
    this.onScan = this.onScan.bind(this);
    this.modalOnOff = this.modalOnOff.bind(this);
    this.updatePartQuantity = this.updatePartQuantity.bind(this);
    this.setQuantity = this.setQuantity.bind(this);
  }

  async onScan(serial) {
    this.props.enableSpinner();
    let alreadyScanned = false;
    for (let serialPart of this.props.parts.serializedPartList) {
      if (serialPart.serialNumber === serial) {
        alreadyScanned = true;
      }
    }
    for (let nonSerialPart of this.props.parts.partList) {
      if (nonSerialPart.partNumber === serial) {
        alreadyScanned = true;
      }
    }
    if (!alreadyScanned) {
      let addresponse = await this.props.checkSerial(serial, this.props.truckData, this.props.parts);
      this.setState({ serial: serial });
      if (addresponse && addresponse.status === 'failure') {
        this.setState({
          showError: true,
          borderColor: '#db1b1b',
          message: addresponse.message
        });
      } else {
        this.props.updateTruckPage(this.props.parts);
      }
    } else {
      this.setState({
        showError: true,
        borderColor: '#db1b1b',
        message: 'already scanned'
      });
    }
    document.getElementById('scanInputBox').value = '';
    this.setState({ serial: '' });
    this.props.disableSpinner();
  }

  modalOnOff(bool, partNumber, countsAtLocation) {
    if (bool) {
      this.setState({ currentPartNumber: partNumber, countsAtLocation }, () => this.setState({ quantityModalVisible: bool }));
    } else {
      this.setState({ quantityModalVisible: bool });
    }
  }

  async updatePartQuantity() {
    await this.props.updateQuantity(this.state.currentPartNumber, this.state.countsAtLocation, this.props.parts, this.props.currentParts);
    this.modalOnOff(false);
  }
  setQuantity(quantity) {
    this.setState({ quantity: quantity });
  }

  render() {
    return (
      <div>
        <div className='ScanNavLabel'>
          {this.state.quantityModalVisible ? (
            <MessageModal
              id='quantityModal'
              header='Enter quantity'
              content={
                <div>
                  {this.state.countsAtLocation.map(item => (
                    <div className='quantityEditBox'>
                      <label for={item.status}>{statusMap[item.status] ? statusMap[item.status] : item.status}</label>
                      <input
                        type='number'
                        id={item.status}
                        className='quantityEditInputBox'
                        value={item.quantityIncrement}
                        min='0'
                        max={item.quantity}
                        onChange={event => {
                          let currCounts = this.state.countsAtLocation.map(i => {
                            if (i.status === item.status) {
                              i.quantityIncrement = event.target.value;
                            }
                            return i;
                          });
                          this.setState({ countsAtLocation: currCounts });
                        }}
                      ></input>{' '}
                      / {item.quantity}
                    </div>
                  ))}
                </div>
              }
              cancel={() => this.modalOnOff(false)}
              continue={() => {
                this.updatePartQuantity();
              }}
            />
          ) : (
            ''
          )}
          {this.state.afterSaveModalVisible ? (
            <MessageModal
              header='Transfer Completed'
              id='validationModal'
              contentStyle='validationModalContent'
              bodyStyle='validationModalBody'
              cancelName='stay and continue'
              continueName='go to dashboard'
              content={
                <div>
                  {this.state.errors.length > 0 ? (
                    <>
                      <p>errored out transfers</p>
                      <table className='unfulfilledReasonsTable'>
                        <tr>
                          <th>SKU/Serial</th>
                          <th>Error</th>
                        </tr>
                        {this.state.errors.map(row => (
                          <tr>
                            <td>{row.key}</td>
                            <td>{row.value}</td>
                          </tr>
                        ))}
                      </table>
                    </>
                  ) : (
                    <div>
                      <h1>Success</h1>
                    </div>
                  )}
                </div>
              }
              cancel={() => this.setState({ afterSaveModalVisible: false })}
              continue={this.props.goToRmaDashboard}
            />
          ) : (
            ''
          )}
          <LeftArrowLogo className='scanLeftArraow' />
          <a
            className='ScanNavLabel'
            href='#'
            onClick={() => {
              this.props.clearTruckPage(this.props.truckData.transferNumber);
              this.props.goToRmaDashboard();
            }}
          >
            Return to Transfer Dashboard
          </a>
        </div>
        <div className='TransferNumberTitle'>
          <Text size={5}>Truck Transfer {this.props.truckData.transferNumber}</Text>
        </div>
        <div className='ScanContent'>
          <div className='scanParentDiv'>
            <Text size={3} className='truckTitle'>
              {' '}
              Scan Serial No/Enter non-serialized sku
            </Text>
            <br />
            <br />
            <input
              id='scanInputBox'
              style={{ borderColor: this.state.borderColor }}
              value={this.state.serial}
              className='scanbox'
              onChange={event => {
                this.setState({
                  showError: false,
                  borderColor: '#377a80',
                  serial: event.target.value
                });
              }}
              onKeyDown={event => {
                if (event.keyCode === 13 && event.target.value !== '') {
                  this.onScan(event.target.value);
                }
              }}
            ></input>
            <div>
              <BarcodeReader onScan={this.onScan} />
            </div>
            {this.state.showError ? (
              <div className='scanMessage'>
                <p>{this.state.message}</p>
              </div>
            ) : (
              ''
            )}

            <div className='fromToInfoDiv'>
              <Text size={3} className='labeltext'>
                From
              </Text>
              <br />
              <Text size={5} className='locationNameText'>
                <ErrorBoundary>{this.props.truckData.sourceDescription}</ErrorBoundary>
              </Text>
            </div>
            <div className='fromToInfoDiv'>
              <Text size={3} className='labeltext'>
                To
              </Text>
              <br />
              <Text size={5} className='locationNameText'>
                {this.props.truckData.destinationDescription}
              </Text>
            </div>
            <button
              className={
                this.props.parts.partList.length > 0 || this.props.parts.serializedPartList.length > 0 ? 'transferbuttonEnabled' : 'transferbuttonDisabled'
              }
              disabled={this.props.parts.partList.length > 0 || this.props.parts.serializedPartList.length > 0 ? false : true}
              onClick={async () => {
                this.props.enableSpinner();
                let saveOrder = await this.props.saveTransfer(this.props.parts, this.props.truckData);
                if (saveOrder.status === 'success') {
                  await this.props.clearTruckChanges(this.props.parts);
                  this.setState({ afterSaveModalVisible: true });
                  this.setState({ errors: saveOrder.errors });
                }
                this.props.disableSpinner();
              }}
            >
              <p className='buttonText'>Save Transfer</p>
            </button>
          </div>
          <div className='partsDiv'>
            <Text size={4} className='title'>
              Scanned Parts
            </Text>
            <div className='partsContainer'>
              {Object.keys(this.props.currentParts).map(partNumber => {
                let backgroundcolor = '#f0fff9';
                return (
                  <div className='partContainer'>
                    <div className='partInfo' style={{ backgroundColor: backgroundcolor }}>
                      {partNumber}

                      {this.props.currentParts[`${partNumber}`].isSerialized ? (
                        <>
                          {' '}
                          <div style={{ float: 'right', marginRight: '5px' }}>{this.props.currentParts[`${partNumber}`].quantity}</div>
                          <div style={{ float: 'right' }}>
                            {!this.props.currentParts[`${partNumber}`].childVisible ? (
                              <Expand onClick={() => this.props.togglechild(partNumber, this.props.currentParts, true)} />
                            ) : (
                              <Collapse onClick={() => this.props.togglechild(partNumber, this.props.currentParts, false)} />
                            )}
                          </div>
                        </>
                      ) : (
                        <div style={{ float: 'right' }}>
                          <EditPencil onClick={() => this.modalOnOff(true, partNumber, this.props.currentParts[`${partNumber}`].countsAtLocation)} />
                        </div>
                      )}
                    </div>
                    {this.props.currentParts[`${partNumber}`].childVisible ? (
                      <div id={partNumber} className='rmascanchildtablediv'>
                        {this.props.currentParts[`${partNumber}`].isSerialized ? (
                          <table className='rmascanchildtable'>
                            {this.props.currentParts[`${partNumber}`].parts.map(part => {
                              return (
                                <tr className='serialRow'>
                                  <td>
                                    <div className='serial'>{part.partSerialNumber}</div>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        ) : this.props.currentParts[`${partNumber}`].countsAtLocation &&
                          Array.isArray(this.props.currentParts[`${partNumber}`].countsAtLocation) ? (
                          <>
                            {this.props.currentParts[`${partNumber}`].countsAtLocation.map(item => (
                              <div className='countsByStatusDiv'>
                                <p>
                                  {statusMap[item.status] ? statusMap[item.status] : item.status}: {item.quantityIncrement} / {item.quantity}
                                </p>
                              </div>
                            ))}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}{' '}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ truckFlowReducer }) => {
  const { truckData, currentParts, parts } = truckFlowReducer;
  return { truckData, currentParts, parts };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToRmaDashboard,
      enableSpinner,
      disableSpinner,
      togglechild,
      updateTruckPage,
      checkSerial,
      updateQuantity,
      saveTransfer,
      clearTruckChanges,
      clearTruckPage
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TruckFlow);
