import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as LeftArrowLogo } from '../../../assets/arrow1-left.svg';
import { ReactComponent as Vector } from '../../../assets/add-parts.svg'
import { goToRmaDashboard, createSurRma } from '../createTransferFlow/action';
import { addSuccessMessage, addErrorMessage, clearMessages } from '../../action';
import SearchableDropdown from '../../../components/common/SearchableDropdown';
import { searchServiceProviders, getspLocInfo, handleOnManualEntry } from './action'
import Dropdown from '../../../components/common/Dropdown';
import RmaManualImportTable from './RmaImportManualTable'
import { Post, uriName, userInfo } from '../../../utilities/apiUtility';
import { goToRmaSummarySuccess, setRmaSummary } from '../RmaDashboard/action';

class RmaImportManual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rmaCreateButton: true,
      disableSpSearch: false,
      disabledLocation: false,
      ServiceBenchId: "",
      spId: "",
      spLocId: "",
      spLocationName: "",
      spName: "",
      parts: [
        {
          "PartNumber": "", "SerialNumber": "", "DefectCode": "", "ReturnReason": "", "Quantity": ""
        }
      ]
    }
    this.validateAndExtractData = this.validateAndExtractData.bind(this);
  }
  updatePart = (index, attributeName, val) => {
    let parts = this.state.parts;
    parts[index][`${attributeName}`] = val;
    this.setState({
      parts: parts,
    })
  };
  deletePart = (index) => {
    let parts = this.state.parts;
    if (parts.length === 1) {
      parts = [];
    } else {
      parts.splice(index, 1);
    }
    this.setState({
      parts: parts,
    })
  };
  resetPage = () => {
    this.setState({
      disableSpSearch: false,
      disabledLocation: false,
      rmaCreateButton: true,
      parts: [
      ]
    })
  };
  addPart = () => {
    let parts = this.state.parts;
    parts.push({
      "PartNumber": "", "SerialNumber": "", "DefectCode": "", "ReturnReason": "", "Quantity": ""
    })
    this.setState({
      parts: parts,
    })
  };
  render() {
    return (
      <div>
        <div>
          <a
            className="ScanNavLabel"
            href="#"
            onClick={this.props.goToRmaDashboard}
          >
            <LeftArrowLogo className="scanLeftArraow" />
          Return to RMA Dashboard
        </a>
          <br />
          <h1>Create new RMA</h1> <br />
          <h4>Choose a provider to request parts from.</h4> <br />
          <div className="manualSpLocationDiv">
            <SearchableDropdown
              label="Service provider"
              onChange={this.props.searchServiceProviders}
              defaultDisabled={this.state.disableSpSearch}
              onSelect={(value, label) => {
                let content = {
                  providerId: value,
                };
                this.setState({
                  spId: value,
                  spName: this.props.serviceProviderList[0].providerName,
                  ServiceBenchId: this.props.serviceProviderList[0].accountNumber,
                  disableSpSearch: true
                })
                this.props.getspLocInfo(content);
              }}
              list={this.props.serviceProviderList}
            >
            </SearchableDropdown>
            <Dropdown
              label="Location"
              options={this.props.spLocations}
              defaultDisabled={this.state.disabledLocation}
              onChange={(event) => {
                this.setState({
                  spLocId: event.target.value,
                  spLocationName: event.target[event.target.selectedIndex].text,
                  disabledLocation: true
                })
              }}
            ></Dropdown>
          </div>
        </div>
        <br /> <br />
        <p>Requested parts</p>
        <RmaManualImportTable key={this.state.parts} parts={this.state.parts} spId={this.state.spId} spLocId={this.state.spLocId} updateParts={this.updatePart} deletePart={this.deletePart} />
        <Vector className="addparts" onClick={this.addPart}></Vector>
        <div className="manualSummarydiv" >
          {this.state.rmaCreateButton && (
            <button
              className="createRMASummaryButton"
              onClick={this.validateAndExtractData}
            >
              Create RMA
            </button>
          )}
          <button
            className="createRMASummaryButton"
            onClick={this.resetPage}
          >
            Reset
          </button>
        </div>
      </div>
    );
  }
  async createRmaAndGoToSummary() {
    // validation
    // this.setState({ rmaCreateButton: false });
    let rmaSummaryObject = [];
    let rmaCreationCount = 0;

    // grouping based on location ID
    let mainObject = this.props.summaryData;
    const groupedByLocationId = mainObject.reduce(function (r, a) {
      r[a.spLocId] = r[a.spLocId] || [];
      r[a.spLocId].push(a);
      return r;
    }, Object.create(null));

    const groupedByLocationIdArray = Object.values(
      groupedByLocationId
    ).reverse();

    // grouping based  on serialized and non-serialized  and calling ceeating RMA {"somedata":"sp-related", parts :[{},{},{}]}

    for (let i = 0; i < groupedByLocationIdArray.length; i++) {
      let groupedBysearializedParts = groupedByLocationIdArray[i].filter(
        (x) => x.searializedPart === '1'
      );
      let groupedBynonSerializedParts = groupedByLocationIdArray[i].filter(
        (x) => x.searializedPart === '0'
      );
      if (groupedBysearializedParts.length > 0) {
        // since sp and  location details  are same  for  each object
        let obj = groupedBysearializedParts[0];
        let rmaSpId = obj.spId;
        let rmalocId = obj.spLocId;
        let spName = obj.spName;
        let spLocationName = obj.spLocationName;
        let location = obj.Location;
        let customerAccount = obj.ServiceBenchId;
        let programType = obj.ProgramType;
        //  forming parts data
        let parts = [];
        let partsSet = [];
        groupedBysearializedParts.map((x, i) => {
          let part = {};
          if (partsSet.indexOf(x.PartNumber).toString() === "-1") {
            partsSet.push(x.PartNumber);
          }
          part.partNumber = x.PartNumber;
          part.DefectCode = x.DefectCode;
          part.partType = x.PartType;
          part.ReturnReason = x.ReturnReason;
          part.spLocId = x.spLocId;
          part.quantity = x.ReturnQuantity;
          part.spInventoryId = x.spInvId;
          part.serialNumber = x.SerialNumber;
          part.asurionBarCode = x.AsurionBarcode;
          part.returnStatus = "Requested"
          part.lineNumber = (partsSet.indexOf(x.PartNumber) + 1).toString();
          part.isSerialized = x.searializedPart;
          parts[i] = part;
          return x;
        });
        const serializedPartsResult = await this.processRMA(
          obj,
          rmaSpId,
          rmalocId,
          parts,
          spName,
          spLocationName,
          location,
          customerAccount,
          programType,
        );
        rmaSummaryObject.push(serializedPartsResult);
        rmaCreationCount++;
      }

      if (groupedBynonSerializedParts.length > 0) {
        // since sp and  location details  are same  for  each object
        let obj = groupedBynonSerializedParts[0];
        let rmaSpId = obj.spId;
        let rmalocId = obj.spLocId;
        let spName = obj.spName;
        let spLocationName = obj.spLocationName;
        let location = obj.spLocationName;
        let customerAccount = obj.ServiceBenchId;
        let programType = obj.ProgramType;
        //  forming parts data
        let parts = [];
        let partsSet = [];
        groupedBynonSerializedParts.map((x, i) => {
          let part = {};
          if (partsSet.indexOf(x.PartNumber).toString() === "-1") {
            partsSet.push(x.PartNumber);
          }
          part.partNumber = x.PartNumber;
          part.DefectCode = x.DefectCode;
          part.partType = x.PartType;
          part.returnStatus = "Requested"
          part.ReturnReason = x.ReturnReason;
          part.spLocId = x.spLocId;
          part.quantity = x.ReturnQuantity;
          part.spInventoryId = x.spInvId;
          part.serialNumber = x.SerialNumber;
          part.asurionBarCode = x.AsurionBarcode;
          part.lineNumber = (partsSet.indexOf(x.PartNumber) + 1).toString();
          part.isSerialized = x.searializedPart;
          parts[i] = part;
          return x;
        });
        const nonserializedPartsResult = await this.processRMA(
          obj,
          rmaSpId,
          rmalocId,
          parts,
          spName,
          spLocationName,
          location,
          customerAccount,
          programType,
        );
        rmaSummaryObject.push(nonserializedPartsResult);
        rmaCreationCount++;
      }
    }

    //need to enhance the funtionality to track rmaCreationCount
    if (rmaCreationCount > 0) {
      this.props.addSuccessMessage(
        'You successfully created ' + rmaCreationCount + ' RMA'
      );
      this.props.setRmaSummary(rmaSummaryObject);
    }
    this.props.goToRmaSummarySuccess();
  }
  async processRMA(
    obj,
    rmaSpId,
    rmalocId,
    parts,
    spName,
    spLocationName,
    location,
    customerAccount,
    programType,
  ) {
    let result = await this.props.createSurRma(
      rmaSpId,
      rmalocId,
      this.props.daxAddress,
      programType,
      parts
    );
    let returnObj = {};

    if (result && result.status) {
      if (result.status === 'success') {
        this.setState({ modalVisible: false });
        returnObj.parts = parts;
        returnObj.rmaNumber = result.rmaNumber;
        returnObj.rmalocId = rmalocId;
        returnObj.spName = spName;
        returnObj.spLocationName = spLocationName;
        obj.rmaNumber = result.rmaNumber;

        /* send out email */

        let rmaSpLocEmailInfo = await Post(
          uriName,
          `/v1/transfergateway/rma/rmaLocationEmailInfo`,
          {
            locationNumber: rmalocId,
          }
        );
        if (rmaSpLocEmailInfo.emailId === 'NOT_FOUND') {
          this.props.addErrorMessage(
            'Email Not Found for location: ' + location + '.'
          );
        }
        /*get location address */

        let rmaSpLocAddress = await Post(
          uriName,
          `/v1/transfergateway/rma/rmaLocationAddress`,
          {
            spLocationId: rmalocId,
          }
        );
        if (rmaSpLocAddress.address === 'NOT_FOUND') {
          this.props.addErrorMessage('address not found: ' + location + '.');
        } else {
          try {
            for (let i = 0; i < rmaSpLocEmailInfo.length; i++) {
              let rmaSendEmail = await Post(
                uriName,
                `/v1/transfergateway/rma/sendRmaEmail`,
                {
                  rmaNumber: result.rmaNumber,
                  emailId: rmaSpLocEmailInfo[i].EMAIL_ADDRESS,
                  addressName: rmaSpLocAddress.name,
                  addressContactName: rmaSpLocAddress.contactName,
                  addressLine1: rmaSpLocAddress.addressLine1,
                  addressLine2: rmaSpLocAddress.addressLine2,
                  addressCity: rmaSpLocAddress.addressCity,
                  addressState: rmaSpLocAddress.addressState,
                  addressZip: rmaSpLocAddress.addressZip,
                  parts: parts,
                }
              );
            }
          } catch (err) {
            this.props.addErrorMessage(
              'error sending emails ' + location + '.'
            );
          }
          /* send out email end */

          try {
            // sending request to DAX either 365 or 2009
            let rmaDaxResponse = await Post(
              uriName,
              `/v1/transfergateway/rma/daxnotifications`,
              {
                rmaNumber: result.rmaNumber,
                isDax365file: this.props.isDax365file,
                serviceProviderId: rmaSpId,
                customerAccount: customerAccount,
                parts: parts,
              }
            );

            if (rmaDaxResponse.ErrorCode === 'Dax call not successful') {
              this.props.addErrorMessage('dax update failed ' + location + '.');
            }
          } catch (err) {
            this.props.addErrorMessage('dax update failed ' + location + '.');
          }
        }
        return returnObj;
        // this.props.go;ToRmaSummarySuccess()
      } else if (result.status === 'failure') {
        this.props.addErrorMessage('Something went wrong when creating RMA. Please try again.');
      }
    } else {
      this.props.addErrorMessage('Something went wrong when creating RMA. Please try again.');
    }
  }

  validateAndExtractData = async () => {
    let spId;
    let spLocId;
    let validatedData = true;
    let partsList = [];
    let daxData;
    let destinationAddress = {};
    let data = this.state
    // validating manual parts 
    this.setState({ rmaCreateButton: false })
    if (!data.spId) {
      this.props.addErrorMessage(
        'Select Service Provider');
      this.setState({ rmaCreateButton: true })
      return;
    }
    if (!data.spLocId) {
      this.props.addErrorMessage('Select Location');
      this.setState({ rmaCreateButton: true })
      return;
    }

    // obtain desination address and  also validating for dax365 or 2009 end point
    let partsWithOutSerilasArray = [];
    let partsSet =  new Set()
    for (let i = 0; i < data.parts.length; i++) {
      partsList.push(data.parts[i].PartNumber);
      partsSet.add(data.parts[i].PartNumber)
      if(!data.parts[i].SerialNumber){
        partsWithOutSerilasArray.push(data.parts[i].PartNumber);
      }
    }

    if (partsList.length === 0) {
      this.props.addErrorMessage(
        'Please add parts');
      this.setState({ rmaCreateButton: true })
      return;
    }


    let partnumber = partsWithOutSerilasArray.filter((e, i, a) => a.indexOf(e) !== i);
    if (partnumber.length > 0) {
      this.props.addErrorMessage(`Cannot have duplicate Part Number ${partnumber[0]} `);
      this.setState({ rmaCreateButton: true });
      return;
    }

    try {
      let partList = [];
      data.parts.forEach(d => {
        let partData = {
          partNumber: d.PartNumber,
          spId: data.spId,
          spLocId: data.spLocId
        };
        partList.push(partData);
      });

      let rmaDaxInfo = await Post(uriName, `/v1/transfergateway/rma/getDaxInfo`, {
        partList: partList,
      });
      destinationAddress.name = rmaDaxInfo.name;
      destinationAddress.addressLine1 = rmaDaxInfo.addressLine1;
      destinationAddress.addressLine2 = rmaDaxInfo.addressLine2;
      destinationAddress.addressLine3 = rmaDaxInfo.addressLine3;
      destinationAddress.addressCity = rmaDaxInfo.addressCity;
      destinationAddress.addressState = rmaDaxInfo.addressState;
      destinationAddress.addressZip = rmaDaxInfo.addressZip;
      destinationAddress.addressCountry = rmaDaxInfo.addressCountry;

      if (rmaDaxInfo && rmaDaxInfo.count === partsSet.size) {
        // 365 parts    
        daxData = "true";
      } else if (rmaDaxInfo.count === 0) {
        // 2009 parts
        daxData = "false"
      }
      else {
        this.props.addErrorMessage(
          'Cannot have  D365 and non D365 records in the same RMA');
        this.setState({ rmaCreateButton: true });
        return;
      }
    }
    catch (err) {
      this.props.addErrorMessage(
        'Something went wrong when getting the DAX information details. Please try again.');
      this.setState({ rmaCreateButton: true });
      return;
    }
    // validating Ui attributes and aslo updating state 
    for (let i = 0; i < data.parts.length; i++) {
      spId = data.spId;
      data.parts[i].spId = spId;
      data.parts[i].spName = data.spName;
      spLocId = data.spLocId;
      data.parts[i].spLocId = spLocId;
      data.parts[i].spLocationName = data.spLocationName;
      data.parts[i].ServiceBenchId = data.ServiceBenchId;

      if (!data.parts[i].PartNumber) {
        this.props.addErrorMessage(`Row:${i + 1} Part Number cannot be empty.`);
        this.setState({ rmaCreateButton: true })
        return;
      }
      if (!data.parts[i].DefectCode) {
        this.props.addErrorMessage(`Row:${i + 1} Defect Code must be selected.`);
        this.setState({ rmaCreateButton: true })
        return;
      }
      if (!data.parts[i].ReturnReason) {
        this.props.addErrorMessage(`Row:${i + 1} Return Reason must be selected`);
        this.setState({ rmaCreateButton: true })
        return;
      }

      let rmaSpInvInfo = await Post(
        uriName,
        `/v1/transfergateway/rma/rmaInventoryInfo`,
        {
          serviceProviderId: spId,
          serviceProviderLocationId: spLocId,
          partNumber: data.parts[i].PartNumber,
        }
      );
      if (rmaSpInvInfo.spInventoryId === 'NOT_FOUND') {
        this.props.addErrorMessage(
          'Part: ' +
          data.parts[i].PartNumber +
          ' does not exist at location: ' +
          data.spLocationName +
          ' .  Please Correct the Data'
        );
        this.setState({ rmaCreateButton: true })
        return;
      }
      data.parts[i].spInvId = rmaSpInvInfo.spInventoryId;
      data.parts[i].searializedPart = rmaSpInvInfo.searlizedPart;

      if (isNaN(data.parts[i].Quantity)) {
        this.props.addErrorMessage(`Row:${i + 1} Quantity should be Number`);
        this.setState({ rmaCreateButton: true })
        return;
      }

      if (data.parts[i].searializedPart === "1" && data.parts[i].SerialNumber) {
        if (data.parts[i].Quantity && (data.parts[i].Quantity !== "1")) {
          this.props.addErrorMessage(`Row:${i + 1} Quantity should be 1 or blank`);
          this.setState({ rmaCreateButton: true })
          return;
        }
        data.parts[i].ReturnQuantity = "1"
      } else {
        if (data.parts[i].searializedPart !== "1" && data.parts[i].SerialNumber) {
          this.props.addErrorMessage(`Row:${i + 1} ${data.parts[i].PartNumber} cannot have SerialNumber`);
          this.setState({ rmaCreateButton: true })
          return;
        }
        if (!data.parts[i].Quantity) {
          this.props.addErrorMessage(`Row:${i + 1} Quantity cannot be empty`);
          this.setState({ rmaCreateButton: true })
          return;
        }
        if (Number(data.parts[i].Quantity) < 1) {
          this.props.addErrorMessage(`Row:${i + 1} Quantity cannot be negative or Zero`);
          this.setState({ rmaCreateButton: true })
          return;
        }
        data.parts[i].ReturnQuantity = data.parts[i].Quantity;
      }
      //validate serial Number not done 
      if (data.parts[i].SerialNumber) {
        let rmaSerialInfo = await Post(
          uriName,
          `/v1/transfergateway/rma/validateRmaSerial`,
          {
            serialNumber: data.parts[i].SerialNumber,
            partNumber: data.parts[i].PartNumber,
            serviceProviderId: spId,
            serviceProviderLocationId: spLocId
          }
        );

        if (rmaSerialInfo.serialId === 'NOT_FOUND') {
          this.props.addErrorMessage('Invalid Serial:' + data.parts[i].SerialNumber);
          this.setState({ rmaCreateButton: true })
          return;
        }

        if (rmaSerialInfo.partStatus && rmaSerialInfo.partStatus.toUpperCase() === 'INSALLED') {
          this.props.addErrorMessage(`Serial ${data.parts[i].SerialNumber} installed in service job`);
          this.setState({ rmaCreateButton: true })
          return;
        }
      }
    }

    try {
      await this.props.handleOnManualEntry(data.parts, daxData, destinationAddress);
      await this.createRmaAndGoToSummary();
    }
    catch (err) {
      this.props.addErrorMessage('technical Error ! please try again');
      this.setState({ rmaCreateButton: true })
      return;
    }

  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addErrorMessage,
      goToRmaDashboard,
      searchServiceProviders,
      getspLocInfo,
      addErrorMessage,
      handleOnManualEntry,
      createSurRma,
      addSuccessMessage,
      goToRmaSummarySuccess,
      setRmaSummary
    },
    dispatch
  );
const mapStateToProps = ({ rmaImportReducer }) => {
  const {
    serviceProviderList, spLocations, isDax365file, daxAddress, summaryData
  } = rmaImportReducer;
  return {
    serviceProviderList, spLocations, summaryData, daxAddress, isDax365file
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RmaImportManual)
