import './Dropdown.scss';

const Dropdown = props => {
  const { label, options, onChange, defaultDisabled, selectedValue, value } = props;
  return (
    <div>
      <div className='genericDropdown'>
        <label className='selectLabel'>{label}</label>
      </div>
      <div style={{ width: '100%' }}>
        <select name={value} id={value} className='select' disabled={defaultDisabled ? true : null} onChange={event => onChange(event)} value={selectedValue}>
          {options
            ? options.map((item, idx) => (
                <option id={`key__option__${idx}`} key={`key__option__${idx}`} value={item.value}>
                  {item.label}
                </option>
              ))
            : ''}
        </select>
      </div>
    </div>
  );
};
export default Dropdown;
