import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 95vh;
  width: 100vw;
  padding: 16px;
`;

export const PageContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`;

export const RmaInfoListContainer = styled.div`
  flex: 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px;
  margin-right: 16px;
`;
