import {
  SET_RMA_INFO_SUMMARY_PAGE,
  SET_RMA_INFO_CREATION_PAGE,
  SET_UPLOAD_FILE_NAME,
  SET_RMA_SUMMARY,
  SET_DAX_365,
  SET_DESTINATION_ADDRESS,
  SET_RMA_MANUAL_IMPORT_SP_INFO,
  SET_SP_LOCATION_LIST_MANUAL_IMPORT ,
  SET_UPLOAD_SUMMARY_DATA
} from '../../../actionTypes';


const initialState = {
  summaryData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RMA_INFO_SUMMARY_PAGE:
      return {
        ...state,
        summaryData: action.data,
      };
    case SET_UPLOAD_SUMMARY_DATA:
      return {
        ...state,
        uploadSummary: action.data,
      };
    case SET_UPLOAD_FILE_NAME:
      return {
        ...state,
        fileName: action.data,
      };
    case SET_DAX_365:
      return {
        ...state,
        isDax365file: action.data,
      };
    case SET_RMA_MANUAL_IMPORT_SP_INFO :
        return {
          ...state,
          serviceProviderList: action.data,
        };
    case SET_RMA_SUMMARY:
      return {
        ...state,
        rmaSummary: action.data,
      };
    case SET_SP_LOCATION_LIST_MANUAL_IMPORT:
      return {
        ...state,
        spLocations: action.data,
      };
    case SET_DESTINATION_ADDRESS:
      return {
        ...state,
        daxAddress: action.data,
      };
    case SET_RMA_INFO_CREATION_PAGE:
      return {
        ...state,
        summaryData: action.data,
      };
    default:
      return state;
  }
};



