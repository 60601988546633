import * as Styled from './SerializedPart.styles';
import { Text } from '@sbx/sbx-ui-components';
import { ReactComponent as Expand } from '../../../assets/chevron-down.svg';
import { ReactComponent as Collapse } from '../../../assets/chevron-up.svg';

const SerializedPart = ({ part, collapsedParts }) => {
  
  const { partNumber, scanQuantity } = part;

  return (
    <Styled.PartInfoDiv bottomBorder='none'>
      <Text size='3'>Part Number: {partNumber}</Text>
      <Styled.PartInfoAction>
        <Text size='2'>Quantity: {scanQuantity}</Text>
        {collapsedParts[partNumber] ? <Expand /> : <Collapse />}
      </Styled.PartInfoAction>
    </Styled.PartInfoDiv>
  );
};

export default SerializedPart;
