import { SET_RETURNS_NAVIGATION, SET_RMA_INFO_FOR_SCAN_PAGE, SET_APPLICATION_ERROR_MESSAGES, SET_APPLICATION_SUCCESS_MESSAGES } from '../../../actionTypes';
import { Post, uriName } from '../../../utilities/apiUtility';

export const goToRmaDashboard = () => async dispatch => {
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_APPLICATION_SUCCESS_MESSAGES, data: [] });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaDashBoard' });
};

export const addSerial = (rmaNumber, locationIdOrNumber, serialNumber) => async disaptch => {
  try {
    const response = await Post(uriName, `/v1/transfergateway/rma/part`, {
      action: 'add',
      transferNumber: rmaNumber,
      locationIdOrNumber,
      serialNumber
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const updateparts = (rmaNumber, partNumber, locationIdOrNumber) => async dispatch => {
  const response = await Post(uriName, `/v1/transfergateway/rma/rmaInfo`, {
    rmaNumber,
    locationIdOrNumber
  });
  if (response && response.parts && response.parts.length > 0) {
    let parts = {};
    for await (let part of response.parts) {
      if (parts[part.partNumber]) {
        parts[part.partNumber]['parts'].push(part);
        parts[part.partNumber]['scannedQuantity'] = parts[part.partNumber]['scannedQuantity'] + part.scanQuantity;
        parts[part.partNumber]['requestedQuantity'] = parts[part.partNumber]['requestedQuantity'] + 1;
      } else {
        if (part.isSerialized) {
          let newpart = {
            scannedQuantity: part.scanQuantity,
            requestedQuantity: 1,
            childVisible: false,
            parts: [part],
            isSerialized: true
          };
          if (partNumber === part.partNumber) {
            newpart.childVisible = true;
          }
          parts[part.partNumber] = newpart;
        } else {
          let newpart = {
            scannedQuantity: part.scanQuantity,
            requestedQuantity: part.requiredQuantity,
            parts: [part],
            isSerialized: false,
            childVisible: true
          };
          parts[part.partNumber] = newpart;
        }
      }
    }
    response.parts = parts;
  } else {
    response.parts = {};
  }
  dispatch({ type: SET_RMA_INFO_FOR_SCAN_PAGE, data: response });
};

export const togglechild = (currentpartNumber, rmaInfo, visible) => async dispatch => {
  if (rmaInfo && rmaInfo.parts) {
    let parts = {};
    Object.keys(rmaInfo.parts).map(partNumber => {
      let part = {};
      if (currentpartNumber === partNumber) {
        part = rmaInfo.parts[`${partNumber}`];
        part.childVisible = visible;
      } else {
        part = rmaInfo.parts[`${partNumber}`];
      }
      parts[`${partNumber}`] = part;
    });
  }
  dispatch({ type: SET_RMA_INFO_FOR_SCAN_PAGE, data: { ...rmaInfo } });
};

export const deleteSerial = (rmaNumber, serialNumber) => async dispatch => {
  const response = await Post(uriName, `/v1/transfergateway/rma/part`, {
    action: 'delete',
    transferNumber: rmaNumber,
    serialNumber
  });
  return response;
};

export const updateQuantity = (rmaNumber, partNumber, quantity) => async dispatch => {
  const response = await Post(uriName, `/v1/transfergateway/rma/part`, {
    action: 'update',
    transferNumber: rmaNumber,
    partNumber,
    statusQuantities: quantity
  });
  return response;
};

export const generatePackingList = (rmaNumber, partLocationId, reasons) => async dispatch => {
  let response;
  if (reasons && reasons.length > 0) {
    response = await Post(uriName, `/v1/transfergateway/rma/update`, {
      action: 'generatePackingList',
      rmaNumber,
      partLocationId,
      reasons
    });
  } else {
    response = await Post(uriName, `/v1/transfergateway/rma/update`, {
      action: 'generatePackingList',
      rmaNumber,
      partLocationId
    });
  }
  return response;
};
