import * as Styled from './PartInfo.styles';
import SerialInfo from './SerialInfo';
import { Fragment, useState } from 'react';
import SerializedPart from './SerializedPart';
import NonSerializedPart from './NonSerializedPart';
import { useDispatch, useSelector } from 'react-redux';
import PartQuantityModal from './PartQuantityModal';
import { handleSerialQuantityUpdate } from './action';

const PartInfo = ({ rmaInfo, part }) => {
  
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({
    showModal: false,
    rmaNumber: '',
    partNumber: '',
    partStatus: '',
    partQuantity: 0
  });
  const [collapsedParts, setCollapsedParts] = useState({});
  const rmaInfoList = useSelector(state => state.rmaScanDynamicReducer.pageData.rmaInfo);

  const togglePart = partNumber => {
    setCollapsedParts(prev => ({
      ...prev,
      [partNumber]: !prev[partNumber]
    }));
  };

  const isSerialized = part => {
    return Number(part.isSerialized) === 1 ? true : false;
  };

  const setPartQuantity = partQuantity => {
    setModalData({
      ...modalData,
      partQuantity: partQuantity
    });
  };

  const setPartDetailAndShowModal = (rmaNumber, partNumber, partStatus, partQuantity) => {
    setModalData({
      showModal: true,
      rmaNumber: rmaNumber,
      partNumber: partNumber,
      partStatus: partStatus,
      partQuantity: partQuantity
    });
  };

  const closeModal = () => {
    setModalData({
      showModal: false,
      rmaNumber: '',
      partNumber: '',
      partStatus: '',
      partQuantity: 0
    });
  };

  const submitModal = () => {
    dispatch(handleSerialQuantityUpdate(modalData, rmaInfoList));
    closeModal();
  };

  return (
    <Fragment>
      {modalData.showModal ? (
        <PartQuantityModal modalData={modalData} setPartQuantity={setPartQuantity} closeModal={closeModal} submitModal={submitModal} />
      ) : (
        ''
      )}
      <Styled.PartInfoContainer onClick={() => togglePart(part.partNumber)}>
        {isSerialized(part) ? (
            <SerializedPart part={part} collapsedParts={collapsedParts} />
        ) : (
            <NonSerializedPart part={part} rmaInfo={rmaInfo} setPartDetailAndShowModal={setPartDetailAndShowModal} />
        )}
      </Styled.PartInfoContainer>
      {!collapsedParts[part.partNumber] && isSerialized(part) && (
        <Styled.ScannedSerialsContainer>
          {part.serialList.map((serialNumber, idx) => (
            <SerialInfo key={serialNumber} rmaInfo={rmaInfo} part={part} serialNumber={serialNumber} idx={idx} />
          ))}
        </Styled.ScannedSerialsContainer>
      )}
    </Fragment>
  );
};

export default PartInfo;
