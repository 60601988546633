import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MessageModal from '../../common/MessageModal';

class SuccessModal extends React.Component {
  constructor(props) {
    super(props);
    this.onContinue = this.props.onContinue.bind(this);
  }
  render() {
    return (
      <MessageModal
        id="successModal"
        header="Packing complete!"
        noCancel="true"
        contentStyle="successModalContent"
        bodyStyle="successModalBody"
        continueName="Return to Transfer Dashboard"
        content={
          <div>
            <p style={{ textAlign: 'left' }}>
              Next step is to print packing list shipping label and affix
              the shipping label to the box
              <br />
              We'll send you an email once the label is ready or you can
              check the Transfer Dashboard for updates.it should be ready
              in the next 5-10 minutes.
            </p>
          </div>
        }
        continue={this.onContinue}
      />      
    )
  }
};

// continue={this.props.goToRmaDashboard}

SuccessModal.propTypes = {
  onContinue: PropTypes.func
};

const mapStateToProps = ({ rmaScanMaReducer }) => {
    const { pageData } = rmaScanMaReducer;
    return {
      pageData,
    };
  };

const mapDispatchToProps = (dispatch) => 
  bindActionCreators({

  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal);
