import styled from 'styled-components';

export const PartInfoContainer = styled.div`
  width: 100%;
  padding: 16px 0px;
`;

export const ScannedSerialsContainer = styled.ul`
  width: 100%;
  padding-left: 10px;
`;
