import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropdown from '../../../components/common/Dropdown';
import '../RmaDashboard/table.scss';
import './RmaImportModal.scss'
import { ReactComponent as CloseButton } from '../../../assets/close2.svg';


const columns = [
  {
    Header: 'Part number',
    accessor: 'PartNumber',
    disableSortBy: true,
  },
  {
    Header: 'Serial number',
    accessor: 'SerialNumber',
    disableSortBy: true,
  },
  {
    Header: 'Defect code',
    accessor: 'DefectCode',
    disableSortBy: true,
  },
  {
    Header: 'Return reason',
    accessor: 'ReturnReason',
    disableSortBy: true,
  },
  {
    Header: 'Quantity',
    accessor: 'ReturnQuantity',
    disableSortBy: true,
  }
];

const reasonCodes = [{ label: "SELECT", value: "" }, { label: "CRE", value: "CRE" }, { label: "DEF", value: "DEF" }, { label: "DNO", value: "DNO" }, { label: "DOA", value: "DOA" }, { label: "GDU", value: "GDU" }, { label: "TMS", value: "TMS" }, { label: "UUU", value: "UUU" }, { label: "WPO", value: "WPO" }, { label: "WPS", value: "WPS" }]

const defectCodes = [{ label: "SELECT", value: "" }, { label: "CIS", value: "CIS" }, { label: "CPR", value: "CPR" }, { label: "IPD", value: "IPD" }, { label: "IPI", value: "IPI" }, { label: "NPD", value: "NPD" }, { label: "NPW", value: "NPW" }, { label: "PDG", value: "PDG" }, { label: "PDI", value: "PDI" }, { label: "SPR", value: "SPR" }, { label: "VHP", value: "VHP" }, { label: "WCR", value: "WCR" }, { label: "ASD", value: "ASD" }]

function RmaManualImportTable(props) {
  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { spId, spLocId },
    data
  } = useTable(
    {
      columns,
      data: props.parts,
      initialState: { spId: props.spId, spLocId: props.spLocId },
    },
    useSortBy,
    usePagination
  );

  return (
    <div style={{ maxHeight: "350px", overflow: "scroll" }}>
      <table className="rmaFiledashboardtable" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="rmaDashboardTableHeader"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? '▼' : '▲') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr className="dashrmatr" {...row.getRowProps()}>
                <td>
                  <input style={{ float: 'left', paddingRight: '12px' }} type="textBox" defaultValue={props.parts[i].PartNumber} name="Part number" onChange={(event) => {
                    props.updateParts(i, "PartNumber", event.target.value);
                  }} />
                </td>
                <td>
                  <input style={{ float: 'left', paddingRight: '12px' }} type="textBox" defaultValue={props.parts[i].SerialNumber} name="Serial number" onChange={(event) => {
                    props.updateParts(i, "SerialNumber", event.target.value);
                  }} /></td>
                <td>
                  <Dropdown
                    label=""
                    selectedValue={(props.parts[i].DefectCode) ? props.parts[i].DefectCode : ''}
                    options={defectCodes}
                    onChange={(event) => {
                      props.updateParts(i, "DefectCode", event.target.value);
                    }}
                  ></Dropdown>
                </td>
                <td>
                  <Dropdown
                    label=""
                    selectedValue={(props.parts[i].ReturnReason) ? props.parts[i].ReturnReason : ''}
                    options={reasonCodes}
                    onChange={(event) => {
                      props.updateParts(i, "ReturnReason", event.target.value);
                    }}
                  ></Dropdown>
                </td>
                <td>
                  <input style={{ float: 'left', paddingRight: '12px' }} type="textBox" defaultValue={props.parts[i].Quantity} name="Quantity" onChange={(event) => {
                    props.updateParts(i, "Quantity", event.target.value);
                  }} /></td>
                <td><CloseButton onClick={() => {
                  props.deletePart(i);
                }}></CloseButton></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = ({ rmaImportReducer }) => {
  const { summaryData } = rmaImportReducer;
  return {
    summaryData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {

    },
    dispatch
  );

export default connect(mapDispatchToProps)(RmaManualImportTable);
