export const URLS = {
  INTIAL_INFO_URL: '/v1/transfergateway/rma/initialInfo',
  SP_LOCATION_INFO: '/v1/transfergateway/spLocationInfo',
  CODEDESC_SERACH: '/v1/codedescription/search',
  TRANSFERS: '/v1/transfergateway/transfers',
  SOURCE_LOCATIONS: '/v1/transfergateway/sourceLocations',
  DESTINATION_LOCATIONS: '/v1/transfergateway/destinationLocations',
  CREATE_RMA: '/v1/transfergateway/rma/createTransfer',
  INFO_RMA: '/v1/transfergateway/rma/rmaInfo'
};
