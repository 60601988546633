import * as Styled from './RmaInfoList.styles';
import RmaInfo from './RmaInfo';
import { useSelector } from 'react-redux';
import { Text } from '@sbx/sbx-ui-components';

const RmaInfoList = () => {

  const { rmaInfo } = useSelector(state => state.rmaScanDynamicReducer.pageData);

  return rmaInfo ? (
    <Styled.RmaInfoContainer>
      {rmaInfo.map(info => (
        <RmaInfo key={info.rmaInfo.rmaNumber} rmaInfo={info} />
      ))}
    </Styled.RmaInfoContainer>
  ) : (
    <Styled.DefaultTextContainer>
      <Text size='4'>No RMA information to display. Scan a part.</Text>
    </Styled.DefaultTextContainer>
  );
};

export default RmaInfoList;
