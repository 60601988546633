import _ from 'lodash';
import {
  SET_RETURNS_NAVIGATION,
  SET_APPLICATION_ERROR_MESSAGES,
  SET_APPLICATION_SUCCESS_MESSAGES,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_TOGGLE_PART,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_SUCCESS,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATED_ABC_NUMBER,
  ADD_APPLICATION_ERROR_MESSAGE,
  ADD_APPLICATION_SUCCESS_MESSAGE,
  ENABLE_APPLICATION_SPINNER,
  DISABLE_APPLICATION_SPINNER
} from '../../../actionTypes';
import { Post, uriName } from '../../../utilities/apiUtility';
import config from '../../../config.json'
import {
  validateAsurionBarcodeMock,
  deleteSerialMock,
  generatePackingListCloseMock,
  addQuantityCloseMock,
  successModalCloseMock,
  readRmaInfoMock
} from '../../mocks'

export const toggleChild = (curPartNumber, visible) => async (
  dispatch
) => {
  dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_TOGGLE_PART, data: { partNumber: curPartNumber, visible } });
};

export const goToRmaDashboard = () => async (dispatch) => {
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_APPLICATION_SUCCESS_MESSAGES, data: [] });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaDashBoard' });
};

export const validateAsurionBarcode = (params) => async (dispatch) => {
  if (_.get(config, "mocks.api.addAsurionBarcode", false)) {
    return validateAsurionBarcodeMock(params)(dispatch);
  }
  try {
    const response = await Post(uriName, `/v1/transfergateway/rma/validateRmaABC`, {
      serviceProviderId: _.get(params, "providerId"),
      serviceProviderLocationId: _.get(params, "serviceProviderLocation"),
      inventoryRtnId: _.toString(_.get(params, "inventoryReturnId")),
      asurionBarcode: _.get(params, "asurionBarcode")
    });
    dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATED_ABC_NUMBER, data: response });
    return response;
  } catch (err) {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: 'Something went wrong when validating the ABC Barcode. Please try again.' });
  }
  return { };
};

export const addQuantityOpen = (params) => async (dispatch) => {
  dispatch({type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY, data: { ...params, updateModalVisible: true }});
};

export const addQuantityClose = (params) => async (dispatch) => {
  if (_.get(config, "mocks.api.updateQuantityClose", false)) {
    return addQuantityCloseMock(params)(dispatch);
  }  
  if (_.has(params, "cancel")) {
    dispatch({type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY, data: { updateModalVisible: false }});
  } else {
    try {
      const response = await Post(uriName, `/v1/transfergateway/rma/part`, {
        action: 'add',
        serviceProviderId: _.get(params, "providerId"),
        transferNumber: _.get(params, "rmaNumber"),
        partNumber: _.get(params, "parthNumber"),
        asurionBarcode: _.get(params, "asurionBarcode"),
        quantity: _.toNumber(_.get(params, "quantity"))
      });
      dispatch({type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY, 
        data: { updateModalVisible: false, quantity: _.get(params, "quantity", 0) }});
      readRmaInfo(params)(dispatch);
    } catch (err) {
      dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: 'Something went wrong when updating the quantity. Please try again.' });
      dispatch({type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY, data: { updateModalVisible: false }});
    }
  }
};

export const deleteSerial = (params) => async (dispatch) => {
  if (_.get(config, "mocks.api.deleteSerial", false)) {
    return deleteSerialMock(params)(dispatch);
  }  
  try {
    const response = await Post(uriName, `/v1/transfergateway/rma/part`, {
      action: 'delete',
      serviceProviderId: _.get(params, "providerId"),
      transferNumber: _.get(params, "rmaNumber"),
      asurionBarcode: _.get(params, "asurionBarcode")
    });
    readRmaInfo(params)(dispatch);
    dispatch({ type: ADD_APPLICATION_SUCCESS_MESSAGE, data: `Deleted successfully ${_.get(params, "asurionBarcode")}` });
    return response;
  } catch (err) {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: 'Something went wrong when deleting the ABC barcode. Please try again.' });    
  }
  return { };
};

export const readRmaInfo = (params) => async (dispatch) => {
  if (_.get(config, "mocks.api.readRmaInfo", false)) {
    return readRmaInfoMock(params)(dispatch);
  } 
  const rmaNumber = _.get(params, "rmaNumber");
  try {
    dispatch({ type: ENABLE_APPLICATION_SPINNER, data: true });
    const response = await Post(uriName, `/v1/transfergateway/rma/rmaInfo`, {
      rmaNumber: rmaNumber
    });
    dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE, data: response })
    return response;
  } catch (err) {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Something went wrong when reading RMA information details (${rmaNumber}). Please try again.` });
  } finally {
    dispatch({ type: DISABLE_APPLICATION_SPINNER, data: false });
  }
  return { };
};

export const generatePackingListOpen = (params) => async (dispatch) => {
  dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION, 
    data: { validatationModalVisible: true, details: _.get(params, "details", []) } });
};

export const generatePackingListClose = (params) => async (dispatch) => {
  if (_.get(config, "mocks.api.generatePackingListClose", false)) {
    return generatePackingListCloseMock(params)(dispatch);
  }  
  if (_.has(params, "cancel")) {
    dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION, data: { validatationModalVisible: false } });
  } else {
    try {
      dispatch({ type: ENABLE_APPLICATION_SPINNER, data: true });
      const response = await Post(uriName, `/v1/transfergateway/rma/update`, {
        action: 'generatePackingList',
        rmaNumber: _.get(params, "rmaNumber")
      });
      dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_SUCCESS, data: { successModalVisible: true } });
    } catch (err) {
      dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: 'Something went wrong when generating the packing list. Please try again.' });      
    } finally {
      dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION, data: { validatationModalVisible: false } });   
      dispatch({ type: DISABLE_APPLICATION_SPINNER, data: false });   
    }
  }
};

export const successModalClose = (params) => async (dispatch) => {
  if (_.get(config, "mocks.api.successModalClose", false)) {
    return successModalCloseMock(params)(dispatch);
  }    
  dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_SUCCESS, data: { successModalVisible: false } });
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_APPLICATION_SUCCESS_MESSAGES, data: [] });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaDashBoard' });  
}
