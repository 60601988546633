import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MessageModal from '../../common/MessageModal';
import _ from 'lodash';

class ValidationModal extends React.Component {
  constructor(props) {
    super(props);
    this.onCancel = props.onCancel.bind(this);
    this.onContinue = props.onContinue.bind(this);
    this.state = {
      doNotShowContinue: 'true',
      partsReasons: props.partsReasons
    };
  }
  updateReason(event, partNumber) {
    let reasons = this.state.partsReasons;
    let allResonsSelected = true;
    reasons = reasons.map((r) => {
        if (r.partNumber === partNumber) {
          r.reason = event.target.value;
        }
        
        if (!r.reason || r.reason == '') {
          allResonsSelected = false;
        }
        return r;
    });
    if (allResonsSelected) {
      this.state.doNotShowContinue = 'false'
    } else {
      this.state.doNotShowContinue = 'true'
    }
    this.setState({ partsReasons: [...reasons] });
  }
  componentDidMount() {
    if (_.isEmpty(this.state.partsReasons)) {
      this.onContinue({});
    }
  }
  render() {
    if (_.isEmpty(this.state.partsReasons)) {
      return ( <h1>No parts.</h1> );
    }
    return (
      <MessageModal
        id="validationModal"
        header="Part quantity discrepancy"
        contentStyle="validationModalContent"
        bodyStyle="validationModalBody"
        cancelName="Change quantities"
        continueName="Submit Transfer"
        noContinue= {this.state.doNotShowContinue}
        content={
          <React.Fragment>
            <p>
              The following parts are either over or under the quantity
              requested. Please provide a reason for this discrepancy or
              go back to add or remove parts.
            </p>              
            <table className="unfulfilledReasonsTable">
              <thead>
                <tr>
                  <th>Part number</th>
                  <th>Quantity</th>
                  <th>Reason for over/under</th>
                </tr>
              </thead>
              <tbody>
                {this.state.partsReasons.map((row, id) => (
                  <tr id={`validation_modal_${id}`} key={`validation_modal_${id}`}>
                    <td>{row.partNumber}</td>
                    <td>{row.quantity}</td>
                    <td>
                      <select onChange={(event) => this.updateReason(event, `${row.partNumber}`)} >
                        <option value="">Select</option>
                        <option value="Part was used">Part was used</option>
                        <option value="Part not found">Part not found</option>
                        <option value="Part was returned">Part was returned</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        }
        cancel={this.onCancel}
        continue={this.onContinue}
      />
    );
  }
};

ValidationModal.propTypes = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func, 
  partsReasons: PropTypes.array
};

const mapStateToProps = ({ rmaScanMaReducer }) => {
    const { pageData } = rmaScanMaReducer;
    return {
      pageData,
    };
  };

const mapDispatchToProps = (dispatch) => 
  bindActionCreators({

  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ValidationModal);