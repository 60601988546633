import _ from "lodash";

import { 
  SET_RMA_INFO_FOR_SCAN_MA_PAGE, 
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_TOGGLE_PART,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_SUCCESS,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_SELECT_RMA,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATED_ABC_NUMBER  
} from '../../../actionTypes';

const initialState = {
  pageData: {},
  ui: {
    updateModalVisible: false, 
    validatationModalVisible: false, 
    successModalVisible: false,
    viewOnly: true
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RMA_INFO_FOR_SCAN_MA_PAGE_SELECT_RMA:
      return {
        ...state, 
        ui: {
          ...state.ui, 
          ...action.data
        }
      }
    case SET_RMA_INFO_FOR_SCAN_MA_PAGE_TOGGLE_PART:
      return {
        ...state, 
        pageData: {
          ...state.pageData, 
          details: _.map(state.pageData.details, (c, i) => 
            c.partNumber == action.data.partNumber ? { ...c, visible: action.data.visible } : c
          )
        }
      };
    case SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY:
      return {
        ...state, 
        ui: {
          ...state.ui, 
          ...action.data
        }
      };
    case SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION: {
      const groupedByPartNumber = _
        .chain(action.data.details)
        .groupBy("partNumber")
        .flatMap((part, id) => ({
          partNumber: id, 
          quantity: _.sumBy(part, x => _.toNumber(_.get(x, "scanQuantity"))),
          required: _.sumBy(part, x => _.toNumber(_.get(x, "requiredQuantity")))
        }))
        .filter(x => x.quantity !== x.required)
        .value();
      return {
        ...state,
        ui: {
          ...state.ui, 
          validatationModalVisible: action.data.validatationModalVisible,
          partsReasons: groupedByPartNumber
        }
      }}  
    case SET_RMA_INFO_FOR_SCAN_MA_PAGE_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui, 
          ...action.data
        }
      }   
    case SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATED_ABC_NUMBER:
      return {
        ...state, 
        ui: {
          ...state.ui, 
          ..._.first(_.get(action.data, 'abcDetails'))
        }
      }     
    case SET_RMA_INFO_FOR_SCAN_MA_PAGE: {
      const groupedByPartNumber = _
        .chain(action.data.parts)
        .groupBy("partNumber")
        .map((part, id) => ({
          partNumber: id,
          requiredQuantity: _.sumBy(part, "requiredQuantity"),
          scanQuantity: _.sumBy(part, "scanQuantity"), 
          parts: _.chain(part).groupBy("asurionBarcode")
            .map((part2, id2) => ({
              asurionBarcode: id2,
              returnStatus: _.get(_.first(part2), "returnStatus"),
              requiredQuantity: _.sumBy(part2, "requiredQuantity"),
              scanQuantity: _.sumBy(part2, "scanQuantity")
            })).filter(x => x["asurionBarcode"] !== "").value()
        }))
        .value();
        return {
          ...state,
          pageData: {
            rmaInfo: _.get(action.data, "rmaInfo"),
            from: _.get(action.data, "from"), 
            to: _.get(action.data, "to"),
            details: groupedByPartNumber  
          }
        };
      }
    default:
      return state;
  }
};
