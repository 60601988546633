import { setSessionExpired, setSessionInfo, updateCurrentSession } from '@sbx/sbx-deimos-reactui-common/authentication/userSessionActions';
import { SET_SESSION_DATA, calculateTokenExpireTime, calculateTokenRefreshTime } from '@sbx/sbx-deimos-reactui-common/authentication/userSessionConst';
import { loadUserAccess } from '@sbx/sbx-deimos-reactui-common/userAccess/userAccessActions';
import { parseJwt } from '@sbx/sbx-deimos-reactui-common/genericUtility';
import { hasError, showErrorMessage } from './alertActions';
import { showLoading, hideLoading } from './appInteractionActions';
import { updateSessionId } from './../components/common/constants/userSessionConst';
import { getCommonHeaders, userInfo } from '../utilities/apiUtility';
import config from '../config.json';

export const setSessionContext = (cookies, setCookies) => async (dispatch, getState) => {
  try {
    setSessionInfo(cookies, userInfo);

    const { userDetails } = getState().userSession;

    if (window.location.pathname === '/login' || !userDetails || !userDetails.userCompanyType) {
      dispatch(showLoading());
      if (userInfo.sessionId === '') {
        updateSessionId();
      }

      const url = new URL(window.location.href);

      if (!parseJwt(userInfo.token).username.includes('|') && url.searchParams?.get('state') && cookies.ssoState) {
        if (url.searchParams?.get('state') !== cookies.ssoState) {
          throw new Error();
        }
        setCookies('ssoState', '');
      }

      const userAccessDetails = await loadUserAccess({ userInfo, configList: {}, baseUrl: config.API.BaseUrl, getHeaders: getCommonHeaders });

      if (hasError(userAccessDetails)) {
        throw new Error();
      } else {
        dispatch(setSessionExpired(false));
        dispatch({
          type: SET_SESSION_DATA,
          payload: {
            userDetails: userAccessDetails?.userProfile,
            userAccess: userAccessDetails?.userProfile.accessProfile,
            clientConfigs: userAccessDetails?.clientConfigs
          }
        });
        if (window.location.pathname === '/login') {
          dispatch(updateCurrentSession(setCookies));
        } else {
          if (calculateTokenExpireTime(userInfo.token) <= calculateTokenRefreshTime()) {
            dispatch(updateCurrentSession(setCookies));
          }
        }
      }
      dispatch(hideLoading());
    }
  } catch (error) {
    dispatch(setSessionExpired(true));
    dispatch(hideLoading());
    dispatch(showErrorMessage('Error: Could not retrieve user profile.'));
  }
};
