import * as Styled from './NonSerializedPart.styles';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { ReactComponent as EditPencil } from '../../../assets/pencil-1.svg';

const NonSerializedPart = ({ part, rmaInfo, setPartDetailAndShowModal }) => {
  
  const rmaInfoDetail = rmaInfo.rmaInfo;
  const { status, partNumber, scanQuantity } = part;
  
  return (
    <Styled.PartInfoDiv bottomBorder='1px solid #d5d6da'>
      <Styled.PartInfoLabel>
        <Text size='3'>Part Number: {partNumber}</Text>
        <Text size='3' weight='feather'>
          {' '}
          - No serial numbers
        </Text>
      </Styled.PartInfoLabel>
      <Styled.PartInfoAction>
        <Text size='2'>Quantity: {scanQuantity}</Text>
        {rmaInfoDetail.status === 'INITIATED' && (
          <EditPencil onClick={() => setPartDetailAndShowModal(rmaInfoDetail.rmaNumber, partNumber, status, scanQuantity)} />
        )}
      </Styled.PartInfoAction>
    </Styled.PartInfoDiv>
  );
};

export default NonSerializedPart;
