import styled from 'styled-components';

export const ScannedSerialItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 10px 20px 10px;
  border-bottom: 1px solid #d5d6da;
  background-color: ${props => props.color};
`;

export const ScannedSerialStatus = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between
    align-items: center;
    padding: 5px 5px 5px;
`;

export const InnerDiv = styled.div`
  padding: 2px 5px 2px;
  margin-right: 15px;
  align-items: center;
  background-color: ${props => props.color};
`;
