import styled from 'styled-components';

export const QuantityModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  gap: 16px;
  text-align: left;
  margin-top: 16px;
`;

export const QuantityInput = styled.input`
  height: 48px;
  width: 303px;
  background: #ffffff;
  border: 1.5px solid #000000;
  box-sizing: border-box;
  padding: 4px 8px;
`;
