import * as Styled from './SerialInfo.styles';
import { handleSerialDeletion } from './action';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { ReactComponent as CloseButton } from '../../../assets/close2.svg';

const SerialInfo = ({ rmaInfo, part, serialNumber, idx }) => {
  
  const dispatch = useDispatch();
  const rmaInfoDetail = rmaInfo.rmaInfo;
  const rmaInfoList = useSelector(state => state.rmaScanDynamicReducer.pageData.rmaInfo);

  const deleteSelectedSerial = (rmaNumber, serialNumber) => {
    dispatch(handleSerialDeletion(rmaNumber, serialNumber, rmaInfoList));
  };

  return (
    <Styled.ScannedSerialItem color={idx % 2 === 0 ? '#F0F0F5' : '#FFFFFF'}>
      <Text size='2'>{serialNumber}</Text>
      <Styled.ScannedSerialStatus>
        <Styled.InnerDiv color={part.returnStatus === 'Scanned' ? '#37E7A7' : '#000000'}>
          <Text size='1' color={part.returnStatus === 'Scanned' ? '#000000' : '#FFFFFF'}>
            {part.returnStatus}
          </Text>
        </Styled.InnerDiv>
        {rmaInfoDetail.status === 'INITIATED' && <CloseButton onClick={() => deleteSelectedSerial(rmaInfoDetail.rmaNumber, serialNumber)} />}
      </Styled.ScannedSerialStatus>
    </Styled.ScannedSerialItem>
  );
};

export default SerialInfo;
