import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  width: 95vw;
  justify-content: space-between;
`;

export const FilterOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px 16px 16px;
  width: 100 vw;
`;

export const SearchFilterDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 24px;
`;
