import '../RmaDashboard.scss';
import Input from '../../../common/Input';
import SearchableDropdown from '../../../common/SearchableDropdown';
import SelectDropdown from '../../../common/SelectDropdown';
import SaTable from './SaTable';
import SpTable from './SpTable';
import SBDateRangePicker from '../../../common/SBDateRangePicker';
import ShipLabelModal from '../ShipLabelModal';
import PackingListModal from '../PackingListModal';
import * as Styled from '../TransferDashboard.styles';
import { rmaStatusOutgoingMap } from '../TransfersStatuses';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchServiceProviders, getspLocInfo, searchRma } from '../action';

const OutgoingTransfers = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const { capabilities, selectedSP, fromDate, toDate, shipModalVisisble, packListModalVisible, selectedSpId, userType, spLocations, spList, selectedStatuses } =
    useSelector(state => state.rmadashboardReducer);

  useEffect(() => {
    if (selectedSP && selectedSP.providerId) {
      rmaSearch({
        providerId: selectedSP.providerId,
        fromDate,
        toDate
      });
    } else {
      rmaSearch({
        fromDate,
        toDate
      });
    }
  }, []);

  const rmaSearch = content => {
    dispatch(searchRma(content));
  };

  const spLocInfoSearch = content => {
    dispatch(getspLocInfo(content));
  };

  const spSearch = content => {
    dispatch(searchServiceProviders(content));
  };

  return (
    <div>
      {shipModalVisisble ? <ShipLabelModal /> : ''}
      {packListModalVisible ? <PackingListModal /> : ''}

      <div className='searchdiv'>
        <input
          className='rmaDashboardSearch'
          type='text'
          placeholder='Search Transfers'
          onKeyDown={event => {
            if (event.key === 'Enter' && event.target.value !== '') {
              rmaSearch({
                rmaNumber: event.target.value,
                selectedStatuses: selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses : []
              });
            } else if (event.key === 'Enter' && event.target.value === '') {
              if (selectedSP && selectedSP.providerId) {
                rmaSearch({
                  providerId: selectedSP.providerId,
                  fromDate,
                  toDate,
                  selectedStatuses: selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses : []
                });
              } else {
                rmaSearch({
                  fromDate,
                  toDate,
                  selectedStatuses: selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses : []
                });
              }
            }
          }}
        />
      </div>

      <Styled.FilterOptions>
        <Styled.SearchFilterDiv>
          <SBDateRangePicker
            label='Date range'
            fromDate={fromDate}
            toDate={toDate}
            onChange={dateString => {
              let content = {};
              if (dateString[0]) {
                content.fromDate = dateString[0];
              }
              if (dateString[1]) {
                content.toDate = dateString[1];
              }
              if (selectedSpId && selectedSpId === 'all') {
                content.providerId = selectedSP.providerId;
              } else if (selectedSpId) {
                content.providerLocationId = selectedSpId;
              }
              if (selectedStatuses && selectedStatuses.length > 0) {
                content.selectedStatuses = selectedStatuses;
              }
              rmaSearch(content);
            }}
          ></SBDateRangePicker>
        </Styled.SearchFilterDiv>

        <Styled.SearchFilterDiv>
          <SelectDropdown
            label='Status'
            options={rmaStatusOutgoingMap}
            isMultiSelect={true}
            isSearchable={true}
            placeholder='Select statuses'
            isDisabled={false}
            onChange={selectedOptions => {
              let content = {};
              if (fromDate) {
                content.fromDate = fromDate;
              }
              if (toDate) {
                content.toDate = toDate;
              }
              if (selectedSpId && selectedSpId === 'all') {
                content.providerId = selectedSP.providerId;
              } else if (selectedSpId) {
                content.providerLocationId = selectedSpId;
              }
              content.selectedStatuses = selectedOptions;
              rmaSearch(content);
            }}
          />
        </Styled.SearchFilterDiv>

        <Styled.SearchFilterDiv>
          <SelectDropdown
            label='Transfer type'
            options={capabilities}
            isMultiSelect={false}
            isSearchable={false}
            placeholder='Select type'
            isDisabled={false}
            onChange={selectedOption => {
              let content = {};
              if (fromDate) {
                content.fromDate = fromDate;
              }
              if (toDate) {
                content.toDate = toDate;
              }
              if (selectedSpId && selectedSpId === 'all') {
                content.providerId = selectedSP.providerId;
              } else if (selectedSpId) {
                content.providerLocationId = selectedSpId;
              }
              if (selectedStatuses && selectedStatuses.length > 0) {
                content.selectedStatuses = selectedStatuses;
              }
              content.transferType = selectedOption;
              rmaSearch(content);
            }}
          />
        </Styled.SearchFilterDiv>

        <Styled.SearchFilterDiv>
          <SelectDropdown
            label='Location'
            options={spLocations}
            isMultiSelect={false}
            isSearchable={true}
            placeholder='Select location'
            isDisabled={false}
            onChange={selectedOption => {
              let content = {};
              if (fromDate) {
                content.fromDate = fromDate;
              }
              if (toDate) {
                content.toDate = toDate;
              }
              if (selectedOption === 'all') {
                content.providerId = selectedSP.providerId;
              } else {
                content.providerLocationId = selectedOption;
              }
              if (selectedStatuses && selectedStatuses.length > 0) {
                content.selectedStatuses = selectedStatuses;
              }
              rmaSearch(content);
            }}
          />
        </Styled.SearchFilterDiv>

        <Styled.SearchFilterDiv>
          {userType === 'SA' ? (
            <SearchableDropdown
              label='Service provider'
              onChange={spSearch}
              onSelect={value => {
                let content = { providerId: value };
                if (fromDate) {
                  content.fromDate = fromDate;
                }
                if (toDate) {
                  content.toDate = toDate;
                }
                spLocInfoSearch(content);
              }}
              list={spList}
            ></SearchableDropdown>
          ) : (
            <Input label='Service provider' disabled value={selectedSP.providerName}></Input>
          )}
        </Styled.SearchFilterDiv>
      </Styled.FilterOptions>

      <div className='tablediv'>
        {userType === 'SA' ? (
          <SaTable currentPage={currentPage} setCurrentPage={setCurrentPage} />
        ) : (
          <SpTable currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )}
      </div>
    </div>
  );
};

export default OutgoingTransfers;
