import axios from 'axios';
import config from '../config';
import { v4 as uuidv4 } from 'uuid';

export const uriName = 'ift-inventoryfacilities';
export const eventsUriName = 'ift-eventsapi';
export const shippingGwUriName = 'osg-operationsshippinggw';
export const serviceNetwork = 'sa-spsearchapi';
export const opsAdmin = 'ops-admin';

const sessionId = uuidv4();

const apiMapping = {
  GET: axios.get,
  POST: axios.post
};

export const userInfo = {
  companyId: 0,
  token: '',
  refreshToken: '',
  userName: '',
  userKey: '',
  apiKey: '',
  sessionId: ''
};

const getBaseUri = baseUrl => endpoint => `${baseUrl}${endpoint}`;

const getUrl = name => {
  const endpoints = config.API.endpoints.filter(ep => ep.name.toLowerCase() === name.toLowerCase());
  userInfo.apiKey = endpoints[0].apikey;
  return getBaseUri(`${endpoints[0].endpoint}${endpoints[0].suburi.length > 0 ? endpoints[0].suburi : ''}`);
};

const getHeaders = (uri, customHeaders) => {
  const endpoints = config.API.endpoints.filter(ep => ep.name.toLowerCase() === uri);
  const reqInfo = JSON.parse(localStorage.userInfo);
  reqInfo.apiKey = endpoints[0].apikey;
  const commonHeaders = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'x-api-key': reqInfo.apiKey,
    Accept: 'application/json',
    Authorization: `Bearer ${userInfo.token}`,
    companyid: userInfo.companyId,
    correlationId: uuidv4(),
    sender: 'inventoryApp',
    sessionId: userInfo.sessionId,
    username: userInfo.userName
  };
  return { ...commonHeaders, ...customHeaders };
};

export const getCommonHeaders = customHeaders => {
  const commonHeaders = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'x-api-key': config.API.BaseUrl.apikey,
    Accept: 'application/json',
    Authorization: `Bearer ${userInfo.token}`,
    companyId: userInfo.companyId,
    correlationId: uuidv4(),
    sender: userInfo.userName,
    sessionId: userInfo.sessionId,
    username: userInfo.userName
  };
  return { ...commonHeaders, ...customHeaders };
};

const executeApi = (resolve, reject) => (apiName, endpoint, bodyContent, config) => {
  const { method = 'GET', body = null } = bodyContent;
  const arrayOfData = [getUrl(apiName)(endpoint), body, config].filter(item => item !== null);
  apiMapping[method]
    .apply(axios, arrayOfData)
    .then(res => manageResponse(resolve, reject, res))
    .catch(err => {
      const errorToReturn = manageError(err);
      reject(errorToReturn);
    });
};

const manageResponse = (resolve, reject, res) => (res.status === 200 ? resolve(res.data) : reject('Error occurred while getting the response'));

const manageError = payload => {
  if (payload.response && payload.response.status !== 200) {
    if (payload.response.status !== 400 && payload.response.statusText !== '') return buildErrorMessage(payload.response.status, payload.response.statusText);
    else if (payload.response.data && payload.response.data.Error) {
      const { Code, Message } = payload.response.data.Error;
      return buildErrorMessage(Code, Message);
    } else if (payload.response.data.errors) {
      const { code, Message } = payload.response.data.errors[0];
      return buildErrorMessage(code, Message);
    }
  } else return buildErrorMessage('Server Issue', 'Server Issue : Please contact system administrator');
};

const buildErrorMessage = (code, message) => ({
  Error: {
    Code: `${code}`,
    Message: message
  }
});

export const Get = (apiName, path, headers) => {
  return new Promise((resolve, reject) => {
    const custom_headers = {
      headers: headers ? headers : getCommonHeaders(),
      data: {} // Needed for passing Content-Type in headers
    };
    executeApi(resolve, reject)(apiName, path, {}, custom_headers);
  });
};

export const Post = (apiName, path, data) => {
  return new Promise((resolve, reject) => {
    const content = {
      body: { ...data },
      method: 'POST'
    };
    const custom_headers = {
      headers: getCommonHeaders()
    };
    executeApi(resolve, reject)(apiName, path, content, custom_headers);
  });
};

export const isServiceProvider = userDetails => {
  return userDetails && +userDetails?.owningCompany?.companyType === 2;
};

export const isServiceAdministrator = userDetails => {
  return userDetails && +userDetails?.owningCompany?.companyType === 1;
};
