import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MessageModal from '../../common/MessageModal';
import { ReactComponent as LeftArrowLogo } from '../../../assets/arrow1-left.svg';
import { ReactComponent as CloseButton } from '../../../assets/close2.svg';
import { ReactComponent as Expand } from '../../../assets/chevron-down.svg';
import { ReactComponent as Collapse } from '../../../assets/chevron-up.svg';
import { ReactComponent as EditPencil } from '../../../assets/pencil-1.svg';
import BarcodeReader from 'react-barcode-reader';
import { updateQuantity, goToRmaDashboard } from '../scanningFlow/action';
import { enableSpinner, disableSpinner } from '../../action';
import {
  togglechild,
  checkSerial,
  updateReceivePage,
  saveTransfer,
  closeTransfer,
  getSnapShot,
} from './action';
import '../scanningFlow/ScanSerial.scss';
import './Receive.scss';

class Receive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      message: '',
      borderColor: '#377a80',
      afterSaveModalVisible: false,
      descrepanciesModalVisible: false,
      closeSuccessModalVisible: false,
      quantityModalVisible: false,
      currentPartNumber: '',
      pendingReceivingQuantity: 0,
      receivedQuantity: 0,
      serial: '',
      dirtyData: false,
    };
    this.onScan = this.onScan.bind(this);
    this.modalOnOff = this.modalOnOff.bind(this);
    this.updatePartQuantity = this.updatePartQuantity.bind(this);
    this.setQuantity = this.setQuantity.bind(this);
    this.updateReason = this.updateReason.bind(this);
  }
  async onScan(serial) {
    let addresponse = await this.props.checkSerial(
      this.props.pageData.rmaInfo.rmaNumber,
      serial
    );
    this.setState({ serial: serial });
    if (addresponse && addresponse.status === 'failure') {
      this.setState({
        showError: true,
        borderColor: '#db1b1b',
        message: addresponse.message,
      });
    } else {
      this.props.updateReceivePage(
        this.props.pageData,
        addresponse.partNumber,
        serial,
        true
      );
      this.setState({ dirtyData: true });
    }
    document.getElementById('scanInputBox').value = '';
    this.setState({ serial: '' });
  }

  modalOnOff(bool, partNumber, pendingReceivingQuantity) {
    if (bool) {
      this.setState(
        { currentPartNumber: partNumber, pendingReceivingQuantity },
        () => this.setState({ quantityModalVisible: bool })
      );
    } else {
      this.setState({ quantityModalVisible: bool });
    }
  }

  async updatePartQuantity() {
    await this.props.updateReceivePage(
      this.props.pageData,
      this.state.currentPartNumber,
      this.state.receivedQuantity,
      false
    );
    this.modalOnOff(false);
    this.setState({ dirtyData: true });
  }
  setQuantity(quantity) {
    this.setState({ receivedQuantity: quantity });
  }

  updateReason(event, partNumber) {
    let reasons = this.state.partsReasons;
    reasons = reasons.map((r) => {
      if (r.partNumber === partNumber) {
        r.reason = event.target.value;
      }
      return r;
    });
    this.setState({ partsReasons: [...reasons] });
  }

  render() {
    return (
      <div>
        <div className="ScanNavLabel">
          {this.state.quantityModalVisible ? (
            <MessageModal
              id="quantityModal"
              header="Enter quantity"
              content={
                <div>
                  <input
                    type="number"
                    min="0"
                    className="Modal-input"
                    onChange={async (event) =>
                      this.setQuantity(parseInt(event.target.value))
                    }
                  />
                  <p style={{ textAlign: 'left' }}>
                    Requested:{this.state.pendingReceivingQuantity}
                  </p>
                </div>
              }
              cancel={() => this.modalOnOff(false)}
              continue={() => {
                this.updatePartQuantity();
              }}
            />
          ) : (
            ''
          )}
          {this.state.afterSaveModalVisible ? (
            <MessageModal
              id="successModalContent"
              header="Transfer saved!"
              bodyStyle="successModalBody"
              content={
                <div>
                  <p style={{ textAlign: 'left' }}>
                    You can resume this transfer at any time from the transfer
                    dashboard. Once all items from this purchase order are
                    received, you can close out this transfer.
                  </p>
                </div>
              }
              noCancel="true"
              continueName="Return to transfer dashboard"
              continue={() => {
                this.setState({ afterSaveModalVisible: false });
                this.props.goToRmaDashboard();
              }}
            />
          ) : (
            ''
          )}
          {this.state.descrepanciesModalVisible ? (
            <MessageModal
              header="Transfer discrepancy"
              id="validationModal"
              contentStyle="validationModalContent"
              bodyStyle="validationModalBody"
              cancelName="No. go back"
              continueName="Close out transfer"
              content={
                <div>
                  <p>
                    The following items are listed on the purchase order, but
                    are missing or found to be extra in the transfer. Are you
                    sure you want to close out this transfer?
                  </p>
                  <table className="unfulfilledReasonsTable">
                    <tr>
                      <th>SKU</th>
                      <th>Serial/Quantity</th>
                    </tr>
                    {this.props.descrepancies.map((row) => (
                      <tr>
                        <td>{row.partNumber}</td>
                        <td>
                          {row.quantity}
                          {row.serialNumber}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              }
              cancel={() => this.setState({ descrepanciesModalVisible: false })}
              continue={async () => {
                this.props.enableSpinner();
                let res = await this.props.closeTransfer(
                  this.props.pageData,
                  true
                );
                if (res) {
                  this.setState({ descrepanciesModalVisible: false });
                  this.setState({ closeSuccessModalVisible: true });
                }
                this.props.disableSpinner();
              }}
            />
          ) : (
            ''
          )}
          {this.state.closeSuccessModalVisible ? (
            <MessageModal
              id="successModalContent"
              header="Transfer complete!"
              bodyStyle="successModalBody"
              content={
                <div>
                  <p>
                    This transfer is complete — there’s nothing more you need to
                    do.
                  </p>
                </div>
              }
              noCancel="true"
              continueName="Return to transfer dashboard"
              continue={() => {
                this.setState({ closeSuccessModalVisible: false });
                this.props.goToRmaDashboard();
              }}
            />
          ) : (
            ''
          )}
          <LeftArrowLogo className="scanLeftArraow" />
          <a
            className="ScanNavLabel"
            href="#"
            onClick={this.props.goToRmaDashboard}
          >
            Return to Transfer Dashboard
          </a>
        </div>
        <div className="rmaNumberLabel">
          Transfer #{this.props.pageData.rmaInfo.rmaNumber}
        </div>
        <div className="ScanContent">
          <div className="scanParentDiv">
            <p className="title"> Scan or enter parts</p>
            <p className="title2"> Part or serial number</p>
            <input
              id="scanInputBox"
              style={{ borderColor: this.state.borderColor }}
              value={this.state.serial}
              disabled={
                this.props.pageData.rmaInfo.status === 'PND_RECEIVING'
                  ? false
                  : true
              }
              className="scanbox"
              onChange={(event) => {
                this.setState({
                  showError: false,
                  borderColor: '#377a80',
                  serial: event.target.value,
                });
              }}
              onKeyDown={(event) => {
                if (event.keyCode === 13 && event.target.value !== '') {
                  this.onScan(event.target.value);
                }
              }}
            ></input>
            <div>
              <BarcodeReader onScan={this.onScan} />
            </div>
            {this.state.showError ? (
              <div className="scanMessage">
                <p>{this.state.message}</p>
              </div>
            ) : (
              ''
            )}

            <div className="fromToInfoDiv">
              <p className="labeltext">Shipping Origin</p>
              <p className="locationNameText">
                {this.props.pageData.from.locationName}
              </p>

              <p className="labeltext">Shipping Destination</p>
              <p className="locationNameText">
                {this.props.pageData.to.locationName}
              </p>
            </div>
            <button
              className={
                this.props.pageData.rmaInfo.status === 'PND_RECEIVING'
                  ? 'closeOutEnabled'
                  : 'closeOutDisabled'
              }
              disabled={
                this.props.pageData.rmaInfo.status === 'PND_RECEIVING'
                  ? false
                  : true
              }
              onClick={async () => {
                this.props.enableSpinner();
                if (this.state.dirtyData) {
                  let res = await this.props.saveTransfer(this.props.pageData);
                  if (res) {
                    this.props.getSnapShot(res);
                    this.setState({ dirtyData: false });
                  }
                }
                let res = await this.props.closeTransfer(this.props.pageData);
                if (res) {
                  this.props.goToRmaDashboard();
                } else {
                  this.setState({ descrepanciesModalVisible: true });
                }
                this.props.disableSpinner();
              }}
            >
              <p className="buttonText">Close out transfer</p>
            </button>
            <button
              className={
                this.props.pageData.rmaInfo.status === 'PND_RECEIVING' &&
                this.state.dirtyData === true > 0
                  ? 'receivebuttonEnabled'
                  : 'receivebuttonDisabled'
              }
              disabled={
                this.props.pageData.rmaInfo.status === 'PND_RECEIVING' &&
                this.state.dirtyData === true > 0
                  ? false
                  : true
              }
              onClick={async () => {
                this.props.enableSpinner();
                let res = await this.props.saveTransfer(this.props.pageData);
                if (res) {
                  this.props.getSnapShot(res);
                  this.setState({ dirtyData: false });
                  this.setState({ afterSaveModalVisible: true });
                }
                this.props.disableSpinner();
              }}
            >
              <p className="buttonText">Save transfer</p>
            </button>
          </div>
          <div className="partsDiv">
            <p className="title">Received Parts</p>
            <div className="partsContainer">
              {Object.keys(this.props.pageData.parts).map((partNumber) => {
                let backgroundcolor =
                  this.props.pageData.parts[`${partNumber}`]
                    .pendingReceivingQuantity <
                  this.props.pageData.parts[`${partNumber}`].receivedQuantity
                    ? '#F9D51C'
                    : this.props.pageData.parts[`${partNumber}`]
                        .pendingReceivingQuantity >
                      this.props.pageData.parts[`${partNumber}`]
                        .receivedQuantity
                    ? '#FFFFFF'
                    : '#F0FFF9';
                return (
                  <div className="partContainer">
                    <div
                      className="partInfo"
                      style={{ backgroundColor: backgroundcolor }}
                    >
                      {partNumber}
                      {this.props.pageData.parts[`${partNumber}`]
                        .isSerialized ? (
                        <div style={{ float: 'right' }}>
                          {!this.props.pageData.parts[`${partNumber}`]
                            .childVisible ? (
                            <Expand
                              onClick={() =>
                                this.props.togglechild(
                                  partNumber,
                                  this.props.pageData,
                                  true
                                )
                              }
                            />
                          ) : (
                            <Collapse
                              onClick={() =>
                                this.props.togglechild(
                                  partNumber,
                                  this.props.pageData,
                                  false
                                )
                              }
                            />
                          )}
                        </div>
                      ) : (
                        <div style={{ float: 'right' }}>
                          {this.props.pageData.rmaInfo.status ===
                          'PND_RECEIVING' ? (
                            <EditPencil
                              onClick={() =>
                                this.modalOnOff(
                                  true,
                                  partNumber,
                                  this.props.pageData.parts[`${partNumber}`]
                                    .pendingReceivingQuantity
                                )
                              }
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                      <div style={{ float: 'right', marginRight: '5px' }}>
                        {
                          this.props.pageData.parts[`${partNumber}`]
                            .receivedQuantity
                        }
                        /
                        {
                          this.props.pageData.parts[`${partNumber}`]
                            .pendingReceivingQuantity
                        }
                      </div>
                    </div>
                    {this.props.pageData.parts[`${partNumber}`].childVisible ? (
                      <div id={partNumber} className="rmascanchildtablediv">
                        <table className="rmascanchildtable">
                          {this.props.pageData.parts[`${partNumber}`].parts.map(
                            (part) => {
                              let statusbackgroundcolor =
                                part.returnStatus === 'Received'
                                  ? '#37E7A7'
                                  : '#000000';
                              let statuscolor =
                                part.returnStatus === 'Received'
                                  ? '#000000'
                                  : '#ffffff';
                              return (
                                <tr className="serialRow">
                                  <td>
                                    <div className="serial">
                                      {part.partSerialNumber}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="status">
                                      <p
                                        style={{
                                          background: statusbackgroundcolor,
                                          color: statuscolor,
                                          display: 'inline-block',
                                        }}
                                      >
                                        {part.returnStatus}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </table>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ receiveReducer }) => {
  const { pageData, descrepancies } = receiveReducer;
  return { pageData, descrepancies };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToRmaDashboard,
      togglechild,
      checkSerial,
      updateReceivePage,
      saveTransfer,
      closeTransfer,
      getSnapShot,
      enableSpinner,
      disableSpinner,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Receive);
