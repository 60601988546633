import {
  SET_FSL_TRANSFER_INFO_FOR_SCAN_PAGE,
  SET_FSL_TRANSFER_DESCREPANCIES,
} from '../../../actionTypes';
const initialState = {
  pageData: {},
  descrepancies: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FSL_TRANSFER_INFO_FOR_SCAN_PAGE:
      return {
        ...state,
        pageData: action.data,
      };
    case SET_FSL_TRANSFER_DESCREPANCIES:
      return {
        ...state,
        descrepancies: action.data,
      };
    default:
      return state;
  }
};
