import mockData from './examples.json';
import {
  SET_RMA_DASHBOARD_FROM_DATE,
  SET_RMA_DASHBOARD_TO_DATE,
  SET_RMA_DASHBOARD_SELECTED_SP_ID,
  SET_DATA_FOR_RMA_DASHBOARD_TABLE,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATED_ABC_NUMBER,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE,
  SET_RETURNS_NAVIGATION,
  SET_USER_TYPE,
  SET_SP_LOCATION_LIST_FOR_DASHBOARD,
  SET_SP_FOR_DASHBOARD,
  SET_APPLICATION_CAPABILITIES,
  SET_RMA_DASHBOARD_SHIP_PACK_MODAL_DATA,
  SET_RMA_DASHBOARD_PACK_MODAL,
  SET_RMA_DASHBOARD_SHIP_MODAL,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_SUCCESS,
  SET_APPLICATION_ERROR_MESSAGES,
  SET_APPLICATION_SUCCESS_MESSAGES,
  ADD_APPLICATION_SUCCESS_MESSAGE,
  SET_RMA_DASHBOARD_CODE_DESCRIPTION_LIST,
  SET_APPLICATION_STATUS_READY
} from '../../actionTypes';
import _ from 'lodash';

export const readSessionTokenMock = () => {
  const response = _.clone(mockData['User-Info']);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  localStorage.setItem(
    'userInfo',
    JSON.stringify({
      ...userInfo,
      ...response
    })
  );
  return '';
};

export const updateCurrentSessionMock = callback => {};

export const searchRmaMock = content => async dispatch => {
  try {
    const response = _.clone(mockData['RMA-Search']);
    if (content.fromDate) {
      dispatch({ type: SET_RMA_DASHBOARD_FROM_DATE, data: content.fromDate });
    }
    if (content.toDate) {
      dispatch({ type: SET_RMA_DASHBOARD_TO_DATE, data: content.toDate });
    }
    if (content.providerLocationId) {
      dispatch({
        type: SET_RMA_DASHBOARD_SELECTED_SP_ID,

        data: content.providerLocationId
      });
    } else {
      dispatch({ type: SET_RMA_DASHBOARD_SELECTED_SP_ID, data: 'all' });
    }

    if (response.rmaData) {
      dispatch({
        type: SET_DATA_FOR_RMA_DASHBOARD_TABLE,
        data: response.rmaData
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const goToScanningMaFlowMock = (rmaNumber, status) => async dispatch => {
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'scanMaSerials' });
};

export const readRmaInfoMock = params => async dispatch => {
  const response = _.clone(mockData['RMA-Info']);
  dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE, data: response });
};

export const openPackListlModalMock = rmaNumber => async dispatch => {
  const response = _.clone(mockData['RMA-Info']);
  dispatch({ type: SET_RMA_DASHBOARD_SHIP_PACK_MODAL_DATA, data: response });
  dispatch({ type: SET_RMA_DASHBOARD_PACK_MODAL, data: true });
};

export const getInitialInfoMock = () => async dispatch => {
  try {
    let toDate = new Date();
    let fromDate = new Date(toDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    fromDate = fromDate.getMonth() + 1 + '/' + fromDate.getDate() + '/' + fromDate.getFullYear();
    toDate = toDate.getMonth() + 1 + '/' + toDate.getDate() + '/' + toDate.getFullYear();
    const response = _.clone(mockData['Initial-Info']);
    if (response.userInfo.companyType) {
      dispatch({ type: SET_USER_TYPE, data: response.userInfo.companyType });
    }
    if (response.transfers) {
      dispatch({
        type: SET_DATA_FOR_RMA_DASHBOARD_TABLE,
        data: response.transfers
      });
    }
    if (response.locations) {
      response.locations = response.locations.map(item => {
        return {
          ...item,
          label: item.description,
          value: item.serviceProviderLocationId
        };
      });
      if (response.locations.length > 1) {
        response.locations.unshift({ label: 'ALL', value: 'all' });
      }
      dispatch({
        type: SET_SP_LOCATION_LIST_FOR_DASHBOARD,
        data: response.locations
      });
    }
    if (response.userCompany) {
      dispatch({ type: SET_SP_FOR_DASHBOARD, data: response.userCompany });
    }
    if (response.fromDate) {
      dispatch({ type: SET_RMA_DASHBOARD_FROM_DATE, data: response.fromDate });
    }
    if (response.toDate) {
      dispatch({ type: SET_RMA_DASHBOARD_TO_DATE, data: response.toDate });
    }
    if (response.capabilities) {
      let capabilities = response.capabilities.map(item => {
        if (item === 'returns') {
          return { label: 'Returns', value: 'returns' };
        } else if (item === 'fsl') {
          return { label: 'FSL', value: 'fsl' };
        } else if (item === 'truck') {
          return { label: 'Truck', value: 'truck' };
        }
      });
      dispatch({ type: SET_APPLICATION_CAPABILITIES, data: capabilities });
    }
    if (response.codeDescriptionList) {
      dispatch({ type: SET_RMA_DASHBOARD_CODE_DESCRIPTION_LIST, data: response.codeDescriptionList });
    }
    dispatch({ type: SET_APPLICATION_STATUS_READY, data: {} });
  } catch (error) {}
};

export const openShipLabelModalMock = (rmaNumber, spInfo, fromDate, toDate) => async dispatch => {
  let response = _.clone(mockData['RMA-Info']);
  dispatch({ type: SET_RMA_DASHBOARD_SHIP_PACK_MODAL_DATA, data: response });
  dispatch({ type: SET_RMA_DASHBOARD_SHIP_MODAL, data: true });
  if (spInfo && spInfo.providerId && fromDate && toDate) {
    searchRmaMock({
      providerId: `${spInfo.providerId}`,
      fromDate,
      toDate
    })(dispatch);
  }
};

export const validateAsurionBarcodeMock = params => async dispatch => {
  const response = mockData['RMA-Validate-ABC-Number'];
  const found = _.find(_.get(response, 'abcDetails', []), x => x.asurionBarcode === _.get(params, 'asurionBarcode'));
  if (found) {
    dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATED_ABC_NUMBER, data: response });
    return response;
  }
  return { status: 'FAILURE' };
};

export const addQuantityCloseMock = params => async dispatch => {
  if (_.has(params, 'cancel')) {
    dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY, data: { updateModalVisible: false } });
  } else {
    const part = _.find(mockData['RMA-Info']['parts'], { asurionBarcode: params.asurionBarcode });
    if (part) {
      part['scanQuantity'] = params.quantity;
      part['returnStatus'] = 'Scanned';
    }
    dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY, data: { updateModalVisible: false, quantity: _.get(params, 'quantity', 0) } });
    dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE, data: _.clone(mockData['RMA-Info']) });
  }
};

export const deleteSerialMock = params => async dispatch => {
  const part = _.find(mockData['RMA-Info']['parts'], { asurionBarcode: params.asurionBarcode });
  part['scanQuantity'] = 0;
  part['returnStatus'] = 'Requested';
  dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE, data: _.clone(mockData['RMA-Info']) });
  dispatch({ type: ADD_APPLICATION_SUCCESS_MESSAGE, data: `Deleted successfully ${_.get(params, 'asurionBarcode')}` });
};

export const generatePackingListCloseMock = params => async dispatch => {
  if (_.has(params, 'cancel')) {
    dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION, data: { validatationModalVisible: false } });
  } else {
    dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION, data: { validatationModalVisible: false } });
    dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_SUCCESS, data: { successModalVisible: true } });
  }
};

export const successModalCloseMock = params => async dispatch => {
  const rma = mockData['RMA-Info'];
  rma['rmaInfo']['status'] = 'COMPLETED';

  dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_SUCCESS, data: { successModalVisible: false } });
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_APPLICATION_SUCCESS_MESSAGES, data: [] });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaDashBoard' });
};
