//App navigation
export const SET_APPLICATION_NAVIGATION = 'SET_APPLICATION_NAVIGATION';
export const SET_APPLICATION_SUCCESS_MESSAGES = 'SET_APPLICATION_SUCCESS_MESSAGES';
export const ADD_APPLICATION_SUCCESS_MESSAGE = 'ADD_APPLICATION_SUCCESS_MESSAGE';
export const DELETE_APPLICATION_SUCCESS_MESSAGE = 'DELETE_APPLICATION_SUCCESS_MESSAGE';
export const SET_APPLICATION_ERROR_MESSAGES = 'SET_APPLICATION_ERROR_MESSAGES';
export const ADD_APPLICATION_ERROR_MESSAGE = 'ADD_APPLICATION_ERROR_MESSAGE';
export const DELETE_APPLICATION_ERROR_MESSAGE = 'DELETE_APPLICATION_ERROR_MESSAGE';
export const ENABLE_APPLICATION_SPINNER = 'ENABLE_APPLICATION_SPINNER';
export const DISABLE_APPLICATION_SPINNER = 'DISABLE_APPLICATION_SPINNER';
export const SET_APPLICATION_STATUS_INTIALIZE = 'SET_APPLICATION_STATUS_INTIALIZE';
export const SET_APPLICATION_STATUS_READY = 'SET_APPLICATION_STATUS_READY';

//Returns
export const SET_RETURNS_NAVIGATION = 'SET_RETURNS_NAVIGATION';
//RMA DASHBOARD Actions
export const SET_DATA_FOR_RMA_DASHBOARD_TABLE = 'SET_DATA_FOR_RMA_DASHBOARD_TABLE';
export const SET_SP_LOCATION_LIST_FOR_DASHBOARD = 'SET_SP_LOCATION_LIST_FOR_DASHBOARD';
export const SET_SP_FOR_DASHBOARD = 'SET_SP_FOR_DASHBOARD';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_SP_LIST_FOR_RMA_DASHBOARD = 'SET_SP_LIST_FOR_RMA_DASHBOARD';
export const SET_RMA_DASHBOARD_FROM_DATE = 'SET_RMA_DASHBOARD_FROM_DATE';
export const SET_RMA_DASHBOARD_TO_DATE = 'SET_RMA_DASHBOARD_TO_DATE';
export const SET_RMA_DASHBOARD_SELECTED_SP_ID = 'SET_RMA_DASHBOARD_SELECTED_SP_ID';
export const SET_RMA_DASHBOARD_SHIP_MODAL = 'SET_RMA_DASHBOARD_SHIP_MODAL';
export const SET_RMA_DASHBOARD_PACK_MODAL = 'SET_RMA_DASHBOARD_PACK_MODAL';
export const SET_RMA_DASHBOARD_SHIP_PACK_MODAL_DATA = 'SET_RMA_DASHBOARD_SHIP_PACK_MODAL_DATA';
export const SET_RMA_DASHBOARD_CODE_DESCRIPTION_LIST = 'SET_RMA_DASHBOARD_CODE_DESCRIPTION_LIST';
export const SET_RMA_DASHBOARD_SELECTED_STATUSES = 'SET_RMA_DASHBOARD_SELECTED_STATUSES';

export const SET_TRANSFER_DASHBOARD_CURRENT_SELECTION = 'SET_TRANSFER_DASHBOARD_CURRENT_SELECTION';
export const SET_APPLICATION_CAPABILITIES = 'SET_APPLICATION_CAPABILITIES';
export const SET_ASN_DATA = 'SET_ASN_DATA';

export const SET_SELECTED_TRANSFER_TYPE = 'SET_SELECTED_TRANSFER_TYPE';
export const SET_SELECTED_ORIGIN = 'SET_SELECTED_ORIGIN';
export const SET_SELECTED_DESTINATION = 'SET_SELECTED_DESTINATION';

//Adhoc returns
export const SET_ADHOC_RMA_SOURCE_LOCATIONS = 'SET_ADHOC_RMA_SOURCE_LOCATIONS';
export const SET_ADHOC_RMA_DESTINATION_LOCATIONS = 'SET_ADHOC_RMA_DESTINATION_LOCATIONS';
export const SET_ADHOC_RMA_SOURCE = 'SET_ADHOC_RMA_SOURCE';
export const SET_ADHOC_RMA_DESTINATION = 'SET_ADHOC_RMA_DESTINATION';

//scan/RMAFLOW returns
export const SET_RMA_INFO_FOR_SCAN_PAGE = 'SET_RMA_INFO_FOR_SCAN_PAGE';
export const SET_RMA_INFO_FOR_DYNAMIC_SCAN_PAGE = 'SET_RMA_INFO_FOR_DYNAMIC_SCAN_PAGE';
export const SET_RMA_INFO_FOR_SCAN_MA_PAGE = 'SET_RMA_INFO_FOR_SCAN_MA_PAGE';
export const SET_RMA_INFO_FOR_SCAN_MA_PAGE_SELECT_RMA = 'SET_RMA_INFO_FOR_SCAN_MA_PAGE_SELECT_RMA';
export const SET_RMA_INFO_FOR_SCAN_MA_PAGE_TOGGLE_PART = 'SET_RMA_INFO_FOR_SCAN_MA_PAGE_TOGGLE_PART';
export const SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY = 'SET_RMA_INFO_FOR_SCAN_MA_PAGE_UPDATE_QUANTITY';
export const SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATED_ABC_NUMBER = 'SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATED_ABC_NUMBER';
export const SET_RMA_INFO_FOR_SCAN_MA_PAGE_SUCCESS = 'SET_RMA_INFO_FOR_SCAN_MA_PAGE_SUCCESS';
export const SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION = 'SET_RMA_INFO_FOR_SCAN_MA_PAGE_VALIDATION';
export const SET_RMA_INFO_FOR_SCAN_MA_PAGE_DELETE_SCANNED = 'SET_RMA_INFO_FOR_SCAN_MA_PAGE_DELETE_SCANNED';
export const SET_RMA_INFO_SUMMARY_PAGE = 'SET_RMA_INFO_SUMMARY_PAGE';
export const SET_RMA_INFO_CREATION_PAGE = 'SET_RMA_INFO_CREATION_PAGE';
export const SET_INFO_FOR_DYNAMIC_SCAN_PAGE = 'SET_INFO_FOR_DYNAMIC_SCAN_PAGE';
export const SET_UPLOAD_FILE_NAME = 'SET_UPLOAD_FILE_NAME';
export const SET_RMA_SUMMARY = 'SET_RMA_SUMMARY';
export const SET_DAX_365 = 'SET_DAX_365';
export const SET_DESTINATION_ADDRESS = 'SET_DESTINATION_ADDRESS';
export const SET_UPLOAD_SUMMARY_DATA = 'SET_UPLOAD_SUMMARY_DATA';
export const SET_RMA_MANUAL_IMPORT_SP_INFO = 'SET_RMA_MANUAL_IMPORT_SP_INFO';
export const SET_SP_LOCATION_LIST_MANUAL_IMPORT = 'SET_SP_LOCATION_LIST_MANUAL_IMPORT';
export const SET_RMA_SCAN_SUCCESSFUL = 'SET_RMA_SCAN_SUCCESSFUL';

//scan //fsl-receive
export const SET_FSL_TRANSFER_INFO_FOR_SCAN_PAGE = 'SET_FSL_TRANSFER_INFO_FOR_SCAN_PAGE';
export const SET_FSL_TRANSFER_DESCREPANCIES = 'SET_FSL_TRANSFER_DESCREPANCIES';

//order page
export const SET_ORDER_INFO_FOR_SCAN_PAGE = 'SET_ORDER_INFO_FOR_SCAN_PAGE';
export const SET_ORDER_CHANGES_SCAN_PAGE = 'SET_ORDER_CHANGES_SCAN_PAGE';

//Truck Flow
export const SET_TRUCK_PAGE_INITIAL_INFO = 'SET_TRUCK_PAGE_INITIAL_INFO';
export const SET_TRUCK_PAGE_PARTS = 'SET_TRUCK_PAGE_PARTS';
export const SET_TRUCK_PAGE_STRUCTURED_PARTS_DATA = 'SET_TRUCK_PAGE_STRUCTURED_PARTS_DATA';
