import { userInfo } from '../../../utilities/apiUtility';
import { v4 as uuid } from 'uuid';

export const updateSessionId = () => (userInfo.sessionId = uuid());

export const defaultUserInfo = {
  companyId: 0,
  token: '',
  refreshToken: '',
  userName: '',
  userKey: '',
  apiKey: '',
  sessionId: ''
};
