export const HIDE_LOADING = 'HIDE_LOADING';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_SUBMIT_LOADING = 'HIDE_SUBMIT_LOADING';
export const SHOW_SUBMIT_LOADING = 'SHOW_SUBMIT_LOADING';

export const hideLoading = () => dispatch => dispatch({ type: HIDE_LOADING });

export const showLoading = message => dispatch => dispatch({ type: SHOW_LOADING, message });

export const hideSubmitLoading = () => dispatch => dispatch({ type: HIDE_SUBMIT_LOADING });

export const showSubmitLoading = () => dispatch => dispatch({ type: SHOW_SUBMIT_LOADING });
