import {
  SET_ADHOC_RMA_SOURCE_LOCATIONS,
  SET_ADHOC_RMA_DESTINATION_LOCATIONS,
  SET_ADHOC_RMA_SOURCE,
  SET_ADHOC_RMA_DESTINATION,
  SET_SELECTED_TRANSFER_TYPE,
  SET_SELECTED_ORIGIN,
  SET_SELECTED_DESTINATION
} from '../../../actionTypes';
const initialState = {
  sourceLocations: [],
  destinationLocations: [],
  source: {},
  destination: {},
  selectedTransferType: null,
  selectedOrigin: null,
  selectedDestination: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ADHOC_RMA_SOURCE_LOCATIONS:
      return {
        ...state,
        sourceLocations: action.data
      };
    case SET_ADHOC_RMA_DESTINATION_LOCATIONS:
      return {
        ...state,
        destinationLocations: action.data
      };
    case SET_ADHOC_RMA_SOURCE:
      return {
        ...state,
        source: action.data
      };
    case SET_ADHOC_RMA_DESTINATION:
      return {
        ...state,
        destination: action.data
      };
    case SET_SELECTED_TRANSFER_TYPE:
      return {
        ...state,
        selectedTransferType: action.data
      };
    case SET_SELECTED_ORIGIN:
      return {
        ...state,
        selectedOrigin: action.data
      };
    case SET_SELECTED_DESTINATION:
      return {
        ...state,
        selectedDestination: action.data
      };
    default:
      return state;
  }
};
