import '../RmaDashboard.scss';
import Input from '../../../common/Input';
import SearchableDropdown from '../../../common/SearchableDropdown';
import SelectDropdown from '../../../common/SelectDropdown';
import SaTable from './SaTable';
import SpTable from './SpTable';
import SBDateRangePicker from '../../../common/SBDateRangePicker';
import ShipLabelModal from '../ShipLabelModal';
import PackingListModal from '../PackingListModal';
import ASNDetailsModel from './ASNDetailsModel';
import BarcodeReader from 'react-barcode-reader';
import * as Styled from '../TransferDashboard.styles';
import { rmaStatusIncomingMap } from '../TransfersStatuses';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchServiceProviders, getspLocInfo, searchTransfers } from '../action';

const IncomingTransfers = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchBoxType, setSearchBoxType] = useState('transferNumber');
  const {
    capabilities,
    selectedSP,
    fromDate,
    toDate,
    shipModalVisisble,
    packListModalVisible,
    selectedSpId,
    userType,
    spLocations,
    spList,
    selectedStatuses,
    currentTransfersSelection,
    ASNData
  } = useSelector(state => state.rmadashboardReducer);

  useEffect(() => {
    if (userType) {
      if (selectedSP && selectedSP.providerId) {
        transferSearch({
          type: currentTransfersSelection,
          providerId: selectedSP.providerId,
          fromDate,
          toDate
        });
      } else {
        transferSearch({
          type: currentTransfersSelection,
          fromDate,
          toDate
        });
      }
    }
  }, []);

  const transferSearch = content => {
    dispatch(searchTransfers(content));
  };

  const spLocInfoSearch = content => {
    dispatch(getspLocInfo(content));
  };

  const spSearch = content => {
    dispatch(searchServiceProviders(content));
  };

  return (
    <div>
      {shipModalVisisble ? <ShipLabelModal /> : ''}
      {packListModalVisible ? <PackingListModal /> : ''}
      {ASNData.purchaseOrderNumber ? <ASNDetailsModel /> : ''}

      <div className='searchdiv'>
        <div className='leftSearchdiv'>
          <select
            id='incomingSelectionType'
            className='transferDashboardIncomingDropdown'
            value={searchBoxType}
            onChange={event => {
              setSearchBoxType(event.target.value);
            }}
          >
            <option key='transfer-number' value='transferNumber'>
              Transfer number
            </option>
            <option key='tracking-number' value='trackingNumber'>
              Tracking number
            </option>
          </select>
        </div>
        <div className='rightSearchdiv'>
          <input
            className='transferDashboardIncomingSearch'
            id='transferDashboardIncomingSearchid'
            type='text'
            placeholder='Scan or enter transfer number or tracking number'
            onKeyDown={event => {
              if (event.key === 'Enter' && event.target.value !== '') {
                if (searchBoxType === 'transferNumber') {
                  transferSearch({
                    type: currentTransfersSelection,
                    transferNumber: event.target.value,
                    selectedStatuses: selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses : []
                  });
                } else if (searchBoxType === 'trackingNumber') {
                  transferSearch({
                    type: currentTransfersSelection,
                    trackingNumber: event.target.value,
                    selectedStatuses: selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses : []
                  });
                }
              } else if (event.key === 'Enter' && event.target.value === '') {
                if (selectedSP && selectedSP.providerId) {
                  transferSearch({
                    type: currentTransfersSelection,
                    providerId: selectedSP.providerId,
                    fromDate,
                    toDate,
                    selectedStatuses: selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses : []
                  });
                } else {
                  transferSearch({
                    type: currentTransfersSelection,
                    fromDate,
                    toDate,
                    selectedStatuses: selectedStatuses && selectedStatuses.length > 0 ? selectedStatuses : []
                  });
                }
              }
            }}
          ></input>
          <BarcodeReader
            onScan={data => {
              document.getElementById('transferDashboardIncomingSearchid').value = data;
              if (searchBoxType === 'transferNumber') {
                transferSearch({
                  type: currentTransfersSelection,
                  transferNumber: data
                });
              } else if (searchBoxType === 'trackingNumber') {
                transferSearch({
                  type: currentTransfersSelection,
                  trackingNumber: data
                });
              }
            }}
          />
        </div>
      </div>

      <Styled.FilterOptions>
        <Styled.SearchFilterDiv>
          <SBDateRangePicker
            label='Date range'
            fromDate={fromDate}
            toDate={toDate}
            onChange={dateString => {
              let content = { type: currentTransfersSelection };
              if (dateString[0]) {
                content.fromDate = dateString[0];
              }
              if (dateString[1]) {
                content.toDate = dateString[1];
              }
              if (selectedSpId && selectedSpId === 'all') {
                content.providerId = selectedSP.providerId;
              } else if (selectedSpId) {
                content.providerLocationId = selectedSpId;
              }
              if (selectedStatuses && selectedStatuses.length > 0) {
                content.selectedStatuses = selectedStatuses;
              }
              transferSearch(content);
            }}
          ></SBDateRangePicker>
        </Styled.SearchFilterDiv>

        <Styled.SearchFilterDiv>
          <SelectDropdown
            label='Status'
            options={rmaStatusIncomingMap}
            isMultiSelect={true}
            isSearchable={true}
            placeholder={'Select statuses'}
            isDisabled={false}
            onChange={selectedOptions => {
              let content = { type: currentTransfersSelection };
              if (fromDate) {
                content.fromDate = fromDate;
              }
              if (toDate) {
                content.toDate = toDate;
              }
              if (selectedSpId && selectedSpId === 'all') {
                content.providerId = selectedSP.providerId;
              } else if (selectedSpId) {
                content.providerLocationId = selectedSpId;
              }
              content.selectedStatuses = selectedOptions;
              transferSearch(content);
            }}
          />
        </Styled.SearchFilterDiv>

        <Styled.SearchFilterDiv>
          <SelectDropdown
            label='Transfer type'
            options={capabilities}
            isMultiSelect={false}
            isSearchable={false}
            placeholder='Select type'
            isDisabled={false}
            onChange={selectedOption => {
              let content = { type: currentTransfersSelection };
              if (fromDate) {
                content.fromDate = fromDate;
              }
              if (toDate) {
                content.toDate = toDate;
              }
              if (selectedSpId && selectedSpId === 'all') {
                content.providerId = selectedSP.providerId;
              } else if (selectedSpId) {
                content.providerLocationId = selectedSpId;
              }
              if (selectedStatuses && selectedStatuses.length > 0) {
                content.selectedStatuses = selectedStatuses;
              }
              content.transferType = selectedOption;
              transferSearch(content);
            }}
          />
        </Styled.SearchFilterDiv>

        <Styled.SearchFilterDiv>
          <SelectDropdown
            label='Location'
            options={spLocations}
            isMultiSelect={false}
            isSearchable={true}
            placeholder={'Select location'}
            isDisabled={false}
            onChange={selectedOption => {
              let content = { type: currentTransfersSelection };
              if (fromDate) {
                content.fromDate = fromDate;
              }
              if (toDate) {
                content.toDate = toDate;
              }
              if (selectedOption === 'all') {
                content.providerId = selectedSP.providerId;
              } else {
                content.providerLocationId = selectedOption;
              }
              if (selectedStatuses && selectedStatuses.length > 0) {
                content.selectedStatuses = selectedStatuses;
              }
              transferSearch(content);
            }}
          ></SelectDropdown>
        </Styled.SearchFilterDiv>

        <Styled.SearchFilterDiv>
          {userType === 'SA' ? (
            <SearchableDropdown
              label='Service provider'
              onChange={spSearch}
              onSelect={value => {
                let content = {
                  type: currentTransfersSelection,
                  providerId: value
                };
                if (fromDate) {
                  content.fromDate = fromDate;
                }
                if (toDate) {
                  content.toDate = toDate;
                }
                spLocInfoSearch(content);
              }}
              list={spList}
            ></SearchableDropdown>
          ) : (
            <Input label='Service provider' disabled value={selectedSP.providerName}></Input>
          )}
        </Styled.SearchFilterDiv>
      </Styled.FilterOptions>

      <div className='tablediv'>
        {userType === 'SA' ? (
          <SaTable currentPage={currentPage} setCurrentPage={setCurrentPage} />
        ) : (
          <SpTable currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )}
      </div>
    </div>
  );
};

export default IncomingTransfers;
