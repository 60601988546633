import React from 'react';
import { connect } from 'react-redux';
import TransferDashboard from './RmaDashboard/TransferDashboard';
import CreateTransferFlow from './createTransferFlow/CreateTransferFlow';
import ImportFlow from './importFlow/ImportFlow';
import ScanSerials from './scanningFlow/ScanSerials.jsx';
import ScanMaSerials from './scanningMaFlow/ScanMaSerials.jsx';
import ScanDynamic from './scanningDynamicFlow/ScanDynamic.jsx';
import RmaImportCSV from './importFlow/RmaImportCSV.jsx';
import RmaImportManual from './importFlow/RmaImportManual.jsx';
import RmaSummary from './importFlow/RmaSummary';
import RmaSummarySuccess from './importFlow/RmaSummarySuccess';
import Receive from './receiveFlow/Receive';
import ReceiveOrder from './orderFlow/ReceiveOrder';
import TruckFlow from './truckFlow/TruckFlow';

class Returns extends React.Component {
  render() {
    return (
      <>
        {this.props.currentPage === 'rmaDashBoard' ? <TransferDashboard /> : ''}
        {this.props.currentPage === 'scanSerials' ? <ScanSerials /> : ''}
        {this.props.currentPage === 'scanMaSerials' ? <ScanMaSerials /> : ''}
        {this.props.currentPage === 'scanDynamic' ? <ScanDynamic /> : ''}
        {this.props.currentPage === 'adhoc' ? <CreateTransferFlow /> : ''}
        {this.props.currentPage === 'import' ? <ImportFlow /> : ''}
        {this.props.currentPage === 'importFile' ? <RmaImportCSV userSession={this.props.userSession}/> : ''}
        {this.props.currentPage === 'rmaManual' ? <RmaImportManual /> : ''}
        {this.props.currentPage === 'rmaSummary' ? <RmaSummary /> : ''}
        {this.props.currentPage === 'receive' ? <Receive /> : ''}
        {this.props.currentPage === 'receiveOrder' ? <ReceiveOrder /> : ''}
        {this.props.currentPage === 'truckTransfer' ? <TruckFlow /> : ''}
        {this.props.currentPage === 'rmaSummarySuccess' ? <RmaSummarySuccess /> : ''}
      </>
    );
  }
}

const mapStateToProps = ({ ReturnsReducer, userSession }) => {
  const { currentPage } = ReturnsReducer;
  return {
    currentPage,
    userSession
  };
};

export default connect(mapStateToProps)(Returns);
