import styled from 'styled-components';

export const PartInfoDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  border-bottom: ${props => props.bottomBorder};
`;

export const PartInfoLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PartInfoAction = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
