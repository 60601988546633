import React from 'react';
import { Select } from 'antd';

import './SearchableDropdown.scss';

const { Option } = Select;

class SearchableDropdown extends React.Component {
  super(props) {}

  render() {
    let options = this.props.list
      ? this.props.list.map((d) => (
          <Option key={d.value}>
            {d.value}-{d.label}
          </Option>
        ))
      : '';
    return (
      <div>
        <div style={{ float: 'left', paddingRight: '12px' }}>
          <label className="selectLabel">{this.props.label}</label>
        </div>
        <div style={{ width: '100%' }}>
          <Select
            showSearch
            placeholder="search service providers"
            className="genericSearchbleDropdown"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            disabled={this.props.defaultDisabled ? true : null}
            onSearch={(value) => this.props.onChange(value)}
            onChange={(value,label) => this.props.onSelect(value,label)}
            notFoundContent={null}
          >
            {options}
          </Select>

          {this.props.list ? (
            <datalist id="spList">
              {this.props.list.map((item) => (
                <ul key={item.value} value={item.value} label={item.label}></ul>
              ))}
            </datalist>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default SearchableDropdown;
