import React from 'react';
import './Input.scss'

class Input extends React.Component {
    super(props) {

    }

    render() {
        return (
            <div>
                <div style={{ float: 'left',paddingRight: '12px' }}>
                    <label className="inputLabel">{this.props.label}</label>
                </div>
                <div style={{ width: '100%' }}>
                    <input  className="SBinput" disabled={this.props.disabled} value={this.props.value}/>
                </div>

            </div>
        )
    }
}

export default Input;