import React from 'react';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';

import { Banner, NavigationBar, Login, PageNotFound } from '@sbx/sbx-ui-components';
import { getCognitoUriLink } from '@sbx/sbx-deimos-reactui-common/apiUtility';
import { isUserInfoSet } from '@sbx/sbx-deimos-reactui-common/authentication/userSessionConst';
import { generateRandomString, getDomain, isEmpty } from '@sbx/sbx-deimos-reactui-common/genericUtility';
import { setSessionExpired } from '@sbx/sbx-deimos-reactui-common/authentication/userSessionActions';

import config from './config.json';
import logo from './assets/ServiceBench_logo.svg';
import Layout from './components/Layout/Layout';
import { SB_HOME, storage } from '../src/components/common/constants/generalConst';
import { defaultUserInfo } from '../src/components/common/constants/userSessionConst';
import { hideAlert, showErrorMessage } from './actions/alertActions';
import { setSessionContext } from './actions/userSessionActions';
import { getCommonHeaders, userInfo } from './utilities/apiUtility';
import { hideLoading, showLoading } from './actions/appInteractionActions';
import { callUpdateCurrentSession } from '../src/components/action.js';

import _ from 'lodash';
import './App.scss';

const LINK_ARRAY = [
  {
    navigateText: '<- ' + 'Return to ServiceBench',
    navigateUrl: `${config.API.SBWEB.endpoint}${SB_HOME}`
  }
];

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const url = new URL(window.location.href);
  const [cookies, setCookies] = useCookies();

  const {
    userSession: { sessionExpired, userDetails }
  } = useSelector(state => state);

  useEffect(() => {
    handleUserActivity();
    window.addEventListener('click', handleUserActivity);

    return () => {
      window.removeEventListener('click', handleUserActivity);
    };
  }, []);

  useEffect(() => {
    const redirectLogin = async () => {
      dispatch(setSessionExpired(true));
      dispatch(showErrorMessage('Error: Session Expired'));
    };

    if (window.location.pathname !== '/login') {
      if (!cookies.authcookie || cookies.authcookie === '') {
        redirectLogin();
      } else {
        if (!isUserInfoSet(userInfo, defaultUserInfo)) {
          const ssoState = cookies.ssoState ? true : false;
          dispatch(setSessionContext(cookies, setCookies));
          const ssoPrevUrl = window.sessionStorage.getItem(storage.ssoPrevUrl);
          const currentEndpoint = window.location.pathname + window.location.search;
          if (url.searchParams?.get('state') && ssoState && ssoPrevUrl !== currentEndpoint) {
            history.push(ssoPrevUrl ? ssoPrevUrl : '/');
          }
        }
      }
    }
  }, [window.location.href, cookies.authcookie]);

  useEffect(() => {
    const currentEndpoint = window.location.pathname + window.location.search;
    const storedCurrentURL = window.sessionStorage.getItem(storage.currentUrl);
    if (!storedCurrentURL || storedCurrentURL === '/null') {
      window.sessionStorage.setItem(storage.prevUrl, '/');
      window.sessionStorage.setItem(storage.currentUrl, currentEndpoint);
    } else if (storedCurrentURL !== currentEndpoint) {
      window.sessionStorage.setItem(storage.currentUrl, currentEndpoint);
      window.sessionStorage.setItem(storage.prevUrl, storedCurrentURL === '/login' ? '/' : storedCurrentURL);
      const newUrl = window.sessionStorage.getItem(storage.currentUrl);
    } else {
      window.sessionStorage.setItem(storage.currentUrl, '/');
    }
  }, [window.location.href]);

  useEffect(() => {
    if (sessionExpired) {
      history.push('/login');
    }
  }, [sessionExpired]);

  useEffect(() => {
    if (!isEmpty(userDetails)) {
      history.push('/');
    }
  }, [userDetails]);

  const handleUserActivity = () => {
    if (window.location.pathname !== '/login') {
      dispatch(callUpdateCurrentSession(setCookies));
    }
  };

  const closeWindow = () => {
    const location = `${config.API.SBWEB.endpoint}${SB_HOME}`;
    window.location.href = location;
  };

  const handleCookieChange = () => {
    dispatch(setSessionContext(cookies, setCookies));
    dispatch(setSessionExpired(false));
    dispatch(hideAlert());
    const prevPath = window.sessionStorage.getItem(storage.prevUrl);
    history.push(prevPath ? prevPath : '/');
    window.location.reload();
  };

  const handleSSO = credentials => {
    const { serviceBenchId: companyId } = credentials;
    const ssoStateCheck = generateRandomString(10);
    setCookies('ssoState', ssoStateCheck);

    const prevPath = window.sessionStorage.getItem(storage.prevUrl);
    prevPath ? window.sessionStorage.setItem(storage.ssoPrevUrl, prevPath) : window.sessionStorage.setItem(storage.ssoPrevUrl, '/');

    window.location.href = getCognitoUriLink({
      companyId,
      congitoEndpoint: config.API.COGNITO.endpoint,
      congnitoClientId: config.API.COGNITO.clientId,
      cognitoProvider: config.API.COGNITO.provider,
      cognitoRedirectURI: config.API.COGNITO.redirectUri,
      cognitoResponseType: config.API.COGNITO.responseType,
      cognitoScope: config.API.COGNITO.scope,
      randomCode: ssoStateCheck
    });
  };

  const setDomainCookies = (cookieName, cookieValue) => {
    setCookies(cookieName, cookieValue, { domain: getDomain(), path: '/' });
  };

  return (
    <div id='app_container' className='App'>
      <Banner
        style={{ backgroundColor: 'black' }}
        logo={logo}
        menuArray={[
          {
            id: 1,
            name: 'Return to ServiceBench',
            handleClick() {
              closeWindow();
            }
          },
          {
            id: 2,
            name: 'Log Out',
            handleClick() {
              setCookies('authcookie', '', { path: '/' });
              dispatch(setSessionExpired(true));
            }
          }
        ]}
      />
      <NavigationBar linkArray={LINK_ARRAY} />
      <Switch>
        <Route>
          <div id='app_main_container' className='main-container'>
            <main className='main-content' role='main' id='main-content'>
              <Switch>
                <Route path='/' exact>
                  <Layout />
                </Route>
                <Route path='/login' exact>
                  <Login
                    apiConfig={config.API.BaseUrl}
                    closeWindow={closeWindow}
                    cookies={cookies}
                    getHeaders={getCommonHeaders}
                    handleCookieChange={handleCookieChange}
                    handleSSO={handleSSO}
                    hideLoading={() => dispatch(hideLoading())}
                    setCookies={(cookieName, cookieValue) => setDomainCookies(cookieName, cookieValue)}
                    showErrorMessage={message => dispatch(showErrorMessage(message))}
                    showLoading={() => dispatch(showLoading())}
                    userInfo={userInfo}
                  />
                </Route>
                <Route>
                  <PageNotFound />
                </Route>
              </Switch>
            </main>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default App;
