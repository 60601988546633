import React from 'react';
import './MessageModal.scss';

class MessageModal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div id="messageModal" className="MessageModal">
        <div
          className={
            this.props.bodyStyle ? `${this.props.bodyStyle}` : 'modal-body'
          }
        >
          <div id="Modal-header" className="Modal-header">
            <p className="ModalTitle">{this.props.header}</p>
          </div>
          <div
            id="Modal-content"
            className={
              this.props.contentStyle
                ? `${this.props.contentStyle}`
                : 'Modal-content'
            }
          >
            {this.props.content}
          </div>
          <div id="Model-footer" className="Modal-footer">
            {this.props.noCancel === 'true' ? (
              ''
            ) : (
              <button
                className="modalButton modalButtonBackward"
                onClick={() => {
                  if (this.props.cancel) {
                    this.props.cancel();
                  }
                }}
              >
                {this.props.cancelName ? this.props.cancelName : 'Cancel'}
              </button>
            )}
            {this.props.noContinue === 'true' ? (
              ''
            ) : (
              <button
              className="modalButton modalButtonForward "
                onClick={() => {
                  if (this.props.continue) {
                    this.props.continue();
                  }
                }}
              >
                {this.props.continueName ? this.props.continueName : 'Continue'}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MessageModal;
