import * as Styled from './RmaInfo.styles';
import PartInfo from './PartInfo';
import { Fragment, useState } from 'react';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { ReactComponent as Expand } from '../../../assets/chevron-down.svg';
import { ReactComponent as Collapse } from '../../../assets/chevron-up.svg';

const RmaInfo = ({ rmaInfo }) => {

  const { rmaNumber } = rmaInfo.rmaInfo;
  const [collapsedRmas, setCollapsedRmas] = useState({});

  const toggleRma = rmaId => {
    setCollapsedRmas(prev => ({
      ...prev,
      [rmaId]: !prev[rmaId]
    }));
  };

  return (
    <Fragment>
      <Styled.RmaInfoHeaderContainer onClick={() => toggleRma(rmaNumber)}>
        <Text size='4' weight='heavy'>
          Transfer no. {rmaNumber}
        </Text>
        <div>
          <Text size='2'>Shipping Destination: </Text>
          <Text size='2' weight='heavy'>
            {rmaInfo.to.locationName}{' '}
          </Text>
          {collapsedRmas[rmaNumber] ? <Expand /> : <Collapse />}
        </div>
      </Styled.RmaInfoHeaderContainer>
      {!collapsedRmas[rmaNumber] && (
        <div>
          {rmaInfo.parts.map(part => (
            <PartInfo key={part.partNumber} rmaInfo={rmaInfo} part={part} />
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default RmaInfo;
