import * as Styled from './NavBar.styles';
import { LeftArrow } from './NavBar.styles';
import { Button } from '@sbx/sbx-ui-components';

const NavBar = ({ returnToDashboard }) => {
  
  return (
    <Styled.ScanNavLabel>
      <Styled.ScanNavContent>
        <LeftArrow />
        <Button variant='text' onClick={returnToDashboard}>
          Return to Transfer Dashboard
        </Button>
      </Styled.ScanNavContent>
    </Styled.ScanNavLabel>
  );
};

export default NavBar;
