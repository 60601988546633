import { SET_RMA_INFO_FOR_SCAN_PAGE } from '../../../actionTypes';
const initialState = {
  pageData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RMA_INFO_FOR_SCAN_PAGE:
      return {
        ...state,
        pageData: action.data,
      };
    default:
      return state;
  }
};
