import {
  SET_TRUCK_PAGE_INITIAL_INFO,
  SET_TRUCK_PAGE_PARTS,
  SET_TRUCK_PAGE_STRUCTURED_PARTS_DATA,
} from '../../../actionTypes';
const initialState = {
  truckData: {},
  parts: {
    locationIdOrNumber: '',
    sourceLocationIdOrNumber: '',
    serializedPartList: [],
    partList: [],
  },
  currentParts: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TRUCK_PAGE_PARTS:
      return {
        ...state,
        parts: action.data,
      };
    case SET_TRUCK_PAGE_INITIAL_INFO:
      return {
        ...state,
        truckData: action.data,
      };
    case SET_TRUCK_PAGE_STRUCTURED_PARTS_DATA:
      return {
        ...state,
        currentParts: action.data,
      };
    default:
      return state;
  }
};
