import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MessageModal from '../../common/MessageModal';
import _ from 'lodash';

class EnterQuantityModal extends React.Component {
  constructor(props) {
    super(props);
    this.onCancel = props.onCancel.bind(this);
    this.onContinue = props.onContinue.bind(this);
    this.state = {
      quantity: props.defaultQuantity
    };
  }  
  render() {
    return (
      <MessageModal
        id="quantityModal"
        header="Enter quantity"
        content={
          <div>
            <input
              autoFocus
              type="number"
              min="0"
              className="Modal-input"
              onChange={(event) => this.state = { ...this.state, quantity: event.target.value }}
              onKeyDown={(event) => {
                if (event.keyCode === 13 && event.target.value !== '') {
                  this.onContinue(this.props.asurionBarcode, _.toNumber(this.state.quantity))
                }}}              
              defaultValue={this.props.defaultQuantity}
            />
            <p style={{ textAlign: 'left' }}>
              Requested:{this.props.defaultQuantity}
            </p>
          </div>
        }
        cancel={this.onCancel}
        continue={() => this.onContinue(this.props.asurionBarcode, _.toNumber(this.state.quantity))}
      />
    );
  }  
};

EnterQuantityModal.propTypes = {
  partNumber: PropTypes.string,
  asurionBarcode: PropTypes.string,
  defaultQuantity: PropTypes.number,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func
};

const mapStateToProps = ({ rmaScanMaReducer }) => {
    const { pageData } = rmaScanMaReducer;
    return {
      pageData,
    };
  };

const mapDispatchToProps = (dispatch) => 
  bindActionCreators({

  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnterQuantityModal);