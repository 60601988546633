import './CreateTransferFlow.scss';
import { useState } from 'react';
import MessageModal from '../../common/MessageModal';
import { useDispatch, useSelector } from 'react-redux';
import SelectDropdown from '../../common/SelectDropdown';
import { CONSTANTS } from '../../common/constants/generalConst';
import { RadioButton, ButtonGroup, Button, Text } from '@soluto-private/mx-asurion-ui-react';

import { goToImportFile } from '../RmaDashboard/action.js';
import { addSuccessMessage, addErrorMessage, clearMessages } from '../../action';
import { goToScanningFlow, goToDynamicScanningFlow, goToTruckFlow } from '../RmaDashboard/action';
import {
  getSourceLocations,
  goToRmaDashboard,
  createRma,
  getDestinationLocations,
  handleSelectedTransferType,
  handleOriginChange,
  handleDestinationChange
} from './action';

const NOT_SELECTED = '';

const CreateTransferFlow = () => {
  const dispatch = useDispatch();
  const { capabilities } = useSelector(state => state.rmadashboardReducer);
  const { sourceLocations, destinationLocations, selectedTransferType, selectedOrigin, selectedDestination } = useSelector(
    state => state.createTransferReducer
  );

  const [serviceProviderLocationId, setServiceProviderLocationId] = useState(NOT_SELECTED);
  const [destinationLocation, setDestinationLocation] = useState(NOT_SELECTED);
  const [modalVisible, setModalVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [typeOfTransfer, setTypeOfTransfer] = useState(NOT_SELECTED);
  const [sourcePartLocationId, setSourcePartLocationId] = useState(NOT_SELECTED);
  const [destinationPartLocationId, setDestinationPartLocationId] = useState(NOT_SELECTED);
  const [direction, setDirection] = useState('outgoing');
  const [sourceDescription, setSourceDescription] = useState('');
  const [destinationDescription, setDestinationDescription] = useState('');
  const [externalReferenceNumber, setExternalReferenceNumber] = useState('');
  const [createWithDuplicateRefNumber, setCreateWithDuplicateRefNumber] = useState(false);

  const validateAndCreateTransfer = async () => {
    dispatch(clearMessages());
    if (typeOfTransfer === NOT_SELECTED) {
      dispatch(addErrorMessage('Please select transfer type.'));
    } else {
      if (serviceProviderLocationId === NOT_SELECTED) {
        if (typeOfTransfer === CONSTANTS.CAPABILITIES.TRUCK) {
          dispatch(addErrorMessage('Please select FSL location.'));
        } else {
          dispatch(addErrorMessage('Please select shipping origin location.'));
        }
      }
      if (typeOfTransfer === CONSTANTS.CAPABILITIES.TRUCK) {
        if (destinationPartLocationId === NOT_SELECTED || destinationPartLocationId === '') {
          dispatch(addErrorMessage('Please select technician.'));
        }
        if (
          destinationPartLocationId !== '' &&
          destinationPartLocationId !== NOT_SELECTED &&
          sourcePartLocationId !== '' &&
          sourcePartLocationId !== NOT_SELECTED
        ) {
          const result = await dispatch(
            createRma(
              typeOfTransfer,
              serviceProviderLocationId,
              null,
              null,
              null,
              destinationPartLocationId,
              direction,
              destinationDescription,
              sourceDescription
            )
          );
          if (result && result.status) {
            if (result.status === 'warning') {
              doSetModalVisible(true);
              setMessage(result.message);
            } else if (result.status === 'success') {
              doSetModalVisible(false);
              dispatch(addSuccessMessage('Transfer has been created successfully'));
              dispatch(
                goToTruckFlow({
                  transferNumber: result.rmaNumber,
                  typeOfTransfer,
                  sourcePartLocationId,
                  destinationPartLocationId,
                  serviceProviderLocationId,
                  sourceDescription,
                  destinationDescription,
                  direction
                })
              );
            } else if (result.status === 'failure') {
              if (result.message) {
                dispatch(addErrorMessage(result.message));
              } else {
                dispatch(addErrorMessage('Something went wrong when creating transfer. Please try again.'));
              }
            }
          } else {
            dispatch(addErrorMessage('Something went wrong when creating transfer. Please try again.'));
          }
        }
      } else {
        if (destinationLocation === NOT_SELECTED) {
          dispatch(addErrorMessage('Please select Destination location '));
        }
        if (typeOfTransfer === 'returns' && destinationLocation?.name && destinationLocation?.name === 'Verizon 5G') {
          doSetModalVisible(false);
          dispatch(
            goToDynamicScanningFlow(
              destinationLocation.name,
              sourcePartLocationId,
              sourceDescription,
              serviceProviderLocationId,
              externalReferenceNumber,
              createWithDuplicateRefNumber,
              null
            )
          );
          return;
        }
        if (serviceProviderLocationId !== NOT_SELECTED && destinationLocation !== NOT_SELECTED) {
          const result = await dispatch(
            createRma(
              typeOfTransfer,
              serviceProviderLocationId,
              destinationLocation,
              externalReferenceNumber,
              createWithDuplicateRefNumber,
              destinationPartLocationId,
              null,
              null,
              null
            )
          );
          if (result && result.status) {
            if (result.status === 'warning') {
              doSetModalVisible(true);
              setMessage(result.message);
            } else if (result.status === 'success') {
              doSetModalVisible(false);
              dispatch(addSuccessMessage('Transfer has been created successfully'));
              dispatch(goToScanningFlow(result.rmaNumber));
            } else if (result.status === 'failure') {
              if (result.message) {
                dispatch(addErrorMessage(result.message));
              } else {
                dispatch(addErrorMessage('Something went wrong when creating transfer. Please try again.'));
              }
            }
          } else {
            dispatch(addErrorMessage('Something went wrong when creating transfer. Please try again.'));
          }
        }
      }
    }
  };

  const doSetModalVisible = bool => {
    setModalVisible(bool);
    setExternalReferenceNumber('');
  };

  const doContinue = () => {
    setCreateWithDuplicateRefNumber(true);
    validateAndCreateTransfer();
  };

  const continueToImportFile = () => {
    doSetModalVisible(false);
    dispatch(goToImportFile());
  };

  const transferTypeOnChange = transferType => {
    const matchedTransferType = capabilities.filter(item => (item?.value === transferType ? item : null));
    dispatch(handleSelectedTransferType(matchedTransferType[0]));

    setTypeOfTransfer(transferType);
    setServiceProviderLocationId(NOT_SELECTED);
    setDestinationLocation(NOT_SELECTED);
    setSourcePartLocationId(NOT_SELECTED);
    setDestinationPartLocationId(NOT_SELECTED);

    dispatch(getDestinationLocations(transferType));
    dispatch(getSourceLocations(transferType));
  };

  const originOnChange = location => {
    const { serviceProviderLocationId, spPartLocation_id, description } = location;
    let matchedOriginLocation = sourceLocations.filter(item => (item?.label === description ? item : null));
    dispatch(handleOriginChange(matchedOriginLocation[0]));

    setDestinationLocation(NOT_SELECTED);
    setDestinationDescription(NOT_SELECTED);
    setDestinationPartLocationId(NOT_SELECTED);
    setServiceProviderLocationId(serviceProviderLocationId);
    setSourceDescription(description);
    setSourcePartLocationId(spPartLocation_id);

    if (typeOfTransfer === CONSTANTS.CAPABILITIES.TRUCK || typeOfTransfer === CONSTANTS.CAPABILITIES.FSL) {
      dispatch(getDestinationLocations(typeOfTransfer, serviceProviderLocationId));
    }
  };

  const destinationOnChange = location => {
    let matchedDestination = null;
    if (typeOfTransfer === CONSTANTS.CAPABILITIES.RETURNS || typeOfTransfer === CONSTANTS.CAPABILITIES.CORE_RETURN) {
      matchedDestination = destinationLocations.filter(item => (item?.label === location?.name ? item : null));
      setDestinationLocation(matchedDestination[0]?.value ?? NOT_SELECTED);
    } else if (typeOfTransfer === CONSTANTS.CAPABILITIES.TRUCK) {
      matchedDestination = destinationLocations.filter(item => (item?.label === location?.description ? item : null));
      setDestinationPartLocationId(location?.spPartLocation_id ?? '');
      setDestinationDescription(location?.description ?? '');
    } else {
      matchedDestination = destinationLocations.filter(item => (item?.label === location?.description ? item : null));
      setDestinationPartLocationId(location?.spPartLocation_id ?? '');
      setDestinationDescription(location?.description ?? '');
      setDestinationLocation(location);
    }
    if (matchedDestination) {
      dispatch(handleDestinationChange(matchedDestination[0]));
    }
  };

  const truckRadioButtonsOnChange = event => {
    setDirection(event.target.value);
  };

  const continueOnClick = () => {
    if (typeOfTransfer === CONSTANTS.CAPABILITIES.IMPORT_FILE_TRANSFER) {
      continueToImportFile();
    } else {
      validateAndCreateTransfer();
    }
  };

  const returnToDashboard = () => {
    dispatch(goToRmaDashboard());
  };

  return (
    <>
      {modalVisible && <MessageModal header='Warning' content={message} cancel={doSetModalVisible(false)} continue={doContinue} />}
      <div className='rmaadhocdiv'>
        <div className='rmaadhocchilddiv'>
          <Text size={5}>Start New Transfer</Text>
        </div>

        <SelectDropdown
          label={'Transfer Type'}
          options={capabilities}
          isMultiSelect={false}
          isSearchable={true}
          isDisabled={false}
          onChange={event => transferTypeOnChange(event)}
          value={selectedTransferType}
        />

        {typeOfTransfer === CONSTANTS.CAPABILITIES.TRUCK && (
          <div role='radiogroup' className='trukRadioButtonMainContainer'>
            <RadioButton label='Outgoing' value='outgoing' checked={direction === 'outgoing'} onChange={event => truckRadioButtonsOnChange(event)} />
            <RadioButton label='Incoming' value='incoming' checked={direction === 'incoming'} onChange={event => truckRadioButtonsOnChange(event)} />
          </div>
        )}

        <SelectDropdown
          label={typeOfTransfer === CONSTANTS.CAPABILITIES.TRUCK ? 'FSL Location' : 'Shipping Origin'}
          options={sourceLocations}
          isMultiSelect={false}
          isSearchable={true}
          isDisabled={false}
          onChange={event => originOnChange(event)}
          value={selectedOrigin}
        />

        <SelectDropdown
          label={typeOfTransfer === CONSTANTS.CAPABILITIES.TRUCK ? 'Technician' : 'Shipping Destination'}
          options={destinationLocations}
          isMultiSelect={false}
          isSearchable={true}
          isDisabled={false}
          onChange={event => destinationOnChange(event)}
          value={selectedDestination}
        />

        {typeOfTransfer === CONSTANTS.CAPABILITIES.RETURNS && (
          <div className='rmaadhocchilddiv'>
            <Text size={2}>Reference number</Text>
            <input className='rmaadhocinput' value={externalReferenceNumber} onChange={event => setExternalReferenceNumber(event.target.value)}></input>
          </div>
        )}

        <div className='rmaadhocbuttondiv'>
          <ButtonGroup alignment='center'>
            <Button variant='outline' onClick={returnToDashboard}>
              Cancel
            </Button>
            <Button onClick={continueOnClick}>Continue</Button>
          </ButtonGroup>
        </div>
      </div>
    </>
  );
};

export default CreateTransferFlow;
