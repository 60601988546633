import Select from 'react-select';
import * as Styled from './SelectDropdown.styles';

const SelectDropdown = ({ label, options, isMultiSelect, isSearchable, placeholder, isDisabled, onChange, value }) => {
  const handleOptionChange = optionValue => {
    if (isMultiSelect) {
      let updatedSelection = [];
      if (optionValue.length > 0) {
        for (const option of optionValue) {
          updatedSelection.push(option.value);
        }
      }
      onChange(updatedSelection);
    } else {
      let updatedSelection = optionValue.value ?? null;
      onChange(updatedSelection);
    }
  };

  return (
    <Styled.DropdownParent>
      {label && <label>{label}</label>}
      {value && (
        <Select
          options={options}
          isMulti={isMultiSelect}
          isSearchable={isSearchable}
          placeholder={placeholder}
          isDisabled={isDisabled}
          onChange={handleOptionChange}
          value={value}
        />
      )}
      {!value && (
        <Select
          options={options}
          isMulti={isMultiSelect}
          isSearchable={isSearchable}
          placeholder={placeholder}
          isDisabled={isDisabled}
          onChange={handleOptionChange}
        />
      )}
    </Styled.DropdownParent>
  );
};

export default SelectDropdown;
