import * as Styled from './SubmitModal.styles';
import { Text } from '@sbx/sbx-ui-components';
import { Modal } from '@sbx/sbx-ui-components';

const SubmitModal = ({ returnToDashboard }) => {
  
  return (
    <Modal
      title='Packing complete!'
      isOpen={true}
      primaryButtonLabel='Return to Transfer Dashboard'
      onPrimaryClick={returnToDashboard}
      onCloseClick={returnToDashboard}
    >
      <Styled.SuccessModalContent>
        <Text size='2'>Next step is to print each packing list shipping label and affix the shipping label to the correct box.</Text>
        <Text size='2'>
          We'll send you an email once each label is ready, or you can check the Transfer Dashboard for updates. They should be ready in the next 5-10 minutes.
        </Text>
      </Styled.SuccessModalContent>
    </Modal>
  );
};

export default SubmitModal;
