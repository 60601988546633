import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeShipLabelModal } from './action';
import './RmaDashModal.scss';
import { Spin } from 'antd';

function printContent(props) {
  var frame1 = document.createElement('iframe');
  frame1.name = 'frame1';
  frame1.style.position = 'absolute';
  frame1.style.top = '-1000000px';
  document.body.appendChild(frame1);
  var frameDoc = frame1.contentWindow
    ? frame1.contentWindow
    : frame1.contentDocument.document
    ? frame1.contentDocument.document
    : frame1.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write(
    `<html><head></head><body><p style="
          font-family: Apercu Pro;
          font-size:18px;
          color: #000000;
          padding-bottom: 15px,
        "}}">Shipping label for ${props.rmaInfoInShipPackModal.rmaInfo.rmaNumber}</p>`
  );
  let content = '';
  if (props.rmaInfoInShipPackModal.shippingInfo.shippingLabelUrl) {
    content = `<img style="width:700px" src=${props.rmaInfoInShipPackModal.shippingInfo.shippingLabelUrl} alt="Shipping Label"></img>`;
  } else {
    content = `<img style="width:700px" src=${props.rmaInfoInShipPackModal.shippingInfo[0].shippingLabelUrl} alt="Shipping Label"></img>`;
  }

  frameDoc.document.write(content);

  frameDoc.document.write('</body></html>');
  frameDoc.document.close();
  setTimeout(function () {
    window.frames['frame1'].focus();
    window.frames['frame1'].print();
    document.body.removeChild(frame1);
  }, 500);
  return false;
}

const ShipLabelModal = (props) => {
  const [ loading, setLoading ] = useState(true);
  const handleLoading = () => {
    setLoading(false);
  };
  return (
    <div id="shipLabelModel" className="rmaDashModal">
      <div id="shipContent">
        <div className="modal-content">
          <div id="labelContent" style={{ width: '100%' }}>
            <p
              style={{
                'font-family': 'Apercu Pro',
                'font-size': '18px',
                color: '#000000',
                'padding-bottom': '15px',
              }}
            >
              Shipping label for{' '}
              {props.rmaInfoInShipPackModal.rmaInfo.rmaNumber}
            </p>
            {loading && <Spin size="large" />}
            {props.rmaInfoInShipPackModal.shippingInfo.shippingLabelUrl ? (
              <img
                style={{ width: '700px' }}
                src={props.rmaInfoInShipPackModal.shippingInfo.shippingLabelUrl}
                alt="Shipping Label"
                onLoad={handleLoading}
              ></img>
            ) : (
              <img
                style={{ width: '700px' }}
                src={
                  props.rmaInfoInShipPackModal.shippingInfo[0].shippingLabelUrl
                }
                alt="Shipping Label"
                onLoad={handleLoading}
              ></img>
            )}
          </div>
          <div id="model footer" style={{ width: '100%' }}>
            <button className="modalButton" onClick={props.closeShipLabelModal}>
              Cancel
            </button>
            <button className="modalButton" onClick={() => printContent(props)}>
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ rmadashboardReducer }) => {
  const { userType, rmaInfoInShipPackModal } = rmadashboardReducer;
  return {
    userType,
    rmaInfoInShipPackModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeShipLabelModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShipLabelModal);
