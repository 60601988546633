import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as LeftArrowLogo } from '../../../assets/arrow1-left.svg';
import { ReactComponent as CloseButton } from '../../../assets/close2.svg';
import { ReactComponent as Expand } from '../../../assets/chevron-down.svg';
import { ReactComponent as Collapse } from '../../../assets/chevron-up.svg';
import BarcodeReader from 'react-barcode-reader';
import { addErrorMessage, enableSpinner, disableSpinner } from '../../action';
import './ScanMaSerial.scss';
import {
  goToRmaDashboard,
  toggleChild, 
  validateAsurionBarcode,
  deleteSerial,
  generatePackingListOpen,
  generatePackingListClose,
  addQuantityOpen,
  addQuantityClose,
  successModalClose,
  readRmaInfo
} from './action';
import _ from 'lodash'
import ValidationModal from './ValidationModal'
import EnterQuantityModal from './EnterQuantityModal'
import SuccessModal from './SuccessModal';

class ScanMaSerials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      message: '',
      borderColor: '#377a80',
      modalVisible: false,
      validatationModalVisible: false,
      successModalVisible: false,
      currentPartNumber: '',
      requestedQuantity: 0,
      scannedQuantity: 0,
      partsReasons: [],
      asurionBarcode: '',
      doNotShowContinue : 'true'
    };
    this.onScan = this.onScan.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.validatePartsAndPack = this.validatePartsAndPack.bind(this);
  }
  async onScan(asurionBarcode) {
    this.props.enableSpinner();
    const response = await this.props.validateAsurionBarcode({
      providerId: this.props.pageData.rmaInfo.serviceProviderId, 
      serviceProviderLocation: this.props.pageData.rmaInfo.serviceProviderLocation,
      inventoryReturnId: this.props.pageData.rmaInfo.inventoryReturnId, 
      asurionBarcode
      });
    if (response.status === "SUCCESS") {
      await this.props.addQuantityOpen({
          providerId: this.props.pageData.rmaInfo.serviceProviderId, 
          partNumber: this.props.ui.partNumber,
          rmaNumber: response.rmaNumber,
          asurionBarcode: asurionBarcode,
          requestedQuantity: response.requestedQuantity
        });
      this.setState({ showError: false, borderColor: '#377a80', asurionBarcode: "" });
    } else if (response.status === "FAILURE") {
      this.setState({ showError: true, borderColor: '#db1b1b', message: 'Not valid.' });
    }
    this.props.disableSpinner();
  }

  async onDelete(asurionBarcode, partNumber) {
    this.props.deleteSerial({
      providerId: this.props.pageData.rmaInfo.serviceProviderId, 
      rmaNumber: this.props.pageData.rmaInfo.rmaNumber,
      partNumber,
      asurionBarcode
    });
  }

  async validatePartsAndPack() {
    this.props.enableSpinner();
    this.props.generatePackingListOpen({ details: this.props.pageData.details });
    this.props.disableSpinner();
  }

  componentDidMount() {
    this.props.readRmaInfo({
      rmaNumber: this.props.ui.rmaNumber
    });
  }

  render() {
    const { pageData } = this.props;
    if (_.isEmpty(pageData.details)) {
      return (
        <div>
          <p>Insufficient information for {_.get(this.props, "pageData.rmaInfo.rmaNumber", "unknown")}</p>
          <p><a className="ScanNavLabel" href="#" onClick={this.props.goToRmaDashboard}>Return to Transfer Dashboard</a></p>
        </div>
      );
    }
    return (
      <div>
        <div className="ScanNavLabel">
          {this.props.ui.updateModalVisible &&
            <EnterQuantityModal
              onCancel={() => this.props.addQuantityClose({ cancel: true })}
              onContinue={(asurionBarcode, quantity) => this.props.addQuantityClose({ 
                ...this.props.ui, rmaNumber: this.props.pageData.rmaInfo.rmaNumber, 
                providerId: this.props.pageData.rmaInfo.serviceProviderId, asurionBarcode, quantity 
              })}
              asurionBarcode={_.get(this.props.ui, "asurionBarcode", "")}
              partNumber={_.get(this.props.ui, "partNumber", "")}
              defaultQuantity={_.get(this.props.ui, "requestedQuantity", 0)} />
          }
          {this.props.ui.validatationModalVisible &&
            <ValidationModal 
              partsReasons={_.get(this.props.ui, "partsReasons", [])}
              onCancel={() => this.props.generatePackingListClose({ cancel: true })}
              onContinue={() => this.props.generatePackingListClose({ rmaNumber: this.props.pageData.rmaInfo.rmaNumber })} />
          }
          {this.props.ui.successModalVisible && 
            <SuccessModal 
              onContinue={() => this.props.successModalClose()}
            />
          }
          <LeftArrowLogo className="scanLeftArraow" />
          <a className="ScanNavLabel" href="#" onClick={this.props.goToRmaDashboard}>Return to Transfer Dashboard</a>
        </div>
        <div className="rmaNumberLabel">Transfer no. {pageData.rmaInfo.rmaNumber}</div>
        <div className="ScanContent">
          <div className="scanParentDiv">
            <p className="title"> Scan or enter parts</p>
            <p className="title2"> Asurion Barcode</p>
            <input
              id="scanInputBox"
              autoComplete="off"
              title="Press Enter Key To Validate Asurion Barcode"
              style={{ borderColor: this.state.borderColor }}
              value={this.state.asurionBarcode}
              disabled={
                pageData.rmaInfo.status === 'INITIATED'
                  ? false
                  : true
              }
              className="scanbox"
              onChange={(event) => {
                this.setState({
                  showError: false,
                  borderColor: '#377a80',
                  asurionBarcode: event.target.value,
                });
              }}
              onKeyDown={(event) => {
                if (event.keyCode === 13 && event.target.value !== '') {
                  this.onScan(event.target.value);
                }
              }} />
            <div><BarcodeReader onScan={this.onScan} /></div>
            {this.state.showError ? (
              <div className="scanMessage">
                <p>{this.state.message}</p>
              </div>
            ) : (
              ''
            )}
            <div className="fromToInfoDiv">
              <p className="labeltext">Shipping Origin</p>
              <p className="locationNameText">
                {pageData.from.locationName}
              </p>

              <p className="labeltext">Shipping Destination</p>
              <p className="locationNameText">
                {pageData.to.locationName}
              </p>
            </div>
            <button
              className={
                pageData.rmaInfo.status === 'INITIATED' &&
                _.isEmpty(pageData.parts)
                  ? 'buttonEnabled'
                  : 'buttonDisabled'
              }
              disabled={
                pageData.rmaInfo.status === 'INITIATED' &&
                _.isEmpty(pageData.parts)
                  ? false
                  : true
              }
              onClick={() => this.validatePartsAndPack(false)}
            >
              <p className="buttonText">Generate packing list</p>
            </button>
          </div>
          <div className="partsDiv">
            <p className="title">Scanned Parts</p>
            <div className="partsContainer">
              {_.map(pageData.details, (part, id) => {
                let backgroundcolor =
                  part["partNumber"].requestedQuantity <
                  part["partNumber"].scannedQuantity
                    ? '#F9D51C'
                    : part.requestedQuantity >
                      part.scannedQuantity
                    ? '#FFFFFF'
                    : '#F0FFF9';
                return (
                  <div className="partContainer" 
                    id={`partsContainer_row_${id}`}
                    key={`partsContainer_row_${id}`}
                    >
                      <div
                      className="partInfo"
                      style={{ backgroundColor: backgroundcolor }}
                      >
                      {part["partNumber"]}
                      <div style={{ float: 'right' }}>
                        {!part.visible ? (
                          <Expand
                            onClick={() =>
                              this.props.toggleChild(
                                part["partNumber"],
                                true
                              )
                            }
                          />
                        ) : (
                          <Collapse
                            onClick={() =>
                              this.props.toggleChild(
                                part["partNumber"],
                                false
                              )
                            }
                          />
                        )}
                      </div>
                      {pageData.rmaInfo.returnType !== 'SP_INITIATED_FSL' ? (
                        <div style={{ float: 'right', marginRight: '5px' }}>
                          {part["scanQuantity"]} / {part["requiredQuantity"]}
                        </div>
                      ) : (
                        <div style={{ float: 'right', marginRight: '5px' }}>
                          {part["requiredQuantity"]}
                        </div>
                      )}
                      </div>  
                      {part.visible ? (
                      <div id={part["partNumber"]} className="rmascanchildtablediv">
                        <table className="rmascanchildtable">
                          <tbody>      
                            {_.map(part.parts, (item, idItem) => {
                              let statusbackgroundcolor =
                                item.returnStatus === 'Scanned'
                                  ? '#37E7A7'
                                  : '#000000';
                                let statuscolor =
                                  item.returnStatus === 'Scanned'
                                    ? '#000000'
                                    : '#ffffff';
                                return (
                                  <tr 
                                    id={`rmascanchildtable_row_${idItem}`}
                                    key={`rmascanchildtable_row_${idItem}`}
                                    className="serialRow">
                                    <td>
                                      <div className="serial">
                                        {item.asurionBarcode}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="status">
                                        <p
                                          style={{
                                            background: statusbackgroundcolor,
                                            color: statuscolor,
                                            display: 'inline-block',
                                          }}
                                        >
                                          {item.returnStatus}
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="quantity">
                                          {item.scanQuantity} / {item.requiredQuantity}
                                      </div>                                      
                                    </td>
                                    <td className="delete">
                                      {pageData.rmaInfo.status === 'INITIATED' && item.returnStatus === 'Scanned' && (
                                          <CloseButton
                                            onClick={() =>
                                              this.onDelete(
                                                item.asurionBarcode,
                                                item.partNumber
                                              )
                                            }
                                          />
                                        )}
                                    </td>                                                                    
                                  </tr>
                                );
                            })}               
                          </tbody>
                        </table>  
                      </div>  
                      ) : ('')}
                    </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ rmaScanMaReducer }) => {
  const { pageData, ui } = rmaScanMaReducer;
  return {
    pageData,
    ui
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToRmaDashboard,
      validateAsurionBarcode,
      toggleChild,
      deleteSerial,
      readRmaInfo,
      addErrorMessage,
      generatePackingListOpen,
      generatePackingListClose,
      addQuantityOpen,
      addQuantityClose,
      enableSpinner,
      disableSpinner,
      successModalClose
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ScanMaSerials);
