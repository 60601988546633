import { SET_SESSION_DATA, SET_SESSION_EXPIRED } from '@sbx/sbx-deimos-reactui-common/authentication/userSessionConst';

const initialState = {
  userDetails: {},
  userAccess: {},
  sessionExpired: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION_DATA:
      return {
        ...state,
        userDetails: action.payload.userDetails,
        userAccess: action.payload.userAccess
      };
    case SET_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: action.payload
      };
    default:
      return state;
  }
};
