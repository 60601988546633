import {
  SET_RETURNS_NAVIGATION,
  SET_APPLICATION_ERROR_MESSAGES,
  SET_APPLICATION_SUCCESS_MESSAGES,
  SET_ORDER_INFO_FOR_SCAN_PAGE,
  SET_ASN_DATA,
  SET_ORDER_CHANGES_SCAN_PAGE
} from '../../../actionTypes';
import { Post, uriName } from '../../../utilities/apiUtility';

export const goToRmaDashboard = () => async dispatch => {
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_APPLICATION_SUCCESS_MESSAGES, data: [] });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaDashBoard' });
  dispatch({ type: SET_ASN_DATA, data: {} });
};

export const togglechild = (currentpartNumber, ASNInfo, visible) => async dispatch => {
  if (ASNInfo && ASNInfo.parts) {
    let parts = {};
    Object.keys(ASNInfo.parts).map(partNumber => {
      let part = {};
      if (currentpartNumber === partNumber) {
        part = ASNInfo.parts[`${partNumber}`];
        part.childVisible = visible;
      } else {
        part = ASNInfo.parts[`${partNumber}`];
      }
      parts[`${partNumber}`] = part;
    });
  }
  dispatch({ type: SET_ORDER_INFO_FOR_SCAN_PAGE, data: { ...ASNInfo } });
};

export const checkSerial = (orderData, serialNumber, OrderChanges) => async dispatch => {
  let found = false;
  let isInStock = false;
  let partNumber;
  if (orderData && orderData.parts) {
    Object.keys(orderData.parts).map(partNumber => {
      if (orderData.parts[partNumber].isSerialized) {
        orderData.parts[partNumber].parts = orderData.parts[partNumber].parts.map(serial => {
          if (serial.partSerialNumber === serialNumber && serial.returnStatus !== 'Received') {
            serial.returnStatus = 'Scanned';
            found = true;
            OrderChanges.serializedPartList.push({
              serialNumber: serialNumber,
              partNumber: partNumber
            });
            orderData.parts[partNumber]['receivedQuantity'] = orderData.parts[partNumber]['receivedQuantity'] + 1;
            partNumber = partNumber;
          } else {
            isInStock = true;
            return serial;
          }
          return serial;
        });
      }
    });
  }
  dispatch({
    type: SET_ORDER_CHANGES_SCAN_PAGE,
    data: { ...OrderChanges }
  });
  return found
    ? {
        status: 'success',
        message: 'Serial Scanned',
        partNumber: partNumber
      }
    : isInStock
    ? {
        status: 'failure',
        message: 'Serial has already been received.'
      }
    : {
        status: 'failure',
        message: 'Not a Valid Serial'
      };
};

export const saveOrder = (orderChanges, orderData) => async dispatch => {
  let body = {
    ...orderChanges,
    locationNumber: orderData.locationNumber,
    trackingNumber: orderData.trackingNumber
  };

  try {
    let saveOrder = await Post(uriName, `/v1/transfergateway/order/save`, body);
    if (
      saveOrder.status === 'success' &&
      saveOrder.details &&
      saveOrder.details.serializedPartList &&
      saveOrder.details.serializedPartList.length > 0 &&
      !saveOrder.details.serializedPartList[0].errors
    ) {
      return true;
    } else if (saveOrder.status === 'success' && saveOrder.details && saveOrder.details.partList && saveOrder.details.partList.length > 0) {
      return true;
    } else if (
      saveOrder.status === 'success' &&
      saveOrder.details &&
      saveOrder.details.serializedPartList &&
      saveOrder.details.serializedPartList.length > 0 &&
      saveOrder.details.serializedPartList[0].errors
    ) {
      {
        //need to remove once we update the proper message from API
        let errMessage = saveOrder.details.serializedPartList[0].errors.message;
        dispatch({
          type: SET_APPLICATION_ERROR_MESSAGES,
          data: [errMessage[0].toUpperCase() + errMessage.slice(1)]
        });
      }
    } else if (saveOrder.message) {
      dispatch({
        type: SET_APPLICATION_ERROR_MESSAGES,
        data: [saveOrder.message]
      });
    }
  } catch (err) {
    dispatch({
      type: SET_APPLICATION_ERROR_MESSAGES,
      data: ['saving order failed']
    });
  }
  return false;
};

export const updateRecevingPage = orderData => async dispatch => {
  try {
    const response = await Post(uriName, `/v1/transfergateway/transfers`, {
      type: 'incoming',
      trackingNumber: orderData.trackingNumber
    });
    return response.AsnDetails;
  } catch (error) {
    console.log(error);
  }
};

export const goToReceiveOrderFlow = ASNInfo => async dispatch => {
  let parts = {};
  for (let sku of ASNInfo.partList) {
    if (parts[sku.partNumber]) {
      if (sku.serialized) {
        parts[sku.partNumber].parts.push({
          partNumber: sku.partNumber,
          requiredQuantity: 1,
          partSerialNumber: sku.serialNumber,
          returnStatus: sku.status === 'PENDING_RECEIVING' ? 'Pending' : 'Received',
          scanQuantity: 0,
          isSerialized: 1
        });
        if (sku.status === 'IN_STOCK') {
          parts[sku.partNumber].receivedQuantity = parts[sku.partNumber].receivedQuantity + 1;
        }
        parts[sku.partNumber].pendingReceivingQuantity = parts[sku.partNumber].pendingReceivingQuantity + 1;
      }
    } else {
      if (sku.serialized) {
        parts[sku.partNumber] = {
          receivedQuantity: sku.status === 'IN_STOCK' ? 1 : 0,
          pendingReceivingQuantity: 1,
          isSerialized: true,
          childVisible: false,
          parts: [
            {
              partNumber: sku.partNumber,
              requiredQuantity: 1,
              partSerialNumber: sku.serialNumber,
              returnStatus: sku.status === 'PENDING_RECEIVING' ? 'Pending' : 'Received',
              scanQuantity: 0,
              isSerialized: 1
            }
          ]
        };
      } else {
        parts[sku.partNumber] = {
          receivedQuantity: sku.quantityReceived,
          pendingReceivingQuantity: sku.quantityShipped,
          isSerialized: false
        };
      }
    }
  }
  ASNInfo.parts = parts;

  dispatch({ type: SET_ORDER_INFO_FOR_SCAN_PAGE, data: ASNInfo });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'receiveOrder' });
};

export const clearASNData = content => async dispatch => {
  try {
    dispatch({
      type: SET_ASN_DATA,
      data: {}
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateOrderPage = (orderData, partNumber, serialQuantity, serialized, OrderChanges) => async dispatch => {
  let newPageData = { ...orderData };
  let changedQuantity = 0;
  if (newPageData.parts[partNumber]) {
    if (serialized) {
      newPageData.parts[partNumber]['receivedQuantity'] = newPageData.parts[partNumber]['receivedQuantity'] + 1;
      newPageData.parts[partNumber].parts = newPageData.parts[partNumber].parts.map(part => {
        if (part.partSerialNumber === serialQuantity) {
          part.returnStatus = 'Scanned';
        }
        return part;
      });
    } else {
      changedQuantity = serialQuantity - newPageData.parts[partNumber]['receivedQuantity'];
      newPageData.parts[partNumber]['receivedQuantity'] = serialQuantity;
      newPageData.parts[partNumber].dirtyData = true;
    }
  }
  let partList = [];
  if (OrderChanges.partList.length > 0) {
    for (let i = 0; i < OrderChanges.partList.length; i++) {
      if (OrderChanges.partList[i].partNumber == partNumber) {
        OrderChanges.partList[i].quantityIncrement = OrderChanges.partList[i].quantityIncrement + changedQuantity;
        partList.push(OrderChanges.partList[i]);
      }
    }
  } else {
    partList.push({
      partNumber: partNumber,
      quantityIncrement: changedQuantity
    });
  }

  OrderChanges.partList = partList;
  dispatch({
    type: SET_ASN_DATA,
    data: newPageData
  });
  dispatch({
    type: SET_ORDER_CHANGES_SCAN_PAGE,
    data: { ...OrderChanges }
  });
};

export const clearOrderChanges = () => async dispatch => {
  try {
    dispatch({
      type: SET_ORDER_CHANGES_SCAN_PAGE,
      data: {
        partList: [],
        serializedPartList: []
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const notifyOrder = orderData => async dispatch => {
  let body = {
    locationNumber: orderData.locationNumber,
    trackingNumber: orderData.trackingNumber
  };

  try {
    let notifyOrder = await Post(uriName, `/v1/transfergateway/order/notify`, body);
    if (notifyOrder.status === 'success') {
      return true;
    } else if (notifyOrder.message) {
      dispatch({
        type: SET_APPLICATION_ERROR_MESSAGES,
        data: [notifyOrder.message]
      });
    }
  } catch (err) {
    dispatch({
      type: SET_APPLICATION_ERROR_MESSAGES,
      data: ['notification failed']
    });
  }
  return false;
};
