import React, { Component } from 'react';
import { CSVReader } from 'react-papaparse';
import { handleOnFileLoad } from './action';
import './RmaImportModal.scss';
import { Post, uriName } from '../../../utilities/apiUtility';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as LeftArrowLogo } from '../../../assets/arrow1-left.svg';
import { addErrorMessage, clearMessages } from '../../action';
import { goToRmaDashboard } from '../createTransferFlow/action';
import { parseJwt, getCookie } from '../../../utilities/genericUtility';
const buttonRef = React.createRef();

class RmaImportCSV extends Component {
  constructor(props) {
    super(props);
    this.handleOnFileLoad = this.handleOnFileLoad.bind(this);
  }

  handleOnFileLoad = async (data, file) => {
    this.props.clearMessages();
    let spId;
    let spLocId;
    let spInvId;
    let validatedData = true;
    let partsList = [];
    let daxData = {};
    let destinationAddress = {};

    // validating header - need to enhace by pasing transformHeaders in papaParser config
    let headerAttributes = Object.keys(data[0].data).sort();
    let expectedHeaders = [
      'ServiceBenchId',
      'Location',
      'PartNumber',
      'PartType',
      'ReturnQuantity',
      'SerialNumber',
      'ReturnReason',
      'DefectCode',
      'Notes',
      'ContactEmail',
      'ModelNumber',
      'ModelSerialNumber',
      'TransactionNumber',
      'NeedReplacementFlag'
    ].sort();
    let expectedMaHeaders = [
      'ServiceBenchId',
      'Location',
      'PartNumber',
      'PartType',
      'ReturnQuantity',
      'SerialNumber',
      'ReturnReason',
      'DefectCode',
      'Notes',
      'ContactEmail',
      'ModelNumber',
      'ModelSerialNumber',
      'TransactionNumber',
      'NeedReplacementFlag',
      'AsurionBarcode',
      'ProgramType'
    ].sort();
    if (JSON.stringify(headerAttributes) !== JSON.stringify(expectedHeaders) && JSON.stringify(headerAttributes) !== JSON.stringify(expectedMaHeaders)) {
      this.props.addErrorMessage('Invalid headers');
      return;
    }

    //filter out empty rows from object to avoid unneccessary
    //'Part number is empty in row x' errors as this is frequent issue
    data = data.filter(d => {
      if (Object.keys(d.data).length === 1) {
        return false;
      }
      return true;
    });

    let dataValidationError = false;
    //check if part number, location present in each row of data
    data.forEach((d, i) => {
      if (!d.data.PartNumber) {
        this.props.addErrorMessage(`Part Number is empty in row ${i + 1}`);
        dataValidationError = true;
      }
      if (!d.data.Location) {
        this.props.addErrorMessage(`Location is empty in row ${i + 1}`);
        dataValidationError = true;
      }
    });
    if (dataValidationError) return;

    let isSPUser = false;
    const userDetails = this.props.userSession.userDetails;
    let companyId = userDetails.owningCompany.companyNumber;
    if (companyId.toUpperCase() !== 'NEW') {
      isSPUser = true;
    }
    //MA program type validation
    const isMaExport = headerAttributes.indexOf('AsurionBarcode') !== -1 && headerAttributes.indexOf('ProgramType') !== -1;

    if (isMaExport && !this.validMaCsv(data)) {
      return;
    }

    /// validating parts without serailnumbers -> should not have duplicate entries per location
    let partsWithOutSerilasArray = [];
    await data.filter(x => {
      if (!x.data.SerialNumber) {
        partsWithOutSerilasArray.push(x.data);
      }
    });

    //grouping based on location
    const groupedByLocation = await partsWithOutSerilasArray.reduce(function (r, a) {
      r[a.Location] = r[a.Location] || [];
      r[a.Location].push(a);
      return r;
    }, Object.create(null));

    const groupedByLocationArray = Object.values(groupedByLocation).reverse();

    for (let x of groupedByLocationArray) {
      let arr1 = [];
      for (let a of x) {
        arr1.push(a.PartNumber);
      }
      let partnumber = arr1.filter((e, i, a) => a.indexOf(e) !== i);
      if (partnumber.length > 0) {
        this.props.addErrorMessage(`Duplicate Part Number ${partnumber[0]} . Please correct and re-upload.`);
        return;
      }
    }

    // obtain desination address and also validating for dax365 or 2009 end point
    try {
      let partList = [];
      data.forEach(d => {
        let partData = {
          partNumber: d.data.PartNumber,
          locationNumber: d.data.Location
        };
        partList.push(partData);
      });
      let rmaDaxInfo = await Post(uriName, `/v1/transfergateway/rma/getDaxInfo`, {
        partList: partList
      });

      destinationAddress.name = rmaDaxInfo.name;
      destinationAddress.addressLine1 = rmaDaxInfo.addressLine1;
      destinationAddress.addressLine2 = rmaDaxInfo.addressLine2;
      destinationAddress.addressLine3 = rmaDaxInfo.addressLine3;
      destinationAddress.addressCity = rmaDaxInfo.addressCity;
      destinationAddress.addressState = rmaDaxInfo.addressState;
      destinationAddress.addressZip = rmaDaxInfo.addressZip;
      destinationAddress.addressCountry = rmaDaxInfo.addressCountry;

      if (rmaDaxInfo && rmaDaxInfo.count === data.length) {
        // 365 parts
        daxData = 'true';
      } else if (rmaDaxInfo.count === 0) {
        // 2009 parts
        daxData = 'false';
      } else {
        this.props.addErrorMessage('Unable to import across multiple programs in same file');
        return;
      }
    } catch (err) {
      this.props.addErrorMessage('Something went wrong when getting DAX information details. Please try again.');
    }

    // for validating resaon and defect codes
    const reasonCodes = ['CRE', 'DEF', 'DNO', 'DOA', 'GDU', 'TMS', 'UUU', 'WPO', 'WPS'];
    const defectCodes = ['CIS', 'CPR', 'IPD', 'IPI', 'NPD', 'NPW', 'PDG', 'PDI', 'SPR', 'VHP', 'WCR', 'ASD'];

    for (let i = 0; i < data.length; i++) {
      let obj = data[i].data;
      // validating input ReturnReason and DefectCodes
      if (!reasonCodes.includes(obj.ReturnReason)) {
        this.props.addErrorMessage(`Invalid Reason Code ${obj.ReturnReason}`);
        return;
      }
      if (!defectCodes.includes(obj.DefectCode)) {
        this.props.addErrorMessage(`Invalid Defect Code ${obj.DefectCode}`);
        return;
      }

      /* Validate SP Account Number */
      let rmaSPInfo = await Post(uriName, `/v1/transfergateway/rma/rmaSPInfo`, {
        spAccountNumber: obj.ServiceBenchId
      });
      if (isSPUser === true) {
        if (rmaSPInfo.serviceProviderId !== companyId) {
          this.props.addErrorMessage('Invalid data: SP user may not create an RMA for another SP user');
          return;
        }
      }
      if (rmaSPInfo.serviceProviderId === 'NOT_FOUND') {
        this.props.addErrorMessage('Incorrect SP Account Number: ' + obj.ServiceBenchId + '.  Please Correct the File Data and try again');
        return;
      } else {
        //Validate SP Part Location Number
        spId = rmaSPInfo.serviceProviderId;
        data[i].data.spId = spId;
        data[i].data.spName = rmaSPInfo.serviceProviderName;

        let rmaSpLocInfo = await Post(uriName, `/v1/transfergateway/rma/rmaLocationInfo`, {
          partLocationNumber: obj.Location,
          serviceProviderId: spId
        });

        if (rmaSpLocInfo.serviceProviderLocationId === 'NOT_FOUND') {
          this.props.addErrorMessage('Incorrect Location: ' + obj.Location + '.  Please Correct the File Data and try again');
          return;
        } else {
          //Validate SP Part Number/Inventory
          spLocId = rmaSpLocInfo.serviceProviderLocationId;
          data[i].data.spLocId = spLocId;
          data[i].data.spLocationName = rmaSpLocInfo.spLocationName;

          let rmaSpInvInfo = await Post(uriName, `/v1/transfergateway/rma/rmaInventoryInfo`, {
            serviceProviderId: spId,
            serviceProviderLocationId: spLocId,
            partNumber: obj.PartNumber
          });

          if (rmaSpInvInfo.spInventoryId === 'NOT_FOUND') {
            this.props.addErrorMessage(
              'Part: ' + obj.PartNumber + ' does not exist at location: ' + obj.Location + ' .  Please Correct the File Data and try again'
            );
            return;
          } else {
            //Validate is successful. Take User to Rma Creation UI
            spInvId = rmaSpInvInfo.spInventoryId;
            data[i].data.spInvId = spInvId;
            data[i].data.searializedPart = rmaSpInvInfo.searlizedPart;

            if (data[i].data.ReturnQuantity && isNaN(data[i].data.ReturnQuantity)) {
              this.props.addErrorMessage(`Row:${i + 1} Return Quantity should be Number`);
              return;
            }
            if (data[i].data.searializedPart === '1' && data[i].data.SerialNumber) {
              if (data[i].data.ReturnQuantity && data[i].data.ReturnQuantity !== '1') {
                this.props.addErrorMessage(`Row:${i + 1} Return Quantity should be 1 or blank`);
                return;
              }
              data[i].data.ReturnQuantity = '1';
            } else {
              if (data[i].data.searializedPart !== '1' && data[i].data.SerialNumber) {
                this.props.addErrorMessage(`Row:${i + 1} ${data[i].data.PartNumber} cannot have Serial Number`);
                return;
              }
              if (!data[i].data.ReturnQuantity) {
                this.props.addErrorMessage(`Row:${i + 1} Quantity cannot be empty `);
                return;
              }
              if (Number(data[i].data.ReturnQuantity) < 1) {
                this.props.addErrorMessage(`Row:${i + 1} Return Quantity cannot be negative or Zero`);
                return;
              }
            }
            if (obj.SerialNumber) {
              let rmaSerialInfo = await Post(uriName, `/v1/transfergateway/rma/validateRmaSerial`, {
                serialNumber: obj.SerialNumber,
                partNumber: obj.PartNumber,
                serviceProviderId: spId,
                serviceProviderLocationId: spLocId
              });
              if (rmaSerialInfo.serialId === 'NOT_FOUND') {
                this.props.addErrorMessage('Invalid Serial Number :' + obj.SerialNumber);
                return;
              }
              if (rmaSerialInfo.partStatus && rmaSerialInfo.partStatus.toUpperCase() === 'INSALLED') {
                this.props.addErrorMessage(`Serial ${data[i].data.SerialNumber} installed in service job`);
                return;
              }
            }
            if (obj.AsurionBarcode && !(await this.validAbc(obj, i, spId, spLocId))) {
              return;
            }
          }
        }
      }
    }

    let filteredDataArray = [];
    const filteredData = await data.filter(x => filteredDataArray.push(x.data));
    //grouping based on location
    const groupedByLocationSummary = await filteredDataArray.reduce(function (r, a) {
      r[a.Location] = r[a.Location] || [];
      r[a.Location].push(a);
      return r;
    }, Object.create(null));

    const groupedByLocationSummaryArray = Object.values(groupedByLocationSummary).reverse();
    this.props.handleOnFileLoad(data, file, daxData, destinationAddress, groupedByLocationSummaryArray);
  };

  validMaCsv = data => {
    // Check if the all the program type code is MA for the csv
    for (const [index, row] of data.entries()) {
      if (row.data.ProgramType !== 'MA') {
        this.props.addErrorMessage(`Invalid MA CSV import. Make sure the Program type is MA on all rows.`);
        return false;
      }
      if (!row.data.Location) {
        this.props.addErrorMessage(`Location is undefined at row ${index + 1}. Please correct the file`);
        return false;
      }

      if (!row.data.ServiceBenchId) {
        this.props.addErrorMessage(`ServiceBenchId field is undefined at row ${index + 1}. Please correct the file`);
        return false;
      }
    }
    return true;
  };

  validAbc = async (obj, row, spId, spLocId) => {
    let abcValidationResponse = await Post(uriName, `/v1/transfergateway/rma/validateRmaABC`, {
      asurionBarcode: obj.AsurionBarcode,
      serviceProviderId: spId,
      serviceProviderLocationId: spLocId,
      partNumber: obj.PartNumber
    });
    if (
      !abcValidationResponse.status ||
      abcValidationResponse.status !== 'SUCCESS' ||
      (abcValidationResponse.abcDetails && abcValidationResponse.abcDetails.PART_NUMBER_ORDERED !== obj.partNumber)
    ) {
      this.props.addErrorMessage(`Invalid ABC Barcode : ${obj.AsurionBarcode} on row : ${row + 1}`);
      return false;
    }
    return true;
  };

  handleOpenDialog = e => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnError = (err, file, inputElem, reason) => {};

  handleOnRemoveFile = data => {};

  handleRemoveFile = e => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  render() {
    return (
      <div>
        <a className='ScanNavLabel' href='#' onClick={this.props.goToRmaDashboard}>
          <LeftArrowLogo className='scanLeftArraow' />
          Return to RMA Dashboard
        </a>
        <br />
        <h1>Create new RMA</h1> <br />
        <p>Upload File</p>
        <CSVReader
          ref={buttonRef}
          onFileLoad={this.handleOnFileLoad}
          onError={this.handleOnError}
          noClick
          noDrag
          onRemoveFile={this.handleOnRemoveFile}
          config={{
            header: true
          }}
        >
          {({ file }) => (
            <aside
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10
              }}
            >
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#ccc',
                  height: 45,
                  lineHeight: 2.5,
                  marginTop: 5,
                  marginBottom: 5,
                  paddingLeft: 13,
                  paddingTop: 3,
                  width: '30%'
                }}
              >
                {file && file.name}
              </div>
              <button type='button' onClick={this.handleOpenDialog} className='createRMAinImportButton'>
                Choose File
              </button>
            </aside>
          )}
        </CSVReader>
        <p>please upload .csv file only</p>
      </div>
    );
  }
}

const mapStateToProps = ({ rmaImportReducer }) => {
  const { summaryData } = rmaImportReducer;
  return {
    summaryData
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleOnFileLoad,
      addErrorMessage,
      clearMessages,
      goToRmaDashboard
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RmaImportCSV);
