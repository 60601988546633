import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MessageModal from '../../common/MessageModal';
import ErrorBoundary from '../../common/ErrorBoundary';
import { ReactComponent as LeftArrowLogo } from '../../../assets/arrow1-left.svg';
import { ReactComponent as Expand } from '../../../assets/chevron-down.svg';
import { ReactComponent as Collapse } from '../../../assets/chevron-up.svg';
import { ReactComponent as EditPencil } from '../../../assets/pencil-1.svg';
import BarcodeReader from 'react-barcode-reader';
import { enableSpinner, disableSpinner } from '../../action';
import {
  togglechild,
  checkSerial,
  updateOrderPage,
  saveOrder,
  updateRecevingPage,
  goToReceiveOrderFlow,
  clearOrderChanges,
  goToRmaDashboard,
  notifyOrder,
} from './action';
import '../scanningFlow/ScanSerial.scss';
import './ReceiveOrder.scss';

class ReceiveOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      message: '',
      borderColor: '#377a80',
      afterSaveModalVisible: false,
      descrepanciesModalVisible: false,
      closeSuccessModalVisible: false,
      quantityModalVisible: false,
      currentPartNumber: '',
      pendingReceivingQuantity: 0,
      receivedQuantity: 0,
      serial: '',
      dirtyData: false,
      currentParts: '',
    };
    this.onScan = this.onScan.bind(this);
    this.modalOnOff = this.modalOnOff.bind(this);
    this.updatePartQuantity = this.updatePartQuantity.bind(this);
    this.setQuantity = this.setQuantity.bind(this);
  }

  async onScan(serial) {
    let addresponse = await this.props.checkSerial(
      this.props.OrderData,
      serial,
      this.props.OrderChanges
    );
    this.setState({ serial: serial });
    if (addresponse && addresponse.status === 'failure') {
      this.setState({
        showError: true,
        borderColor: '#db1b1b',
        message: addresponse.message,
      });
    } else {
      this.props.enableSpinner();
      let saveOrder = await this.props.saveOrder(
        this.props.OrderChanges,
        this.props.OrderData
      );
      if (saveOrder) {
        let asnInfo = await this.props.updateRecevingPage(this.props.OrderData);
        await this.props.goToReceiveOrderFlow(asnInfo);
        await this.props.clearOrderChanges();
      }
      this.props.disableSpinner();
    }
    document.getElementById('scanInputBox').value = '';
    this.setState({ serial: '' });
  }

  modalOnOff(bool, partNumber, pendingReceivingQuantity) {
    if (bool) {
      this.setState(
        { currentPartNumber: partNumber, pendingReceivingQuantity },
        () => this.setState({ quantityModalVisible: bool })
      );
    } else {
      this.setState({ quantityModalVisible: bool });
    }
  }

  async updatePartQuantity() {
    await this.props.updateOrderPage(
      this.props.OrderData,
      this.state.currentPartNumber,
      this.state.receivedQuantity,
      false,
      this.props.OrderChanges
    );
    this.modalOnOff(false);
    this.props.enableSpinner();
    let saveOrder = await this.props.saveOrder(
      this.props.OrderChanges,
      this.props.OrderData
    );
    if (saveOrder) {
      let asnInfo = await this.props.updateRecevingPage(this.props.OrderData);
      await this.props.goToReceiveOrderFlow(asnInfo);
      await this.props.clearOrderChanges();
    }
    this.props.disableSpinner();
  }
  setQuantity(quantity) {
    this.setState({ receivedQuantity: quantity });
  }

  render() {
    return (
      <div>
        <div className="ScanNavLabel">
          {this.state.quantityModalVisible ? (
            <MessageModal
              id="quantityModal"
              header="Enter quantity"
              content={
                <div>
                  <input
                    type="number"
                    min="0"
                    className="Modal-input"
                    onChange={async (event) => {
                      if (event.target.value!=="" && event.target.value <= this.state.currentParts) {
                        alert(
                          'Value should be greater than the current value'
                        );
                        return;
                      }else{
                        if(event.target.value!==""){
                          this.setQuantity(parseInt(event.target.value));
                        }else{
                           return;
                        }
                      
                        
                      }
                      
                    }}
                  />
                  <p style={{ textAlign: 'left' }}>
                    Sent:{this.state.pendingReceivingQuantity}
                  </p>
                </div>
              }
              cancel={() => this.modalOnOff(false)}
              continue={() => {
                if (this.state.receivedQuantity > this.state.currentParts) {
                  this.updatePartQuantity();
                }
              }}
            />
          ) : (
            ''
          )}
          <LeftArrowLogo className="scanLeftArraow" />
          <a
            className="ScanNavLabel"
            href="#"
            onClick={this.props.goToRmaDashboard}
          >
            Return to Transfer Dashboard
          </a>
        </div>
        <div className="rmaNumberLabel">
          Order #{this.props.OrderData.purchaseOrderNumber}
        </div>
        <div className="ScanContent">
          <div className="scanParentDiv">
            <p className="title"> Scan serial number</p>
            <br />
            <br />
            <input
              id="scanInputBox"
              style={{ borderColor: this.state.borderColor }}
              value={this.state.serial}
              className="scanbox"
              onChange={(event) => {
                this.setState({
                  showError: false,
                  borderColor: '#377a80',
                  serial: event.target.value,
                });
              }}
              onKeyDown={(event) => {
                if (event.keyCode === 13 && event.target.value !== '') {
                  this.onScan(event.target.value);
                }
              }}
            ></input>
            <div>
              <BarcodeReader onScan={this.onScan} />
            </div>
            {this.state.showError ? (
              <div className="scanMessage">
                <p>{this.state.message}</p>
              </div>
            ) : (
              ''
            )}

            <div className="fromToInfoDiv">
              <p className="labeltext">Shipping Origin</p>
              <p className="locationNameText">
                <ErrorBoundary>
                  {this.props.OrderData.shipFromAddress.name}
                </ErrorBoundary>
              </p>

              <p className="labeltext">Shipping Destination</p>
              <p className="locationNameText">
                {this.props.OrderData.shipToAddress.name}
              </p>
            </div>
            {this.props.OrderData.sendMessage ? (
              <button
                className={
                  this.props.OrderData.pendingChanges
                    ? 'receiveOrderbuttonEnabled'
                    : 'receiveOrderbuttonDisabled'
                }
                disabled={this.props.OrderData.pendingChanges ? false : true}
                onClick={async () => {
                  this.props.enableSpinner();
                  await this.props.notifyOrder(this.props.OrderData);
                  let asnInfo = await this.props.updateRecevingPage(
                    this.props.OrderData
                  );
                  await this.props.goToReceiveOrderFlow(asnInfo);
                  this.props.disableSpinner();
                }}
              >
                <p className="buttonText">Save Order</p>
              </button>
            ) : (
              ''
            )}
          </div>
          <div className="partsDiv">
            <p className="title">Received Parts</p>
            <div className="partsContainer">
              {Object.keys(this.props.OrderData.parts).map((partNumber) => {
                let backgroundcolor =
                  this.props.OrderData.parts[`${partNumber}`]
                    .pendingReceivingQuantity <
                  this.props.OrderData.parts[`${partNumber}`].receivedQuantity
                    ? '#F9D51C'
                    : this.props.OrderData.parts[`${partNumber}`]
                        .pendingReceivingQuantity >
                      this.props.OrderData.parts[`${partNumber}`]
                        .receivedQuantity
                    ? '#FFFFFF'
                    : '#F0FFF9';
                return (
                  <div className="partContainer">
                    <div
                      className="partInfo"
                      style={{ backgroundColor: backgroundcolor }}
                    >
                      {partNumber}
                      {this.props.OrderData.parts[`${partNumber}`]
                        .isSerialized ? (
                        <div style={{ float: 'right' }}>
                          {!this.props.OrderData.parts[`${partNumber}`]
                            .childVisible ? (
                            <Expand
                              onClick={() =>
                                this.props.togglechild(
                                  partNumber,
                                  this.props.OrderData,
                                  true
                                )
                              }
                            />
                          ) : (
                            <Collapse
                              onClick={() =>
                                this.props.togglechild(
                                  partNumber,
                                  this.props.OrderData,
                                  false
                                )
                              }
                            />
                          )}
                        </div>
                      ) : (
                        <div style={{ float: 'right' }}>
                          {this.props.OrderData.orderStatusCode !== 'REC' ? (
                            <EditPencil
                              onClick={() => {
                                this.setState({
                                  currentParts:
                                    this.props.OrderData.parts[`${partNumber}`]
                                      .receivedQuantity,
                                });
                                this.modalOnOff(
                                  true,
                                  partNumber,
                                  this.props.OrderData.parts[`${partNumber}`]
                                    .pendingReceivingQuantity
                                );
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                      <div style={{ float: 'right', marginRight: '5px' }}>
                        {
                          this.props.OrderData.parts[`${partNumber}`]
                            .receivedQuantity
                        }
                        /
                        {
                          this.props.OrderData.parts[`${partNumber}`]
                            .pendingReceivingQuantity
                        }
                      </div>
                    </div>
                    {this.props.OrderData.parts[`${partNumber}`]
                      .childVisible ? (
                      <div id={partNumber} className="rmascanchildtablediv">
                        <table className="rmascanchildtable">
                          {this.props.OrderData.parts[
                            `${partNumber}`
                          ].parts.map((part) => {
                            let statusbackgroundcolor =
                              part.returnStatus === 'Received'
                                ? '#37E7A7'
                                : '#000000';
                            let statuscolor =
                              part.returnStatus === 'Received'
                                ? '#000000'
                                : '#ffffff';
                            return (
                              <tr className="serialRow">
                                <td>
                                  <div className="serial">
                                    {part.partSerialNumber}
                                  </div>
                                </td>
                                <td>
                                  <div className="status">
                                    <p
                                      style={{
                                        background: statusbackgroundcolor,
                                        color: statuscolor,
                                        display: 'inline-block',
                                      }}
                                    >
                                      {part.returnStatus}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ receiveOrderReducer }) => {
  const { OrderData, OrderChanges } = receiveOrderReducer;
  return { OrderData, OrderChanges };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToRmaDashboard,
      enableSpinner,
      disableSpinner,
      togglechild,
      updateOrderPage,
      checkSerial,
      saveOrder,
      updateRecevingPage,
      goToReceiveOrderFlow,
      clearOrderChanges,
      notifyOrder,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveOrder);
