import { Post, uriName, userInfo } from '../../../utilities/apiUtility';
import {
  SET_RETURNS_NAVIGATION,
  SET_APPLICATION_ERROR_MESSAGES,
  SET_APPLICATION_SUCCESS_MESSAGES,
  SET_RMA_INFO_FOR_DYNAMIC_SCAN_PAGE,
  SET_RMA_SCAN_SUCCESSFUL,
  ADD_APPLICATION_SUCCESS_MESSAGE,
  ADD_APPLICATION_ERROR_MESSAGE,
  DISABLE_APPLICATION_SPINNER,
  ENABLE_APPLICATION_SPINNER
} from '../../../actionTypes';

export const goToRmaDashboard = () => async dispatch => {
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_APPLICATION_SUCCESS_MESSAGES, data: [] });
  dispatch({ type: SET_RMA_INFO_FOR_DYNAMIC_SCAN_PAGE, data: null });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaDashBoard' });
};

export const handleDynamicScan = (pageData, scannedPart, rmaInfo, locationIdOrNumber) => async dispatch => {
  try {
    dispatch({ type: ENABLE_APPLICATION_SPINNER, data: true });
    const { externalReferenceNumber, serviceProviderLocationId, createWithDuplicateRefNumber, programType } = pageData;
    const scannedPartDestination = await getPartShippingProgram(programType, scannedPart);
    if (!scannedPartDestination) {
      dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Could not determine shipping destination for ${scannedPart}. Please try again.` });
      return;
    }
    if (!rmaInfo || !rmaInfo.length > 0) {
      const createRmaResponse = await createRma(
        'returns',
        serviceProviderLocationId,
        scannedPartDestination,
        programType,
        externalReferenceNumber,
        createWithDuplicateRefNumber,
        dispatch
      );
      if (createRmaResponse && createRmaResponse.rmaNumber) {
        await addSerial(createRmaResponse.rmaNumber, locationIdOrNumber, scannedPart, dispatch);
        await updateRmaInfo([createRmaResponse.rmaNumber], dispatch);
      } else {
        dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Error creating new RMA for destination: ${scannedPartDestination.name}. Please try again.` });
        return;
      }
    } else {
      let rmaArray = [];
      let partAdded = false;
      for (const rma of rmaInfo) {
        rmaArray.push(rma.rmaInfo.rmaNumber);
        if (rma.to.locationName === scannedPartDestination.name) {
          await addSerial(rma.rmaInfo.rmaNumber, locationIdOrNumber, scannedPart, dispatch);
          partAdded = true;
        }
      }
      if (!partAdded) {
        const createRmaResponse = await createRma(
          'returns',
          serviceProviderLocationId,
          scannedPartDestination,
          programType,
          externalReferenceNumber,
          createWithDuplicateRefNumber,
          dispatch
        );
        if (createRmaResponse && createRmaResponse.rmaNumber) {
          await addSerial(createRmaResponse.rmaNumber, locationIdOrNumber, scannedPart, dispatch);
          rmaArray = [createRmaResponse.rmaNumber, ...rmaArray];
        } else {
          dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Error creating new RMA for destination: ${scannedPartDestination.name}. Please try again.` });
          return;
        }
      }
      await updateRmaInfo(rmaArray, dispatch);
      return;
    }
  } catch (err) {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Something went wrong scanning part ${scannedPart}. Please try again.` });
    return;
  } finally {
    dispatch({ type: DISABLE_APPLICATION_SPINNER, data: false });
  }
};

export const getPartShippingProgram = async (program, scannedPart) => {
  try {
    const response = await Post(uriName, `/v1/transfergateway/destinationLocations`, {
      program,
      typeOfTransfer: 'returns',
      serviceProviderId: userInfo.companyId,
      partOrSerialNumber: scannedPart
    });
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const handleSerialDeletion = (rmaNumber, serialNumber, rmaInfo) => async dispatch => {
  try {
    dispatch({ type: ENABLE_APPLICATION_SPINNER, data: true });
    const serialDeleted = await deleteSerial(rmaNumber, serialNumber, dispatch);
    if (serialDeleted) {
      const rmaArray = getRmaArray(rmaInfo);
      await updateRmaInfo(rmaArray, dispatch);
    }
  } catch (err) {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Error occurred deleting serial ${serialNumber} from ${rmaNumber}. Please try again.` });
  } finally {
    dispatch({ type: DISABLE_APPLICATION_SPINNER, data: false });
  }
};

export const handleSerialQuantityUpdate = (modalData, rmaInfo) => async dispatch => {
  try {
    dispatch({ type: ENABLE_APPLICATION_SPINNER, data: true });
    const { rmaNumber, partNumber, partStatus, partQuantity } = modalData;
    const quantityUpdated = await updateQuantity(rmaNumber, partNumber, partStatus, partQuantity, dispatch);
    if (quantityUpdated) {
      const rmaArray = getRmaArray(rmaInfo);
      await updateRmaInfo(rmaArray, dispatch);
    }
  } catch (err) {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Error occurred updating quantity for non serialized part ${partNumber}. Please try again.` });
  } finally {
    dispatch({ type: DISABLE_APPLICATION_SPINNER, data: false });
  }
};

export const handleInitialRmaLoad = rmaArray => async dispatch => {
  try {
    dispatch({ type: ENABLE_APPLICATION_SPINNER, data: true });
    await updateRmaInfo(rmaArray, dispatch);
  } catch (err) {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Could not load information for ${rmaArray[0]}. Please try again.` });
  } finally {
    dispatch({ type: DISABLE_APPLICATION_SPINNER, data: false });
  }
};

export const createRma = async (
  typeOfTransfer,
  serviceProviderLocationId,
  destinationAddress,
  programType,
  externalReferenceNumber,
  createWithDuplicateRefNumber,
  dispatch
) => {
  try {
    let content = {
      typeOfTransfer,
      serviceProviderId: userInfo.companyId,
      serviceProviderLocationId,
      destinationAddress,
      programType
    };
    if (externalReferenceNumber) {
      content.externalReferenceNumber = externalReferenceNumber;
      content.createWithDuplicateRefNumber = createWithDuplicateRefNumber ? createWithDuplicateRefNumber : false;
    }
    const response = await Post(uriName, `/v1/transfergateway/rma/createTransfer`, content);
    if (response && response.rmaNumber) {
      dispatch({
        type: ADD_APPLICATION_SUCCESS_MESSAGE,
        data: `Successfully created new RMA: ${response.rmaNumber} for destination ${destinationAddress.name}.`
      });
    } else {
      dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Error creating new RMA for destination: ${destinationAddress.name}.` });
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateRmaInfo = async (rmaArray, dispatch) => {
  try {
    const rmaInfo = await Post(uriName, `/v1/transfergateway/rma/rmaInfo`, { rmaArray });
    if (rmaInfo) {
      dispatch({ type: SET_RMA_INFO_FOR_DYNAMIC_SCAN_PAGE, data: rmaInfo });
    }
  } catch (err) {
    console.log(err);
  }
};

export const addSerial = async (rmaNumber, locationIdOrNumber, serialNumber, dispatch) => {
  try {
    const response = await Post(uriName, `/v1/transfergateway/rma/part`, {
      action: 'add',
      transferNumber: rmaNumber,
      locationIdOrNumber,
      serialNumber
    });
    if (response && response.status.toUpperCase() === 'SUCCESS') {
      dispatch({ type: ADD_APPLICATION_SUCCESS_MESSAGE, data: `Successfully added ${serialNumber} to ${rmaNumber}.` });
    } else {
      dispatch({
        type: ADD_APPLICATION_ERROR_MESSAGE,
        data: `Error adding ${serialNumber} to ${rmaNumber}. ${response.message ? response.message : 'Please try again.'}`
      });
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const deleteSerial = async (rmaNumber, serialNumber, dispatch) => {
  try {
    const response = await Post(uriName, `/v1/transfergateway/rma/part`, {
      action: 'delete',
      transferNumber: rmaNumber,
      serialNumber
    });
    if (response && response.status.toUpperCase() === 'SUCCESS') {
      dispatch({ type: ADD_APPLICATION_SUCCESS_MESSAGE, data: `Successfully deleted ${serialNumber} from ${rmaNumber}.` });
    } else {
      dispatch({
        type: ADD_APPLICATION_ERROR_MESSAGE,
        data: `Error deleting ${serialNumber} from ${rmaNumber}. ${response.message ? response.message : 'Please try again.'}`
      });
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateQuantity = async (rmaNumber, partNumber, statusCode, quantity, dispatch) => {
  try {
    const response = await Post(uriName, `/v1/transfergateway/rma/part`, {
      action: 'update',
      transferNumber: rmaNumber,
      partNumber,
      statusQuantities: [
        {
          statusCode,
          quantity
        }
      ]
    });
    if (response && response.status.toUpperCase() === 'SUCCESS') {
      dispatch({ type: ADD_APPLICATION_SUCCESS_MESSAGE, data: `Successfully updated quantity of ${partNumber} for ${rmaNumber}.` });
    } else {
      dispatch({
        type: ADD_APPLICATION_ERROR_MESSAGE,
        data: `Error updating quantity of ${partNumber} for ${rmaNumber}. ${response.message ? response.message : 'Please try again.'}`
      });
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const generatePackingList = (rmaInfo, partLocationId) => async dispatch => {
  try {
    dispatch({ type: ENABLE_APPLICATION_SPINNER, data: true });
    let response;
    const rmaArray = getRmaSubmitArray(rmaInfo);
    if (rmaArray && rmaArray.length > 0) {
      response = await Post(uriName, `/v1/transfergateway/rma/update`, {
        action: 'generatePackingList',
        rmaArray,
        partLocationId
      });
      if (response && response.status.toUpperCase() === 'SUCCESS') {
        if (response.successMessages) {
          for (const message of response.successMessages) {
            dispatch({ type: ADD_APPLICATION_SUCCESS_MESSAGE, data: message });
          }
        }
        dispatch({ type: SET_RMA_SCAN_SUCCESSFUL, data: true });
        return;
      } else {
        if (response.failureMessages) {
          for (const message of response.failureMessages) {
            dispatch({ type: ADD_APPLICATION_SUCCESS_MESSAGE, data: message });
          }
          return;
        }
      }
    } else {
      dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `No valid RMA to submit. RMA must have minimum one part with one scanned quantity` });
      return;
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({ type: DISABLE_APPLICATION_SPINNER, data: false });
  }
};

export const getRmaArray = rmaInfo => {
  let rmaArray = [];
  for (const rma of rmaInfo) {
    rmaArray.push(rma.rmaInfo.rmaNumber);
  }
  return rmaArray;
};

export const getRmaSubmitArray = rmaInfo => {
  let rmaArray = [];
  for (const rma of rmaInfo) {
    if (rma.parts && rma.parts.length > 0) {
      let hasPartWithValidQuantity = false;
      for (const part of rma.parts) {
        if (Number(part.isSerialized) === 1) hasPartWithValidQuantity = true;
        if (Number(part.isSerialized) === 0) {
          if (Number(part.scanQuantity) > 0) hasPartWithValidQuantity = true;
        }
      }
      if (hasPartWithValidQuantity) {
        rmaArray.push(rma.rmaInfo.rmaNumber);
      }
    }
  }
  return rmaArray;
};
