import { Post, uriName } from '../../../utilities/apiUtility';
import {
  SET_RMA_INFO_SUMMARY_PAGE,
  SET_UPLOAD_FILE_NAME,
  SET_DAX_365,
  SET_DESTINATION_ADDRESS,
  SET_RMA_MANUAL_IMPORT_SP_INFO,
  SET_SP_LOCATION_LIST_MANUAL_IMPORT,
  SET_UPLOAD_SUMMARY_DATA
} from '../../../actionTypes';
import { goToRmaSummary } from '../RmaDashboard/action';
export const serviceNetwork = 'sa-spsearchapi';

export const handleOnFileLoad = (data, file, daxData, destinationAddress, groupedByLocationArray) => async dispatch => {
  data = await data.map(row => row.data);

  dispatch({
    type: SET_RMA_INFO_SUMMARY_PAGE,
    data: data
  });
  dispatch({
    type: SET_UPLOAD_FILE_NAME,
    data: file.name
  });
  dispatch({
    type: SET_DAX_365,
    data: daxData
  });
  dispatch({
    type: SET_DESTINATION_ADDRESS,
    data: destinationAddress
  });
  dispatch({
    type: SET_UPLOAD_SUMMARY_DATA,
    data: groupedByLocationArray
  });
  goToRmaSummary()(dispatch);
};

export const handleOnManualEntry = (data, daxData, destinationAddress) => async dispatch => {
  dispatch({
    type: SET_RMA_INFO_SUMMARY_PAGE,
    data: data
  });
  dispatch({
    type: SET_DAX_365,
    data: daxData
  });
  dispatch({
    type: SET_DESTINATION_ADDRESS,
    data: destinationAddress
  });
};
export const searchServiceProviders = value => async dispatch => {
  try {
    if (value.length > 3) {
      const response = await Post(serviceNetwork, `/v1/spsearch`, {
        providerId: value,
        providerName: value
      });
      if (response.providers) {
        response.providers = response.providers.map(item => {
          return {
            ...item,
            label: item.providerName,
            value: item.providerId
          };
        });
        dispatch({
          type: SET_RMA_MANUAL_IMPORT_SP_INFO,
          data: response.providers
        });
      }
    }
  } catch (err) {}
};
export const getspLocInfo = content => async dispatch => {
  try {
    const response = await Post(uriName, `/v1/transfergateway/spLocationInfo`, content);
    if (response.locations) {
      response.locations = response.locations.map(item => {
        return {
          ...item,
          label: item.description,
          value: item.serviceProviderLocationId
        };
      });
      response.locations.unshift({ serviceProviderLocationId: 'SELECT', description: 'SELECT', spPartLocationId: 'SELECT', label: 'SELECT', value: 'SELECT' });
      dispatch({
        type: SET_SP_LOCATION_LIST_MANUAL_IMPORT,
        data: response.locations
      });
    }
  } catch (error) {}
};
