import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../RmaDashboard.scss';
import './ASNDetailsModal.scss';

import { clearASNData, goToReceiveOrderFlow } from '../../orderFlow/action';

class ASNDetailsModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      searchBoxType: 'transferNumber',
    };
  }

  getStatus = (value) => {
    switch (value) {
      case 'BO':
        return 'Back Ordered';
      case 'PB':
        return 'Pending Buyout';
      case 'PC':
        return 'Pending PD Cancel';
      case 'CA':
        return 'Cancelled';
      case 'CO':
        return 'Co-Return';
      case 'OR':
        return 'Ordered';
      case 'RE':
        return 'Return';
      case 'PS':
        return 'Partially Shipped';
      case 'SH':
        return 'Shipped';
      case 'OH':
        return 'On Hold';
      case 'AC':
        return 'Accepted';
      case 'PA':
        return 'Pending Authorization';
      case 'PQ':
        return 'Pending Quantity Limits';
      case 'PF':
        return 'Pending Failed Order';
      case 'PR':
        return 'Pending Re-Query';
      case 'RI':
        return 'Pending-RestrictedItems';
      case 'MO':
        return 'Pending-ManualOrder';
      case 'IP':
        return 'In Progress';
      case 'ECR':
        return 'Expected Core return';
      case 'EUR':
        return 'Expected Unused Return';
      case 'ST':
        return 'Use Stock';
      case 'PAP':
        return 'Payment Authorization Pending (default  initial status for COD orders including LOC)';
      case 'PAF':
        return 'Payment Authorization Failed (Response from Billing Gateway when indicates  that authorization could not be completed)';
      case 'PRE':
        return 'Partially Received';
      case 'REC':
        return 'Received';
      default:
        return value;
    }
  };
  render() {
    return (
      <div id="messageModal" className="MessageModal">
        <div
          className={
            this.props.bodyStyle ? `${this.props.bodyStyle}` : 'modal-body'
          }
        >
          <div id="Modal-header" className="Modal-header">
            <p className="ModalTitle">Order Details</p>
          </div>
          <div
            id="Modal-content"
            className={
              this.props.contentStyle
                ? `${this.props.contentStyle}`
                : 'Modal-content'
            }
          >
            <table>
              <tr>
                <td>Order Number:</td>
                <td>{this.props.ASNData.purchaseOrderNumber}</td>
              </tr>
              <tr>
                <td>Order Status :</td>
                <td>{this.getStatus(this.props.ASNData.orderStatusCode)}</td>
              </tr>
              <tr>
                <td>PD Order Number : </td>
                <td>{this.props.ASNData.pdOrderNumber}</td>
              </tr>
            </table>
          </div>
          <div id="Model-footer" className="Modal-footer">
            {this.props.noCancel === 'true' ? (
              ''
            ) : (
              <button
              className="modalButton modalButtonBackward"
                onClick={() => {
                  this.props.clearASNData();
                }}
              >
                Cancel
              </button>
            )}
            {this.props.noContinue === 'true' ? (
              ''
            ) : (
              <button
                className="modalButton modalButtonForward "
                onClick={() =>
                  this.props.goToReceiveOrderFlow(this.props.ASNData)
                }
              >
                Receive/View
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ rmadashboardReducer }) => {
  const { ASNData } = rmadashboardReducer;
  return { ASNData };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearASNData,
      goToReceiveOrderFlow,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ASNDetailsModel);
