import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../RmaDashboard/RmaDashboard.scss';
import RmaSuccessSummaryTable from './RmaSuccessSummaryTable';
import { goToRmaDashboard } from '../createTransferFlow/action';
import { ReactComponent as LeftArrowLogo } from '../../../assets/arrow1-left.svg';
import { ReactComponent as ChevronUp } from '../../../assets/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../../assets/chevron-down.svg';
import './RmaImportModal.scss';

import {
  getInitialInfo,
  searchServiceProviders,
  getspLocInfo,
  searchRma,
  goToCreateTransferFlow,
  goToImportFlow,
  goToRmaSummary,
} from '../RmaDashboard/action';
import {
  addSuccessMessage,
  addErrorMessage,
  clearMessages,
} from '../../action';
import { RemoteChunkSize } from 'papaparse';

class RmaSummarySuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indexClicked: []
    };
  }


  componentDidMount() {
    if (!this.props.userType) {
      this.props.getInitialInfo();
    }
  }
  removeItem(arr, item) {
    return arr.filter(f => f !== item)
  }

  render() {
    return (
      <div>
        <a
          className="ScanNavLabel"
          href="#"
          onClick={this.props.goToRmaDashboard}
        >
          <LeftArrowLogo className="scanLeftArraow" />
          Return to RMA Dashboard
        </a>

        <div className="rightdiv">
          <button className="rmaDashButton" onClick={this.props.goToImportFlow}>
            Create another RMA
          </button>
        </div>

        <div>
          <div className="leftdiv">
            <p className="title">RMA Summary</p> <br />
            <br /> <br />
            <br />
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="tablediv">
          {this.props.rmaSummary.map((x, i) => {
            return (
              <div className="mainTablediv">
                <div className="summaryHeaders">
                  <div className="summaryleftdiv">
                    <div className="summaryupperdiv"> {x.spName} </div>
                    <div className="summarylowerdiv">{x.rmaNumber} </div>
                  </div>
                  <div className="summaryrightdiv"> {x.spLocationName}
                  {!this.state.indexClicked.includes(i) && <ChevronDown onClick={() => {
                      let array = this.state.indexClicked
                      array.push(i)
                      this.setState({
                        indexClicked: array
                      });
                    }} />}
                    {this.state.indexClicked.includes(i) && <ChevronUp onClick={() => {
                      let arr = this.state.indexClicked;
                      let newarr = this.removeItem(arr, i);
                      this.setState({
                        indexClicked: newarr
                      });
                    }} />}
                   </div>
                </div>
                <div>
                 {this.state.indexClicked.includes(i) && <RmaSuccessSummaryTable tableData={x.parts} />}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ rmaImportReducer }) => {
  const { rmaSummary } = rmaImportReducer;
  return {
    rmaSummary,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInitialInfo,
      searchServiceProviders,
      getspLocInfo,
      searchRma,
      goToCreateTransferFlow,
      goToImportFlow,
      goToRmaSummary,
      goToRmaDashboard,
      addSuccessMessage,
      addErrorMessage,
      clearMessages,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RmaSummarySuccess);
