import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../RmaDashboard/table.scss';


const getStatus = (value) => {
  return (
    <a className={statusMap[value]['class']}>
      {statusMap[value]['displayValue']}
    </a>
  );
};

const statusMap = {
  INITIATED: {
    displayValue: ' In progress ',
    action: [],
    class: 'rmaInprogressStyle',
  }
};

const columns = [
  {
    Header: 'Part Number',
    accessor: 'partNumber',
    disableSortBy: true,
  },
  {
    Header: 'Serial Number',
    accessor: 'serialNumber',
    disableSortBy: true,
  },
  {
    Header: 'Defect Code',
    accessor: 'DefectCode',
    disableSortBy: true,
  },
  {
    Header: 'Return Reason',
    accessor: 'ReturnReason',
    disableSortBy: true,
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    disableSortBy: true,
  }
  
];

function RmaSuccessSummaryTable(props) {
  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: props.tableData,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  return (
    <div style= {{ maxHeight: "350px", overflow: "scroll" }}>
      <table className="rmaFiledashboardtable" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="rmaDashboardTableHeader"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? '▼' : '▲') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr className="dashrmatr" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className="rmaDashBoardTableData" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}


export default (RmaSuccessSummaryTable);
