import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MessageModal from '../../common/MessageModal';
import { ReactComponent as LeftArrowLogo } from '../../../assets/arrow1-left.svg';
import { ReactComponent as CloseButton } from '../../../assets/close2.svg';
import { ReactComponent as Expand } from '../../../assets/chevron-down.svg';
import { ReactComponent as Collapse } from '../../../assets/chevron-up.svg';
import { ReactComponent as EditPencil } from '../../../assets/pencil-1.svg';
import BarcodeReader from 'react-barcode-reader';
import { goToRmaDashboard, addSerial, updateparts, togglechild, deleteSerial, updateQuantity, generatePackingList } from './action';
import { addErrorMessage, enableSpinner, disableSpinner } from '../../action';
import './ScanSerial.scss';
import { CONSTANTS } from '../../common/constants/generalConst';
const statusMap = {
  REMOVED: 'Removed',
  NOT_REQUIRED: 'Not Required',
  DEFECTIVE_REPLACED: 'Defective Replaced',
  UNINSTALLED: 'Uninstalled',
  DEFECTIVE: 'Defective',
  IN_STOCK: 'In Stock',
  DEAD_ON_ARRIVAL: 'Dead on Arrival'
};

class ScanSerials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      message: '',
      borderColor: '#377a80',
      modalVisible: false,
      validatationModalVisible: false,
      successModalVisible: false,
      currentPartNumber: '',
      requestedQuantity: 0,
      scannedQuantity: 0,
      partsReasons: [],
      serial: '',
      doNotShowContinue: 'true',
      currPartQuantities: [],
      updatePartQuantities: [],
      spPartLocationId: '',
      fromLabel: this.props?.pageData?.rmaInfo?.transferType === CONSTANTS.CAPABILITIES.TRUCK ? 'Transferred From' : 'Shipping Origin',
      toLabel: this.props?.pageData?.rmaInfo?.transferType === CONSTANTS.CAPABILITIES.TRUCK ? 'Transferred To' : 'Shipping Destination',
      submitButtonText: this.props?.pageData?.rmaInfo?.transferType === CONSTANTS.CAPABILITIES.TRUCK ? 'Sutmit Transfer' : 'Generate packing list'
    };
    this.onScan = this.onScan.bind(this);
    this.ondelete = this.ondelete.bind(this);
    this.modalOnOff = this.modalOnOff.bind(this);
    this.updatePartQuantity = this.updatePartQuantity.bind(this);
    this.setQuantity = this.setQuantity.bind(this);
    this.validationModalOnOff = this.validationModalOnOff.bind(this);
    this.validatePartsAndPack = this.validatePartsAndPack.bind(this);
    this.updateReason = this.updateReason.bind(this);
    this.getpartLocationIDFromSelectedSP = this.getpartLocationIDFromSelectedSP.bind(this);
  }
  async onScan(serial) {
    this.props.enableSpinner();
    const { rmaNumber, serviceProviderLocation } = this.props.pageData.rmaInfo;
    let spLoctions = this.props.spLocations;
    let partLocationID = await this.getpartLocationIDFromSelectedSP(serviceProviderLocation, spLoctions);
    let addresponse = await this.props.addSerial(rmaNumber, partLocationID, serial);
    this.setState({ serial: serial });
    if (addresponse && addresponse.status === 'failure') {
      this.setState({
        showError: true,
        borderColor: '#db1b1b',
        message: addresponse.message || addresponse.Message
      });
    } else {
      this.props.updateparts(this.props.pageData.rmaInfo.rmaNumber, addresponse.partNumber, partLocationID);
    }
    document.getElementById('scanInputBox').value = '';
    this.setState({ serial: '' });
    this.props.disableSpinner();
  }
  // for getting the partlocation number using
  async getpartLocationIDFromSelectedSP(serviceProviderLocation, spLocations) {
    for (const element of spLocations) {
      if (element.value == serviceProviderLocation) {
        this.setState({ spPartLocationId: element.spPartLocationId });
        return element.spPartLocationId;
      }
    }
  }

  async ondelete(serial, partNumber) {
    let addresponse = await this.props.deleteSerial(this.props.pageData.rmaInfo.rmaNumber, serial);
    if (addresponse && addresponse.status === 'failure') {
      this.setState({
        showError: true,
        borderColor: '#db1b1b',
        message: addresponse.message
      });
    } else {
      this.props.updateparts(this.props.pageData.rmaInfo.rmaNumber, partNumber);
    }
  }

  modalOnOff(bool, partNumber, currPartQuantities) {
    if (bool) {
      this.setState({ currentPartNumber: partNumber, currPartQuantities }, () => this.setState({ modalVisible: bool }));
    } else {
      this.setState({ modalVisible: bool });
    }
  }
  validationModalOnOff(bool) {
    this.setState({ validatationModalVisible: bool });
  }

  async updatePartQuantity() {
    let updateResponse = await this.props.updateQuantity(this.props.pageData.rmaInfo.rmaNumber, this.state.currentPartNumber, this.state.updatePartQuantities);
    if (updateResponse && updateResponse.status === 'failure') {
    } else {
      this.props.updateparts(this.props.pageData.rmaInfo.rmaNumber, '', this.state.spPartLocationId);
      this.setState({ currPartQuantities: [], updatePartQuantities: [] });
      this.modalOnOff(false);
    }
  }
  setQuantity(quantity) {
    this.setState({ scannedQuantity: quantity });
  }

  async validatePartsAndPack(reasonGiven) {
    this.props.enableSpinner();
    const { serviceProviderLocation } = this.props.pageData.rmaInfo;
    let spLoctions = this.props.spLocations;
    let partLocationId = await this.getpartLocationIDFromSelectedSP(serviceProviderLocation, spLoctions);
    if (reasonGiven || this.props.pageData.rmaInfo.returnType === 'SP_INITIATED_FSL' || this.props.pageData.rmaInfo.returnType === 'SP_INITIATED') {
      let allResonsSelected = true;
      let reasons = this.state.partsReasons;
      reasons = reasons.map(r => {
        if (!r.reason || r.reason == '') {
          allResonsSelected = false;
        }
      });
      let genpacklist;
      if (allResonsSelected) {
        this.state.doNotShowContinue = 'false';
        genpacklist = await this.props.generatePackingList(this.props.pageData.rmaInfo.rmaNumber, partLocationId, this.state.partsReasons);
      }
      if (genpacklist && genpacklist.status === 'success') {
        this.setState({
          validatationModalVisible: false,
          successModalVisible: true
        });
      }
    } else {
      let unfullfilledParts = [];
      for (let partNumber in this.props.pageData.parts) {
        if (this.props.pageData.parts[partNumber].requestedQuantity !== this.props.pageData.parts[partNumber].scannedQuantity) {
          unfullfilledParts.push({
            partNumber: partNumber,
            quantity: `${this.props.pageData.parts[partNumber].scannedQuantity}/${this.props.pageData.parts[partNumber].requestedQuantity}`,
            reason: ''
          });
        }
      }
      if (unfullfilledParts.length > 0) {
        this.setState({ partsReasons: unfullfilledParts });
        this.validationModalOnOff(true);
      } else {
        let genpacklist = await this.props.generatePackingList(this.props.pageData.rmaInfo.rmaNumber, partLocationId);
        if (genpacklist && genpacklist.status === 'success') {
          this.setState({
            validatationModalVisible: false,
            successModalVisible: true
          });
        }
      }
    }
    this.props.disableSpinner();
  }

  updateReason(event, partNumber) {
    let reasons = this.state.partsReasons;
    let allResonsSelected = true;
    reasons = reasons.map(r => {
      if (r.partNumber === partNumber) {
        r.reason = event.target.value;
      }

      if (!r.reason || r.reason == '') {
        allResonsSelected = false;
      }
      return r;
    });
    if (allResonsSelected) {
      this.state.doNotShowContinue = 'false';
    } else {
      this.state.doNotShowContinue = 'true';
    }
    this.setState({ partsReasons: [...reasons] });
  }

  render() {
    return (
      <div>
        <div className='ScanNavLabel'>
          {this.state.modalVisible ? (
            <MessageModal
              id='quantityModal'
              header='Enter quantities'
              content={
                <div>
                  <table>
                    <div className='quantityEditBox'>
                      {this.state.currPartQuantities.map(item => (
                        <tr>
                          <td>
                            <label for={item.status}>{statusMap[item.status] ? statusMap[item.status] : item.status ? item.status : 'In Stock'}</label>
                          </td>
                          <td>
                            <input
                              type='number'
                              id={item.status}
                              className='quantityEditInputBox'
                              value={item.quantity}
                              min='0'
                              onChange={event => {
                                let currCounts = this.state.updatePartQuantities;
                                let found = false;
                                for (let i = 0; i < currCounts.length; i++) {
                                  if (!item.status) {
                                    currCounts[i].quantity = event.target.value;
                                    break;
                                  }
                                  if (item.status === currCounts[i].statusCode) {
                                    currCounts[i].quantity = event.target.value;
                                    found = true;
                                    break;
                                  }
                                }
                                if (!found) {
                                  currCounts.push({
                                    statusCode: item.status ? item.status : 'IN_STOCK',
                                    quantity: event.target.value
                                  });
                                }
                                this.setState({
                                  updatePartQuantities: [...currCounts]
                                });
                              }}
                            ></input>{' '}
                          </td>
                        </tr>
                      ))}
                    </div>
                  </table>
                </div>
              }
              cancel={() => {
                this.setState({
                  currPartQuantities: [],
                  updatePartQuantities: []
                });
                this.modalOnOff(false);
              }}
              continue={() => {
                this.updatePartQuantity();
              }}
            />
          ) : (
            ''
          )}
          {this.state.successModalVisible ? (
            <MessageModal
              id='successModal'
              header='Packing complete!'
              noCancel='true'
              contentStyle='successModalContent'
              bodyStyle='successModalBody'
              continueName='Return to Transfer Dashboard'
              content={
                <div>
                  <p style={{ textAlign: 'left' }}>
                    Next step is to print packing list shipping label and affix the shipping label to the box
                    <br />
                    We'll send you an email once the label is ready or you can check the Transfer Dashboard for updates.it should be ready in the next 5-10
                    minutes.
                  </p>
                </div>
              }
              continue={this.props.goToRmaDashboard}
            />
          ) : (
            ''
          )}
          {this.state.validatationModalVisible ? (
            <MessageModal
              id='validationModal'
              header='Part quantity discrepancy'
              contentStyle='validationModalContent'
              bodyStyle='validationModalBody'
              cancelName='Change quantities'
              continueName='Submit Transfer'
              noContinue={this.state.doNotShowContinue}
              content={
                <div>
                  <p>
                    The following parts are either over or under the quantity requested. Please provide a reason for this discrepancy or go back to add or
                    remove parts.
                  </p>
                  <table className='unfulfilledReasonsTable'>
                    <tr>
                      <th>Part number</th>
                      <th>Quantity</th>
                      <th>Reason for over/under</th>
                    </tr>
                    {this.state.partsReasons.map(row => (
                      <tr>
                        <td>{row.partNumber}</td>
                        <td>{row.quantity}</td>
                        <td>
                          <select onChange={event => this.updateReason(event, `${row.partNumber}`)}>
                            <option value=''>Select</option>
                            <option value='Part was used'>Part was used</option>
                            <option value='Part not found'>Part not found</option>
                            <option value='Part was returned'>Part was returned</option>
                          </select>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              }
              cancel={() => this.validationModalOnOff(false)}
              continue={() => this.validatePartsAndPack(true)}
            />
          ) : (
            ''
          )}
          <LeftArrowLogo className='scanLeftArraow' />
          <a className='ScanNavLabel' href='#' onClick={this.props.goToRmaDashboard}>
            Return to Transfer Dashboard
          </a>
        </div>
        <div className='rmaNumberLabel'>Transfer no. {this.props.pageData.rmaInfo.rmaNumber}</div>
        <div className='ScanContent'>
          <div className='scanParentDiv'>
            <p className='title'> Scan or enter parts</p>
            <p className='title2'> Part or serial number</p>
            <input
              id='scanInputBox'
              style={{ borderColor: this.state.borderColor }}
              value={this.state.serial}
              disabled={this.props.pageData.rmaInfo.status === 'INITIATED' ? false : true}
              className='scanbox'
              onChange={event => {
                this.setState({
                  showError: false,
                  borderColor: '#377a80',
                  serial: event.target.value
                });
              }}
              onKeyDown={event => {
                if (event.keyCode === 13 && event.target.value !== '') {
                  this.onScan(event.target.value);
                }
              }}
            ></input>
            <div>
              <BarcodeReader onScan={this.onScan} />
            </div>
            {this.state.showError ? (
              <div className='scanMessage'>
                <p>{this.state.message}</p>
              </div>
            ) : (
              ''
            )}
            <div className='fromToInfoDiv'>
              <p className='labeltext'>{this.state.fromLabel}</p>
              <p className='locationNameText'>{this.props.pageData.from.locationName}</p>

              <p className='labeltext'>{this.state.toLabel}</p>
              <p className='locationNameText'>{this.props.pageData.to.locationName}</p>
            </div>
            <button
              className={
                this.props.pageData.rmaInfo.status === 'INITIATED' && Object.keys(this.props.pageData.parts).length > 0 ? 'buttonEnabled' : 'buttonDisabled'
              }
              disabled={this.props.pageData.rmaInfo.status === 'INITIATED' && Object.keys(this.props.pageData.parts).length > 0 ? false : true}
              onClick={() => this.validatePartsAndPack(false)}
            >
              <p className='buttonText'>{this.state.submitButtonText}</p>
            </button>
          </div>
          <div className='partsDiv'>
            <p className='title'>Scanned Parts</p>
            <div className='partsContainer'>
              {Object.keys(this.props.pageData.parts).map(partNumber => {
                let backgroundcolor =
                  this.props.pageData.parts[`${partNumber}`].requestedQuantity < this.props.pageData.parts[`${partNumber}`].scannedQuantity
                    ? '#F9D51C'
                    : this.props.pageData.parts[`${partNumber}`].requestedQuantity > this.props.pageData.parts[`${partNumber}`].scannedQuantity
                    ? '#FFFFFF'
                    : '#F0FFF9';
                return (
                  <div className={this.props.pageData.parts[`${partNumber}`].isSerialized ? 'partContainer' : 'partContainerNonSerialized'}>
                    <div className='partInfo' style={{ backgroundColor: backgroundcolor }}>
                      {partNumber}
                      {this.props.pageData.parts[`${partNumber}`].isSerialized ? (
                        <div style={{ float: 'right' }}>
                          {!this.props.pageData.parts[`${partNumber}`].childVisible ? (
                            <Expand onClick={() => this.props.togglechild(partNumber, this.props.pageData, true)} />
                          ) : (
                            <Collapse onClick={() => this.props.togglechild(partNumber, this.props.pageData, false)} />
                          )}
                        </div>
                      ) : (
                        <div style={{ float: 'right' }}>
                          {this.props.pageData.rmaInfo.status === 'INITIATED' ? (
                            <EditPencil onClick={() => this.modalOnOff(true, partNumber, this.props.pageData.parts[`${partNumber}`].parts)} />
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                      {this.props.pageData.rmaInfo.returnType !== 'SP_INITIATED_FSL' && this.props.pageData.rmaInfo.returnType !== 'SP_INITIATED' ? (
                        <div style={{ float: 'right', marginRight: '5px' }}>
                          {this.props.pageData.parts[`${partNumber}`].scannedQuantity}/{this.props.pageData.parts[`${partNumber}`].requestedQuantity}
                        </div>
                      ) : (
                        <div style={{ float: 'right', marginRight: '5px' }}>
                          {this.props.pageData.rmaInfo.returnType === 'SP_INITIATED_FSL'
                            ? this.props.pageData.parts[`${partNumber}`].requestedQuantity
                            : this.props.pageData.parts[`${partNumber}`].scannedQuantity}
                        </div>
                      )}
                    </div>
                    {this.props.pageData.parts[`${partNumber}`].childVisible ? (
                      this.props.pageData.parts[`${partNumber}`].isSerialized ? (
                        <div id={partNumber} className='rmascanchildtablediv'>
                          <table className='rmascanchildtable'>
                            {this.props.pageData.parts[`${partNumber}`].parts.map(part => {
                              let statusbackgroundcolor = part.returnStatus === 'Scanned' ? '#37E7A7' : '#000000';
                              let statuscolor = part.returnStatus === 'Scanned' ? '#000000' : '#ffffff';
                              return (
                                <tr className='serialRow'>
                                  <td>
                                    <div className='serial'>{part.partSerialNumber}</div>
                                  </td>
                                  <td>
                                    <div className='status'>
                                      <p
                                        style={{
                                          background: statusbackgroundcolor,
                                          color: statuscolor,
                                          display: 'inline-block'
                                        }}
                                      >
                                        {part.returnStatus}
                                      </p>
                                    </div>
                                  </td>
                                  <td className='delete'>
                                    {this.props.pageData.rmaInfo.status === 'INITIATED' ? (
                                      part.returnStatus === 'Scanned' ? (
                                        <CloseButton onClick={() => this.ondelete(`${part.partSerialNumber}`, `${part.partNumber}`)} />
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      ) : this.props.pageData.parts[`${partNumber}`].parts && Array.isArray(this.props.pageData.parts[`${partNumber}`].parts) ? (
                        this.props.pageData.parts[`${partNumber}`].parts.map(item => (
                          <div className='countsByStatusDiv'>
                            <p>
                              {statusMap[item.status] ? statusMap[item.status] : item.status ? item.status : 'In Stock'}:{' '}
                              {this.props.pageData.rmaInfo.returnType === 'SP_INITIATED_FSL'
                                ? item.quantityInStock && item.quantityInStock !== ''
                                  ? item.requiredQuantity + ' / ' + item.quantityInStock
                                  : item.requiredQuantity
                                : item.quantityInStock && item.quantityInStock !== ''
                                ? item.scanQuantity + ' / ' + item.quantityInStock
                                : item.scanQuantity}
                            </p>
                          </div>
                        ))
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ rmaScanReducer, rmadashboardReducer }) => {
  const { pageData } = rmaScanReducer;
  const { spLocations } = rmadashboardReducer;
  return {
    pageData,
    spLocations
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToRmaDashboard,
      addSerial,
      updateparts,
      togglechild,
      deleteSerial,
      updateQuantity,
      addErrorMessage,
      generatePackingList,
      enableSpinner,
      disableSpinner
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ScanSerials);
