import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RmaImportFile from './RmaImportFile.jsx';
import RmaImportCSV from './RmaImportCSV.jsx';
import RmaImportManual from './RmaImportManual.jsx';
import './RmaImportModal.scss';
import MessageModal from '../../common/MessageModal';
import {
  addSuccessMessage,
  addErrorMessage,
  clearMessages,
} from '../../action';

import {
  goToImportFlow,
  goToImportFile,
  goToRmaManual,
} from '../RmaDashboard/action';

import { goToRmaDashboard } from '../createTransferFlow/action';

class RmaStartOptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.checkSelectedOption = this.checkSelectedOption.bind(this);
  }
  checkSelectedOption() {
    var radios = document.getElementsByName('rmaCreate');

    var checked = false;
    var selectedValue;

    for (var i = 0, length = radios.length; i < length; i++) {
      if (radios[i].checked) {
        checked = true;
        break;
      }
    }
    if (!checked) {
      this.props.addErrorMessage('Please select one value');
      return false;
    }
    {
      if (radios[i].value === 'upload') {
        this.props.goToImportFile();
      } else {
        this.props.goToRmaManual();
      }
    }
  }

  render() {
    return (
      <MessageModal
        header="Create new RMA"
        content={
          <div>
            <p className="rmaImportModalText">
              Do you want to upload the file?
            </p>
            <label className="rmaImportRadioBtnText">
              <input
                type="radio"
                name="rmaCreate"
                value="upload"
                className="rmaImportRadioBtn"
              />
              Yes, Upload File
            </label>

            <label className="rmaImportRadioBtnText">
              <input
                type="radio"
                name="rmaCreate"
                value="manual"
                className="rmaImportRadioBtn"
              />
              No, Manually Enter Parts
            </label>
          </div>
        }
        cancelName="Return to RMA dashboard"
        cancel={() => {
          this.props.goToRmaDashboard();
          this.props.clearMessages();
        }}
        continue={this.checkSelectedOption}
      />
    );
  }
}

const mapStateToProps = ({ rmadashboardReducer }) => {
  const { userType } = rmadashboardReducer;
  return {
    userType,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToImportFlow,
      goToImportFile,
      goToRmaManual,
      goToRmaDashboard,
      addSuccessMessage,
      addErrorMessage,
      clearMessages,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RmaStartOptionModal);
