import {
  SET_DATA_FOR_RMA_DASHBOARD_TABLE,
  SET_USER_TYPE,
  SET_SP_LOCATION_LIST_FOR_DASHBOARD,
  SET_SP_FOR_DASHBOARD,
  SET_SP_LIST_FOR_RMA_DASHBOARD,
  SET_RMA_DASHBOARD_FROM_DATE,
  SET_RMA_DASHBOARD_TO_DATE,
  SET_RMA_DASHBOARD_SELECTED_SP_ID,
  SET_RMA_DASHBOARD_SHIP_MODAL,
  SET_RMA_DASHBOARD_PACK_MODAL,
  SET_RMA_DASHBOARD_SHIP_PACK_MODAL_DATA,
  SET_RMA_DASHBOARD_CODE_DESCRIPTION_LIST,
  SET_RMA_DASHBOARD_SELECTED_STATUSES,
  SET_TRANSFER_DASHBOARD_CURRENT_SELECTION,
  SET_APPLICATION_CAPABILITIES,
  SET_ASN_DATA
} from '../../../actionTypes';
const initialState = {
  tableData: [],
  selectedSP: {},
  shipModalVisisble: false,
  packListModalVisible: false,
  rmaInfoInShipPackModal: {},
  currentTransfersSelection: 'incoming',
  capabilities: [],
  codeDescriptionList: {},
  ASNData: {},
  selectedStatuses: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_FOR_RMA_DASHBOARD_TABLE:
      return {
        ...state,
        tableData: action.data
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.data
      };
    case SET_SP_LOCATION_LIST_FOR_DASHBOARD:
      return {
        ...state,
        spLocations: action.data
      };
    case SET_RMA_DASHBOARD_CODE_DESCRIPTION_LIST:
      return {
        ...state,
        codeDescriptionList: action.data
      };
    case SET_RMA_DASHBOARD_SELECTED_STATUSES:
      return {
        ...state,
        selectedStatuses: action.data
      };
    case SET_SP_FOR_DASHBOARD:
      return {
        ...state,
        selectedSP: action.data
      };
    case SET_SP_LIST_FOR_RMA_DASHBOARD:
      return {
        ...state,
        spList: action.data
      };
    case SET_RMA_DASHBOARD_FROM_DATE:
      return {
        ...state,
        fromDate: action.data
      };
    case SET_RMA_DASHBOARD_TO_DATE:
      return {
        ...state,
        toDate: action.data
      };
    case SET_RMA_DASHBOARD_SELECTED_SP_ID:
      return {
        ...state,
        selectedSpId: action.data
      };
    case SET_RMA_DASHBOARD_SHIP_MODAL:
      return {
        ...state,
        shipModalVisisble: action.data
      };
    case SET_RMA_DASHBOARD_PACK_MODAL: {
      return {
        ...state,
        packListModalVisible: action.data
      };
    }
    case SET_RMA_DASHBOARD_SHIP_PACK_MODAL_DATA:
      return {
        ...state,
        rmaInfoInShipPackModal: action.data
      };
    case SET_TRANSFER_DASHBOARD_CURRENT_SELECTION:
      return {
        ...state,
        currentTransfersSelection: action.data
      };
    case SET_APPLICATION_CAPABILITIES:
      return {
        ...state,
        capabilities: action.data
      };
    case SET_ASN_DATA:
      return {
        ...state,
        ASNData: action.data
      };
    default:
      return state;
  }
};
