import { Post, uriName, serviceNetwork, opsAdmin } from '../../../utilities/apiUtility';
import {
  SET_DATA_FOR_RMA_DASHBOARD_TABLE,
  SET_SP_LOCATION_LIST_FOR_DASHBOARD,
  SET_SP_FOR_DASHBOARD,
  SET_USER_TYPE,
  SET_SP_LIST_FOR_RMA_DASHBOARD,
  SET_RMA_DASHBOARD_FROM_DATE,
  SET_RMA_DASHBOARD_TO_DATE,
  SET_RMA_DASHBOARD_SELECTED_SP_ID,
  SET_RMA_DASHBOARD_SHIP_MODAL,
  SET_RMA_DASHBOARD_PACK_MODAL,
  SET_RMA_DASHBOARD_SHIP_PACK_MODAL_DATA,
  SET_RMA_DASHBOARD_CODE_DESCRIPTION_LIST,
  SET_RMA_DASHBOARD_SELECTED_STATUSES,
  SET_RETURNS_NAVIGATION,
  SET_RMA_INFO_FOR_SCAN_PAGE,
  SET_RMA_INFO_FOR_SCAN_MA_PAGE_SELECT_RMA,
  SET_INFO_FOR_DYNAMIC_SCAN_PAGE,
  ADD_APPLICATION_ERROR_MESSAGE,
  ADD_APPLICATION_SUCCESS_MESSAGE,
  SET_APPLICATION_ERROR_MESSAGES,
  SET_APPLICATION_STATUS_INTIALIZE,
  SET_APPLICATION_STATUS_READY,
  SET_TRANSFER_DASHBOARD_CURRENT_SELECTION,
  SET_RMA_SUMMARY,
  SET_FSL_TRANSFER_INFO_FOR_SCAN_PAGE,
  SET_APPLICATION_CAPABILITIES,
  SET_ASN_DATA,
  DISABLE_APPLICATION_SPINNER,
  ENABLE_APPLICATION_SPINNER,
  SET_TRUCK_PAGE_INITIAL_INFO,
  SET_SELECTED_TRANSFER_TYPE,
  SET_SELECTED_ORIGIN,
  SET_SELECTED_DESTINATION
} from '../../../actionTypes';
import { addErrorMessage, enableSpinner, disableSpinner } from '../../action';
import { searchRmaMock, getInitialInfoMock, goToScanningMaFlowMock } from '../../mocks';
import config from '../../../config.json';
import { CONSTANTS } from '../../common/constants/generalConst';
import { URLS } from '../../common/constants/urlConsts';
import _ from 'lodash';

export const getInitialInfo = () => async dispatch => {
  if (_.get(config, 'mocks.api.getInitialInfo', false)) {
    return getInitialInfoMock()(dispatch);
  }
  try {
    dispatch({ type: SET_APPLICATION_STATUS_INTIALIZE, data: {} });
    let toDate = new Date();
    let fromDate = new Date(toDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    fromDate = fromDate.getMonth() + 1 + '/' + fromDate.getDate() + '/' + fromDate.getFullYear();
    toDate = toDate.getMonth() + 1 + '/' + toDate.getDate() + '/' + toDate.getFullYear();
    const response = await Post(uriName, URLS.INTIAL_INFO_URL, {
      fromDate: fromDate,
      toDate: toDate
    });
    if (response.userInfo.companyType) {
      dispatch({ type: SET_USER_TYPE, data: response.userInfo.companyType });
    }
    if (response.transfers) {
      dispatch({
        type: SET_DATA_FOR_RMA_DASHBOARD_TABLE,
        data: response.transfers
      });
    }
    if (response.locations) {
      response.locations = response.locations.map(item => {
        return {
          ...item,
          label: item.description,
          value: item.serviceProviderLocationId
        };
      });
      if (response.locations.length > 1) {
        response.locations.unshift({ label: 'ALL', value: 'all' });
      }
      dispatch({
        type: SET_SP_LOCATION_LIST_FOR_DASHBOARD,
        data: response.locations
      });
    }
    if (response.userCompany) {
      dispatch({ type: SET_SP_FOR_DASHBOARD, data: response.userCompany });
    }
    if (response.fromDate) {
      dispatch({ type: SET_RMA_DASHBOARD_FROM_DATE, data: response.fromDate });
    }
    if (response.toDate) {
      dispatch({ type: SET_RMA_DASHBOARD_TO_DATE, data: response.toDate });
    }
    if (response.capabilities) {
      let capabilities = response.capabilities.map(item => {
        if (item === CONSTANTS.CAPABILITIES.RETURNS) {
          return { label: 'Returns', value: CONSTANTS.CAPABILITIES.RETURNS, id: CONSTANTS.CAPABILITIES.RETURNS };
        } else if (item === CONSTANTS.CAPABILITIES.FSL) {
          return { label: 'FSL', value: CONSTANTS.CAPABILITIES.FSL, id: CONSTANTS.CAPABILITIES.FSL };
        } else if (item === CONSTANTS.CAPABILITIES.TRUCK) {
          return { label: 'Truck', value: CONSTANTS.CAPABILITIES.TRUCK, id: CONSTANTS.CAPABILITIES.TRUCK };
        } else if (item === CONSTANTS.CAPABILITIES.IMPORT_FILE_TRANSFER) {
          return { label: 'Import a File', value: 'importFileTransfer' };
        } else if (item === CONSTANTS.CAPABILITIES.CORE_RETURN) {
          return { label: 'Core Return', value: CONSTANTS.CAPABILITIES.CORE_RETURN, id: CONSTANTS.CAPABILITIES.CORE_RETURN };
        }
      });
      dispatch({ type: SET_APPLICATION_CAPABILITIES, data: capabilities });
      dispatch({ type: SET_APPLICATION_STATUS_READY, data: {} });
    }
  } catch (error) {}
  try {
    const response = await Post(opsAdmin, URLS.CODEDESC_SERACH, {
      codeType: 12021
    });
    if (response.codeDescriptionList) {
      dispatch({ type: SET_RMA_DASHBOARD_CODE_DESCRIPTION_LIST, data: response.codeDescriptionList });
    }
  } catch (error) {}
};

export const searchServiceProviders = value => async dispatch => {
  try {
    if (value.length > 3) {
      const response = await Post(serviceNetwork, `/v1/spsearch`, {
        providerId: value,
        providerName: value
      });
      if (response.providers) {
        response.providers = response.providers.map(item => {
          return {
            ...item,
            label: item.providerName,
            value: item.providerId
          };
        });
        dispatch({
          type: SET_SP_LIST_FOR_RMA_DASHBOARD,
          data: response.providers
        });
      }
    }
  } catch (error) {}
};

export const getspLocInfo = content => async dispatch => {
  try {
    const response = await Post(uriName, URLS.SP_LOCATION_INFO, content);
    if (response.rmaData) {
      dispatch({
        type: SET_DATA_FOR_RMA_DASHBOARD_TABLE,
        data: response.rmaData
      });
    }
    if (response.locations) {
      response.locations = response.locations.map(item => {
        return {
          ...item,
          label: item.description,
          value: item.serviceProviderLocationId
        };
      });
      if (response.locations.length > 1) {
        dispatch({ type: SET_RMA_DASHBOARD_SELECTED_SP_ID, data: 'all' });
        response.locations.unshift({ label: 'ALL', value: 'all' });
      }

      dispatch({
        type: SET_SP_LOCATION_LIST_FOR_DASHBOARD,
        data: response.locations
      });
    }
    if (response.userCompany) {
      dispatch({ type: SET_SP_FOR_DASHBOARD, data: response.userCompany });
    }
  } catch (error) {}
};

export const searchRma = content => async dispatch => {
  if (_.get(config, 'mocks.api.searchRma', false)) {
    searchRmaMock(content)(dispatch);
    return;
  }
  try {
    const response = await Post(uriName, `/v1/transfergateway/rma/rmaSearch`, content);
    if (content.fromDate) {
      dispatch({ type: SET_RMA_DASHBOARD_FROM_DATE, data: content.fromDate });
    }
    if (content.toDate) {
      dispatch({ type: SET_RMA_DASHBOARD_TO_DATE, data: content.toDate });
    }
    if (content.providerLocationId) {
      dispatch({
        type: SET_RMA_DASHBOARD_SELECTED_SP_ID,
        data: content.providerLocationId
      });
    } else {
      dispatch({ type: SET_RMA_DASHBOARD_SELECTED_SP_ID, data: 'all' });
    }
    if (content.selectedStatuses) {
      dispatch({ type: SET_RMA_DASHBOARD_SELECTED_STATUSES, data: content.selectedStatuses });
    }
    if (response.rmaData) {
      dispatch({
        type: SET_DATA_FOR_RMA_DASHBOARD_TABLE,
        data: response.rmaData
      });
    }
  } catch (error) {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: 'Something went wrong when was searching RMAs. Please try again.' });
  }
};

export const cancelRma = (rmaNumber, tableData) => async dispatch => {
  try {
    dispatch({ type: ENABLE_APPLICATION_SPINNER, data: true });
    const rmaInfoResponse = await Post(uriName, `/v1/transfergateway/rma/rmaInfo`, { rmaNumber });
    let blockCancellation = false;
    if (rmaInfoResponse && rmaInfoResponse.parts && rmaInfoResponse.parts.length > 0) {
      for (const part of rmaInfoResponse.parts) {
        if (Number(part.isSerialized) === 1 || (Number(part.isSerialized) === 0 && Number(part.scanQuantity) > 0)) {
          blockCancellation = true;
          break;
        }
      }
    }
    if (blockCancellation) {
      dispatch({
        type: ADD_APPLICATION_ERROR_MESSAGE,
        data: `Unable to cancel this transfer as there are one or more parts already scanned. Please delete the scanned parts before cancelling.`
      });
      return;
    } else {
      const cancelResponse = await Post(uriName, `/v1/transfergateway/rma/update`, {
        action: 'cancel',
        rmaNumber
      });
      if (cancelResponse && cancelResponse.status && cancelResponse.status.toUpperCase() === 'SUCCESS') {
        dispatch({ type: ADD_APPLICATION_SUCCESS_MESSAGE, data: `Successfully cancelled ${rmaNumber}.` });
        dispatch({ type: SET_DATA_FOR_RMA_DASHBOARD_TABLE, data: getUpdatedTableData(rmaNumber, tableData) });
        return;
      } else {
        dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Something went wrong cancelling ${rmaNumber}. Please try again.` });
        return;
      }
    }
  } catch (err) {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Something went wrong when cancelling ${rmaNumber}. Please try again.` });
  } finally {
    dispatch({ type: DISABLE_APPLICATION_SPINNER, data: false });
  }
};

const getUpdatedTableData = (rmaNumber, tableData) => {
  let tableDataCopy = structuredClone(tableData);
  const objIdx = tableDataCopy.findIndex(obj => obj.rmaNumber === rmaNumber);
  delete tableDataCopy[objIdx];
  return tableDataCopy;
};

export const updateRma = (rmaNumber, action, spInfo, fromDate, toDate) => async dispatch => {
  let response = await Post(uriName, `/v1/transfergateway/rma/update`, {
    action: action,
    rmaNumber
  });
  if (response.status === 'failure') {
    dispatch({
      type: ADD_APPLICATION_ERROR_MESSAGE,
      data: `Something went wrong when updating RMA (${rmaNumber}). Please try again.`
    });
  }
  if (response.status === 'success') {
    dispatch({
      type: ADD_APPLICATION_SUCCESS_MESSAGE,
      data: 'success'
    });
  }
  if (spInfo && spInfo.providerId) {
    searchRma({
      providerId: `${spInfo.providerId}`,
      fromDate,
      toDate
    })(dispatch);
  }
};

export const openShipLabelModal = (rmaNumber, spInfo, fromDate, toDate) => async dispatch => {
  try {
    enableSpinner()(dispatch);
    let updateRma = await Post(uriName, `/v1/transfergateway/rma/update`, {
      action: 'printShippingLabel',
      rmaNumber
    });
    let response = await Post(uriName, `/v1/transfergateway/rma/rmaInfo`, {
      rmaNumber
    });
    dispatch({ type: SET_RMA_DASHBOARD_SHIP_PACK_MODAL_DATA, data: response });
    dispatch({ type: SET_RMA_DASHBOARD_SHIP_MODAL, data: true });
    if (spInfo && spInfo.providerId && fromDate && toDate) {
      searchRma({
        providerId: `${spInfo.providerId}`,
        fromDate,
        toDate
      })(dispatch);
    }
  } catch (err) {
    addErrorMessage('Something went wrong when openning shipping label. Please try again.')(dispatch);
  } finally {
    disableSpinner()(dispatch);
  }
};

export const openPackListlModal = rmaNumber => async dispatch => {
  const response = await Post(uriName, `/v1/transfergateway/rma/rmaInfo`, {
    rmaNumber
  });
  dispatch({ type: SET_RMA_DASHBOARD_SHIP_PACK_MODAL_DATA, data: response });
  dispatch({ type: SET_RMA_DASHBOARD_PACK_MODAL, data: true });
};

export const closeShipLabelModal = () => async dispatch => {
  dispatch({ type: SET_RMA_DASHBOARD_SHIP_MODAL, data: false });
};

export const closePackListModal = () => async dispatch => {
  dispatch({ type: SET_RMA_DASHBOARD_PACK_MODAL, data: false });
};

export const goToScanningFlow = (rmaNumber, status) => async dispatch => {
  if (status === 'CREATED') {
    await Post(uriName, `/v1/transfergateway/rma/update`, {
      action: 'initiate',
      rmaNumber
    });
  }
  const response = await Post(uriName, `/v1/transfergateway/rma/rmaInfo`, {
    rmaNumber
  });
  if (response && response.parts && response.parts.length > 0) {
    let parts = {};
    for await (let part of response.parts) {
      if (parts[part.partNumber]) {
        parts[part.partNumber]['parts'].push(part);
        parts[part.partNumber]['scannedQuantity'] = parts[part.partNumber]['scannedQuantity'] + part.scanQuantity;
        parts[part.partNumber]['requestedQuantity'] = parts[part.partNumber]['requestedQuantity'] + 1;
      } else {
        if (part.isSerialized) {
          let newpart = {
            scannedQuantity: part.scanQuantity,
            requestedQuantity: 1,
            childVisible: false,
            parts: [part],
            isSerialized: true
          };
          parts[part.partNumber] = newpart;
        } else {
          let newpart = {
            scannedQuantity: part.scanQuantity,
            requestedQuantity: part.requiredQuantity,
            parts: [part],
            isSerialized: false,
            childVisible: true
          };
          parts[part.partNumber] = newpart;
        }
      }
    }
    response.parts = parts;
  } else {
    response.parts = {};
  }
  dispatch({ type: SET_RMA_INFO_FOR_SCAN_PAGE, data: response });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'scanSerials' });
};

export const goToScanningMaFlow = (rmaNumber, status) => async dispatch => {
  if (_.get(config, 'mocks.api.goToScanningMaFlow', false)) {
    return goToScanningMaFlowMock({ rmaNumber })(dispatch);
  }
  if (status === 'CREATED') {
    const initiate = await Post(uriName, `/v1/transfergateway/rma/update`, {
      action: 'initiate',
      rmaNumber
    });
  }
  dispatch({ type: SET_RMA_INFO_FOR_SCAN_MA_PAGE_SELECT_RMA, data: { rmaNumber, status } });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'scanMaSerials' });
};

export const goToDynamicScanningFlow =
  (programType, sourceLocationId, sourceDescription, serviceProviderLocationId, externalReferenceNumber, createWithDuplicateRefNumber, rmaArray) =>
  async dispatch => {
    dispatch({ type: SET_SELECTED_TRANSFER_TYPE, data: null });
    dispatch({ type: SET_SELECTED_ORIGIN, data: null });
    dispatch({ type: SET_SELECTED_DESTINATION, data: null });
    dispatch({
      type: SET_INFO_FOR_DYNAMIC_SCAN_PAGE,
      data: { programType, sourceLocationId, sourceDescription, serviceProviderLocationId, externalReferenceNumber, createWithDuplicateRefNumber, rmaArray }
    });
    dispatch({ type: SET_RETURNS_NAVIGATION, data: 'scanDynamic' });
  };

export const goToReceiveFlow = (transferNumber, status) => async dispatch => {
  const response = await Post(uriName, `/v1/transfergateway/fsltransfer/info`, {
    transferNumber
  });
  if (response && response.parts && response.parts.length > 0) {
    let parts = {};
    for await (let part of response.parts) {
      if (parts[part.partNumber]) {
        parts[part.partNumber]['parts'].push(part);
        if (part.isSerialized) {
          parts[part.partNumber]['receivedQuantity'] = parts[part.partNumber]['receivedQuantity'] + part.scanQuantity;
          parts[part.partNumber]['pendingReceivingQuantity'] = parts[part.partNumber]['pendingReceivingQuantity'] + 1;
        }
      } else {
        if (part.isSerialized) {
          let newpart = {
            receivedQuantity: part.scanQuantity,
            pendingReceivingQuantity: 1,
            parts: [part],
            isSerialized: true
          };
          parts[part.partNumber] = newpart;
        } else {
          let newpart = {
            receivedQuantity: part.scanQuantity,
            pendingReceivingQuantity: part.requiredQuantity,
            parts: [part],
            isSerialized: false
          };
          parts[part.partNumber] = newpart;
        }
      }
    }
    response.parts = parts;
  } else {
    response.parts = {};
  }
  dispatch({ type: SET_FSL_TRANSFER_INFO_FOR_SCAN_PAGE, data: response });

  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'receive' });

  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'receive' });
};

export const goToTruckFlow = state => async dispatch => {
  let truckData = { ...state };
  if (state.direction === 'incoming') {
    truckData = {
      transferNumber: state.transferNumber,
      sourcePartLocationId: state.destinationPartLocationId,
      destinationPartLocationId: state.sourcePartLocationId,
      direction: state.direction,
      sourceDescription: state.destinationDescription,
      destinationDescription: state.sourceDescription,
      serviceProviderLocationId: state.serviceProviderLocationId
    };
  }
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_TRUCK_PAGE_INITIAL_INFO, data: truckData });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'truckTransfer' });
};

export const goToCreateTransferFlow = () => async dispatch => {
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'adhoc' });
};

export const goToImportFlow = () => async dispatch => {
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'import' });
};
export const goToImportFile = () => async dispatch => {
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'importFile' });
};
export const goToRmaManual = () => async dispatch => {
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaManual' });
};
export const goToRmaSummary = () => async dispatch => {
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaSummary' });
};
export const goToRmaSummarySuccess = () => async dispatch => {
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaSummarySuccess' });
};
export const setCurrentSection = section => async dispatch => {
  dispatch({ type: SET_TRANSFER_DASHBOARD_CURRENT_SELECTION, data: section });
  dispatch({ type: SET_RMA_DASHBOARD_SELECTED_STATUSES, data: [] });
};

export const setRmaSummary = rmaData => async dispatch => {
  dispatch({ type: SET_RMA_SUMMARY, data: rmaData });
};

export const searchTransfers = content => async dispatch => {
  try {
    const response = await Post(uriName, URLS.TRANSFERS, content);
    if (content.fromDate) {
      dispatch({ type: SET_RMA_DASHBOARD_FROM_DATE, data: content.fromDate });
    }
    if (content.toDate) {
      dispatch({ type: SET_RMA_DASHBOARD_TO_DATE, data: content.toDate });
    }
    if (content.providerLocationId) {
      dispatch({
        type: SET_RMA_DASHBOARD_SELECTED_SP_ID,
        data: content.providerLocationId
      });
    } else {
      dispatch({ type: SET_RMA_DASHBOARD_SELECTED_SP_ID, data: 'all' });
    }
    if (content.selectedStatuses) {
      dispatch({ type: SET_RMA_DASHBOARD_SELECTED_STATUSES, data: content.selectedStatuses });
    }
    if (response.transfers) {
      dispatch({
        type: SET_DATA_FOR_RMA_DASHBOARD_TABLE,
        data: response.transfers
      });
    }
    if (response.AsnDetails) {
      dispatch({
        type: SET_ASN_DATA,
        data: response.AsnDetails
      });
    }
    if (response.transfers && response.transfers.length === 0 && response.AsnDetails && Object.keys(response.AsnDetails).length === 0) {
      dispatch({
        type: ADD_APPLICATION_ERROR_MESSAGE,
        data: 'No transfers found'
      });
    } else if (response.transfers && response.transfers.length === 0 && !response.AsnDetails) {
      dispatch({
        type: ADD_APPLICATION_ERROR_MESSAGE,
        data: 'No transfers found'
      });
    }
  } catch (error) {
    console.log(error);
  }
};
