import * as Styled from './ScanDynamic.styles';
import NavBar from './NavBar';
import ScanBox from './ScanBox';
import SubmitModal from './SubmitModal';
import RmaInfoList from './RmaInfoList';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { goToRmaDashboard, handleInitialRmaLoad } from './action';

const ScanDynamic = () => {
  const dispatch = useDispatch();

  const [spPartLocationId, setSpPartLocationId] = useState('');
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const { spLocations } = useSelector(state => state.rmadashboardReducer);
  const { rmaArray, serviceProviderLocationId, rmaScanSuccessful } = useSelector(state => state.rmaScanDynamicReducer.pageData);

  useEffect(() => {
    if (serviceProviderLocationId && spLocations && spLocations.length > 0) {
      setSpLocId(spLocations, serviceProviderLocationId);
    }
    if (rmaArray && rmaArray.length && rmaArray.length > 0) {
      dispatch(handleInitialRmaLoad(rmaArray));
    }
  }, [spLocations, serviceProviderLocationId, rmaArray]);

  useEffect(() => {
    if (rmaScanSuccessful) {
      setSuccessModalVisible(true);
    }
  }, [rmaScanSuccessful]);

  const setSpLocId = (spLocations, serviceProviderLocationId) => {
    if (!spPartLocationId) {
      for (const location of spLocations) {
        if (location.serviceProviderLocationId === serviceProviderLocationId) {
          setSpPartLocationId(location.spPartLocationId);
          return;
        }
      }
    }
  };

  const returnToDashboard = () => {
    dispatch(goToRmaDashboard());
  };

  return (
    <Styled.PageContainer>
      {successModalVisible ? <SubmitModal returnToDashboard={returnToDashboard} /> : ''}
      <NavBar returnToDashboard={returnToDashboard} />
      <Styled.PageContent>
        <ScanBox spPartLocationId={spPartLocationId} />
        <Styled.RmaInfoListContainer>
          <RmaInfoList />
        </Styled.RmaInfoListContainer>
      </Styled.PageContent>
    </Styled.PageContainer>
  );
};

export default ScanDynamic;
