import React from 'react';
import { Papa } from 'papaparse';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RmaImportManual from './RmaImportManual.jsx';
import './RmaImportModal.scss';
import MessageModal from '../../common/MessageModal';
import {
  addSuccessMessage,
  addErrorMessage,
  clearMessages,
} from '../../action';

import {
  goToImportFlow,
  goToImportFile,
  goToRmaManual,
  goToRmaSummary,
} from '../RmaDashboard/action';

import { goToRmaDashboard } from '../createTransferFlow/action';

class RmaImportFile extends React.Component {
  handleChange = (event) => {
    this.setState({
      csvfile: event.target.files[0],
    });
  };

  render() {
    return (
      <div>
        <h1>Create new RMA</h1> <br />
        <p>Upload File</p>
        <div>
          <input type="textBox" name="" style={{ width: '500px' }} />
          <input
            type="file"
            name="Choose File"
            onChange={this.handleChange}
            style={{ color: '#008080' }}
          />
          <br />

          <button
            className="rmaDashButton"
            onClick={this.props.goToRmaSummary}
            style={{ float: 'left' }}
          >
            Upload File
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ rmadashboardReducer }) => {
  const { userType } = rmadashboardReducer;
  return {
    userType,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToImportFlow,
      goToImportFile,
      goToRmaManual,
      goToRmaDashboard,
      addSuccessMessage,
      addErrorMessage,
      clearMessages,
      goToRmaSummary,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RmaImportFile);
