import { Post, uriName, userInfo } from '../../../utilities/apiUtility';

import {
  SET_ADHOC_RMA_SOURCE_LOCATIONS,
  SET_ADHOC_RMA_DESTINATION_LOCATIONS,
  SET_RETURNS_NAVIGATION,
  SET_RMA_INFO_FOR_SCAN_PAGE,
  SET_APPLICATION_ERROR_MESSAGES,
  SET_SELECTED_TRANSFER_TYPE,
  SET_SELECTED_ORIGIN,
  SET_SELECTED_DESTINATION
} from '../../../actionTypes';

import { URLS } from '../../common/constants/urlConsts';
import { CONSTANTS } from '../../common/constants/generalConst';

export const handleSelectedTransferType = selectedTransferType => async dispatch => {
  dispatch({ type: SET_SELECTED_TRANSFER_TYPE, data: selectedTransferType });
  dispatch({ type: SET_SELECTED_ORIGIN, data: null });
  dispatch({ type: SET_SELECTED_DESTINATION, data: null });
};

export const handleOriginChange = selectedOrigin => async dispatch => {
  dispatch({ type: SET_SELECTED_ORIGIN, data: selectedOrigin });
  dispatch({ type: SET_SELECTED_DESTINATION, data: null });
};

export const handleDestinationChange = selectedDestination => async dispatch => {
  dispatch({ type: SET_SELECTED_DESTINATION, data: selectedDestination });
};

export const getSourceLocations = typeOfTransfer => async dispatch => {
  const response = await Post(uriName, URLS.SOURCE_LOCATIONS, {
    serviceProviderId: userInfo.companyId,
    typeOfTransfer: typeOfTransfer ? typeOfTransfer : CONSTANTS.CAPABILITIES.RETURNS,
    userAccountId: userInfo.userName ? userInfo.userName : ''
  });
  if (response.serviceProviderLocationName) {
    let sourceLocationsData = [];
    response.serviceProviderLocationName.forEach(location => {
      sourceLocationsData.push({ label: location.description, value: location });
    });
    dispatch({
      type: SET_ADHOC_RMA_SOURCE_LOCATIONS,
      data: sourceLocationsData
    });
  }
};

export const getDestinationLocations = (typeOfTransfer, location) => async dispatch => {
  let destinationLocations = [];
  const response = await Post(uriName, URLS.DESTINATION_LOCATIONS, {
    serviceProviderId: userInfo.companyId,
    typeOfTransfer: typeOfTransfer ? typeOfTransfer : CONSTANTS.CAPABILITIES.RETURNS,
    serviceProviderLocationId: location ? location : userInfo.companyId
  });
  if ((response && response.length > 0 && typeOfTransfer === CONSTANTS.CAPABILITIES.RETURNS) || typeOfTransfer === CONSTANTS.CAPABILITIES.CORE_RETURN) {
    response.forEach(location => {
      destinationLocations.push({ label: location.name, value: location });
    });
    dispatch({
      type: SET_ADHOC_RMA_DESTINATION_LOCATIONS,
      data: destinationLocations
    });
  } else if (response.serviceProviderLocationName && response.serviceProviderLocationName.length > 0) {
    response.serviceProviderLocationName.forEach(location => {
      destinationLocations.push({ label: location.description, value: location });
    });
    dispatch({
      type: SET_ADHOC_RMA_DESTINATION_LOCATIONS,
      data: destinationLocations
    });
  } else if (response.serviceProviderSubLocationName && response.serviceProviderSubLocationName.length > 0) {
    response.serviceProviderSubLocationName.forEach(location => {
      destinationLocations.push({ label: location.description, value: location });
    });
    dispatch({
      type: SET_ADHOC_RMA_DESTINATION_LOCATIONS,
      data: destinationLocations
    });
  }
};

export const createRma =
  (
    typeOfTransfer,
    serviceProviderLocationId,
    destinationAddress,
    externalReferenceNumber,
    createWithDuplicateRefNumber,
    destinationSpLocId,
    direction,
    destinationDescription,
    sourceDescription
  ) =>
  async dispatch => {
    try {
      let content = {
        typeOfTransfer: typeOfTransfer,
        serviceProviderId: userInfo.companyId,
        serviceProviderLocationId: serviceProviderLocationId
      };
      if (typeOfTransfer === CONSTANTS.CAPABILITIES.RETURNS || typeOfTransfer === CONSTANTS.CAPABILITIES.CORE_RETURN) {
        content.destinationAddress = destinationAddress;
      } else if (typeOfTransfer === CONSTANTS.CAPABILITIES.FSL) {
        content.destinationSpLocId = destinationAddress?.serviceProviderLocationId ?? '';
      } else {
        content.destinationSpLocId = destinationAddress;
      }
      if (externalReferenceNumber) {
        content.externalReferenceNumber = externalReferenceNumber;
        content.createWithDuplicateRefNumber = createWithDuplicateRefNumber ? createWithDuplicateRefNumber : false;
      }
      if (destinationSpLocId && typeOfTransfer !== CONSTANTS.CAPABILITIES.FSL) {
        content.destinationSpLocId = destinationSpLocId;
      }
      if (destinationDescription) {
        content.destinationDescription = destinationDescription;
      }
      if (sourceDescription) {
        content.sourceDescription = sourceDescription;
      }
      if (direction) {
        content.direction = direction;
        if (direction === 'incoming') {
          content.destinationSpLocId = serviceProviderLocationId;
          content.serviceProviderLocationId = destinationSpLocId;
          content.destinationDescription = sourceDescription;
          content.sourceDescription = destinationDescription;
        }
      }
      const response = await Post(uriName, URLS.CREATE_RMA, content);
      if (response && response.status === CONSTANTS.SUCCESS) {
        if (typeOfTransfer !== CONSTANTS.CAPABILITIES.TRUCK) {
          let rmaInfo = await Post(uriName, URLS.INFO_RMA, {
            rmaNumber: response.rmaNumber
          });
          if (rmaInfo && rmaInfo.rmaInfo) {
            dispatch({ type: SET_RMA_INFO_FOR_SCAN_PAGE, data: rmaInfo });
          }
        }
        dispatch({ type: SET_SELECTED_TRANSFER_TYPE, data: null });
        dispatch({ type: SET_SELECTED_ORIGIN, data: null });
        dispatch({ type: SET_SELECTED_DESTINATION, data: null });
      }
      return response;
    } catch (err) {}
  };

export const createSurRma = (serviceProviderId, serviceProviderLocationId, destinationAddress, programType, parts) => async dispatch => {
  try {
    let content = '';
    content = {
      typeOfTransfer: CONSTANTS.CAPABILITIES.RETURNS,
      serviceProviderId: serviceProviderId,
      destinationAddress: destinationAddress,
      serviceProviderLocationId: serviceProviderLocationId,
      programType: programType,
      parts: parts
    };

    const response = await Post(uriName, URLS.CREATE_RMA, content);
    if (response && response.status === CONSTANTS.SUCCESS) {
      let rmaInfo = await Post(uriName, URLS.INFO_RMA, {
        rmaNumber: response.rmaNumber
      });
      if (rmaInfo && rmaInfo.rmaInfo) {
        dispatch({ type: SET_RMA_INFO_FOR_SCAN_PAGE, data: rmaInfo });
      }
    }
    return response;
  } catch (err) {}
};

export const goToRmaDashboard = () => async dispatch => {
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaDashBoard' });
};
