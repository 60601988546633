import styled from 'styled-components';
import { ReactComponent as LeftArrowLogo } from '../../../assets/arrow1-left.svg';

export const ScanNavLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

export const ScanNavContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  text-align: center;
`;

export const LeftArrow = styled(LeftArrowLogo)`
  position: static;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  align-self: center;
  margin: 8px 0px;
`;
