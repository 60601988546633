import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RmaStartOptionModal from './RmaStartOptionModal.jsx'

class ImportFlow extends React.Component {
  render() {
    return (
    <div> 
      <RmaStartOptionModal/> 
      </div>
    );
  }
}

export default ImportFlow;
