import {
  SET_RETURNS_NAVIGATION,
  SET_RMA_INFO_FOR_SCAN_PAGE,
  SET_APPLICATION_ERROR_MESSAGES,
  SET_APPLICATION_SUCCESS_MESSAGES,
  SET_FSL_TRANSFER_INFO_FOR_SCAN_PAGE,
  SET_FSL_TRANSFER_DESCREPANCIES
} from '../../../actionTypes';
import { Post, uriName } from '../../../utilities/apiUtility';

export const goToRmaDashboard = () => async dispatch => {
  dispatch({ type: SET_APPLICATION_ERROR_MESSAGES, data: [] });
  dispatch({ type: SET_APPLICATION_SUCCESS_MESSAGES, data: [] });
  dispatch({ type: SET_RETURNS_NAVIGATION, data: 'rmaDashBoard' });
};

export const togglechild = (currentpartNumber, rmaInfo, visible) => async dispatch => {
  if (rmaInfo && rmaInfo.parts) {
    let parts = {};
    Object.keys(rmaInfo.parts).map(partNumber => {
      let part = {};
      if (currentpartNumber === partNumber) {
        part = rmaInfo.parts[`${partNumber}`];
        part.childVisible = visible;
      } else {
        part = rmaInfo.parts[`${partNumber}`];
      }
      parts[`${partNumber}`] = part;
    });
  }
  dispatch({ type: SET_FSL_TRANSFER_INFO_FOR_SCAN_PAGE, data: { ...rmaInfo } });
};

export const checkSerial = (rmaNumber, serialNumber) => async disaptch => {
  const response = await Post(uriName, `/v1/transfergateway/fsltransfer/part`, {
    transferNumber: rmaNumber,
    serialNumber
  });
  return response;
};

export const updateReceivePage = (pageData, partNumber, serialQuantity, serialized) => async dispatch => {
  let newPageData = { ...pageData };
  if (newPageData.parts[partNumber]) {
    if (serialized) {
      newPageData.parts[partNumber]['receivedQuantity'] = newPageData.parts[partNumber]['receivedQuantity'] + 1;
      newPageData.parts[partNumber].parts = newPageData.parts[partNumber].parts.map(part => {
        if (part.partSerialNumber === serialQuantity) {
          part.returnStatus = 'Scanned';
        }
        return part;
      });
    } else {
      newPageData.parts[partNumber]['receivedQuantity'] = serialQuantity;
      newPageData.parts[partNumber].dirtyData = true;
    }
  }
  dispatch({ type: SET_RMA_INFO_FOR_SCAN_PAGE, data: newPageData });
};

export const saveTransfer = data => async dispatch => {
  let body = {
    action: 'save',
    parts: []
  };
  if (data.rmaInfo.rmaNumber) {
    body.transferNumber = data.rmaInfo.rmaNumber;
  }
  for (let sku in data.parts) {
    let partDetails = data.parts[sku][`parts`];
    if (data.parts[sku].dirtyData) {
      body.parts.push({
        partNumber: sku,
        partQuantity: data.parts[sku].receivedQuantity
      });
    } else {
      for (let partDetail of partDetails) {
        if (partDetail.isSerialized && partDetail.returnStatus === 'Scanned') {
          body.parts.push({
            partNumber: sku,
            partSerialNumber: partDetail.partSerialNumber
          });
        }
      }
    }
  }
  let saveTransfer = await Post(uriName, `/v1/transfergateway/fsltransfer/update`, body);
  if (saveTransfer.status && saveTransfer.status === 'success') {
    return data.rmaInfo.rmaNumber;
  } else {
    return false;
  }
};

export const closeTransfer = (data, validateifallpartsscanned) => async dispatch => {
  let body = {
    action: 'close',
    ignoreValidation: validateifallpartsscanned ? validateifallpartsscanned : false
  };
  if (data.rmaInfo.rmaNumber) {
    body.transferNumber = data.rmaInfo.rmaNumber;
  }
  let closeTransfer = await Post(uriName, `/v1/transfergateway/fsltransfer/update`, body);
  if (closeTransfer && closeTransfer.status && closeTransfer.status === 'failure') {
    if (closeTransfer.descrepancies && closeTransfer.descrepancies.length > 0) {
      //set descrepancies in state
      dispatch({
        type: SET_FSL_TRANSFER_DESCREPANCIES,
        data: closeTransfer.descrepancies
      });
      return false;
    }
  }

  if (closeTransfer && closeTransfer.status && closeTransfer.status === 'success') {
    dispatch({
      type: SET_FSL_TRANSFER_DESCREPANCIES,
      data: []
    });
    return true;
  }
};

export const getSnapShot = transferNumber => async dispatch => {
  const response = await Post(uriName, `/v1/transfergateway/fsltransfer/info`, {
    transferNumber
  });
  if (response && response.parts && response.parts.length > 0) {
    let parts = {};
    for await (let part of response.parts) {
      if (parts[part.partNumber]) {
        parts[part.partNumber]['parts'].push(part);
        if (part.isSerialized) {
          parts[part.partNumber]['receivedQuantity'] = parts[part.partNumber]['receivedQuantity'] + part.scanQuantity;
          parts[part.partNumber]['pendingReceivingQuantity'] = parts[part.partNumber]['pendingReceivingQuantity'] + 1;
        }
      } else {
        if (part.isSerialized) {
          let newpart = {
            receivedQuantity: part.scanQuantity,
            pendingReceivingQuantity: 1,
            parts: [part],
            isSerialized: true
          };
          parts[part.partNumber] = newpart;
        } else {
          let newpart = {
            receivedQuantity: part.scanQuantity,
            pendingReceivingQuantity: part.requiredQuantity,
            parts: [part],
            isSerialized: false
          };
          parts[part.partNumber] = newpart;
        }
      }
    }
    response.parts = parts;
  } else {
    response.parts = {};
  }
  dispatch({ type: SET_FSL_TRANSFER_INFO_FOR_SCAN_PAGE, data: response });
};
