import { combineReducers } from 'redux';
import ReturnsReducer from './components/returns/reducer';
import rmadashboardReducer from './components/returns/RmaDashboard/reducer';
import createTransferReducer from './components/returns/createTransferFlow/reducer';
import appReducer from './components/reducer';
import rmaScanReducer from './components/returns/scanningFlow/reducer';
import rmaScanMaReducer from './components/returns/scanningMaFlow/reducer';
import rmaScanDynamicReducer from './components/returns/scanningDynamicFlow/reducer';
import rmaImportReducer from './components/returns/importFlow/reducer';
import receiveReducer from './components/returns/receiveFlow/reducer';
import receiveOrderReducer from './components/returns/orderFlow/reducer';
import truckFlowReducer from './components/returns/truckFlow/reducer';
import userSession from './reducers/userSessionReducers'
import appInteractions from './reducers/appInteractionReducers'

const rootReducer = {
  rmadashboardReducer,
  ReturnsReducer,
  appInteractions,
  appReducer,
  userSession,
  createTransferReducer,
  rmaScanReducer,
  rmaScanMaReducer,
  rmaScanDynamicReducer,
  rmaImportReducer,
  receiveReducer,
  receiveOrderReducer,
  truckFlowReducer,
};

export default combineReducers({
  ...rootReducer,
});
