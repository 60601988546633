import * as Styled from './PartQuantityModal.styles';
import { Text } from '@sbx/sbx-ui-components';
import { Modal } from '@sbx/sbx-ui-components';

const PartQuantityModal = ({ modalData, setPartQuantity, closeModal, submitModal }) => {
  
  const { partQuantity, rmaNumber, partNumber } = modalData;

  return (
    <Modal
      title='Enter quantity for return'
      isOpen={true}
      primaryButtonLabel='Save'
      onPrimaryClick={submitModal}
      secondaryButtonLabel='Cancel'
      onSecondaryClick={closeModal}
      onCloseClick={closeModal}
    >
      <Styled.QuantityModalBody>
        <Styled.QuantityInput
          type='number'
          value={partQuantity}
          min='0'
          onChange={event => {
            setPartQuantity(event.target.value);
          }}
        />
        <Text size='2'>RMA number: {rmaNumber}</Text>
        <Text size='2'>Part number: {partNumber}</Text>
      </Styled.QuantityModalBody>
    </Modal>
  );
};

export default PartQuantityModal;
