import * as Styled from './ScanBox.styles';
import BarcodeReader from 'react-barcode-reader';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, TextField, Button } from '@sbx/sbx-ui-components';
import { handleDynamicScan, generatePackingList } from './action';
import { ADD_APPLICATION_ERROR_MESSAGE } from '../../../actionTypes';

const ScanBox = ({ spPartLocationId }) => {
  
  const dispatch = useDispatch();
  const [serial, setSerial] = useState('');
  const { pageData } = useSelector(state => state.rmaScanDynamicReducer);
  const { rmaInfo, sourceDescription } = useSelector(state => state.rmaScanDynamicReducer.pageData);
  const shippingOrigin = sourceDescription ? sourceDescription : rmaInfo && rmaInfo[0]?.from?.locationName ? rmaInfo[0]?.from?.locationName : ''; 

  const handleInputChange = useCallback(event => {
    setSerial(event.target.value);
  }, []);

  const onScan = () => {
    if (serial && serial.length > 0) {
      dispatch(handleDynamicScan(pageData, serial, rmaInfo, spPartLocationId));
      setSerial('');
    }
  };

  const onScanError = () => {
    dispatch({ type: ADD_APPLICATION_ERROR_MESSAGE, data: `Error occurred during barcode scan. Please try again.` });
  };

  const validatePartsAndPack = () => {
    if (rmaInfo && rmaInfo.length > 0) {
      dispatch(generatePackingList(rmaInfo, spPartLocationId));
    }
  };

  const blockRmaSubmission = () => {
    if (!rmaInfo || !rmaInfo.length > 0) return true;
    for (const rma of rmaInfo) {
      if (rma.rmaInfo && rma.rmaInfo.status === 'INITIATED' && rma.parts && rma.parts.length > 0) {
        for (const part of rma.parts) {
          if (Number(part.isSerialized === 1) && Number(part.scanQuantity > 0)) return false;
          if (Number(part.isSerialized === 0) && Number(part.scanQuantity > 0)) return false;
        }
      }
    }
    return true;
  };

  return (
    <Styled.ScanParent>
      <Styled.ScanContent>
        <Text size='4'>Scan or enter parts</Text>
        <TextField
          label='Part or serial number'
          value={serial}
          disabled={rmaInfo && rmaInfo[0] && rmaInfo[0].rmaInfo && rmaInfo[0].rmaInfo.status !== 'INITIATED' ? true : false}
          autoFocus
          onChange={handleInputChange}
          onKeyDown={event => {
            if (event.key === 'Enter' && event.target.value !== '') {
              onScan();
            }
          }}
        />
        <BarcodeReader onScan={onScan} onError={onScanError}/>
        <Styled.ShippingOrigin>
          <Text size='2'>Shipping Origin</Text>
          <Text size='5'>{shippingOrigin}</Text>
        </Styled.ShippingOrigin>
      </Styled.ScanContent>
      <Styled.ScanButtonContent>
        <Button variant='default' size='large' disabled={blockRmaSubmission()} onClick={validatePartsAndPack}>
          Generate Packing List
        </Button>
      </Styled.ScanButtonContent>
    </Styled.ScanParent>
  );
};

export default ScanBox;
