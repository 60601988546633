export const CONSTANTS = {
  CAPABILITIES: {
    CORE_RETURN: 'coreReturn',
    RETURNS: 'returns',
    FSL: 'fsl',
    TRUCK: 'truck',
    IMPORT_FILE_TRANSFER: 'importFileTransfer'
  },
  SUCCESS: 'success'
};

export const SB_HOME = '/servicebenchv5/common/home.action';

export const storage = {
  currentPageIndex: 'currentPageIndex',
  currentUrl: 'currentUrl',
  navItems: 'navItems',
  prevUrl: 'prevUrl',
  ssoPrevUrl: 'ssoPrevUrl'
};